import React, { useEffect, useState } from "react";

import * as S from "./Classroom.styles";

import Popup from "../../components/Popup/Popup";
import { roundLvlExp, secondsToDhm } from "../../helpers";
import Reward from "../../components/Reward/Reward";
import { grindData } from "../../info/data";
import { CentredWrapper, Close } from "../../App.styles";

import frameClass1 from "../../img/dobbi/class/1.png";
import frameClass2 from "../../img/dobbi/class/2.png";
import frameClass3 from "../../img/dobbi/class/3.png";

import infoPotionImg from "../../img/potionRoom/InfoHover.png";
import itemPotion1Img from "../../img/potionRoom/Item1Hover.png";
import itemPotion2Img from "../../img/potionRoom/Item2Hover.png";
import itemPotion3Img from "../../img/potionRoom/Item3Hover.png";
import itemPotion4Img from "../../img/potionRoom/Item4Hover.png";

import infoGrassImg from "../../img/grass/InfoHover.png";
import itemGrass1Img from "../../img/grass/Item1Hover.png";
import itemGrass2Img from "../../img/grass/Item2Hover.png";
import itemGrass3Img from "../../img/grass/Item3Hover.png";
import itemGrass4Img from "../../img/grass/Item4Hover.png";
import frame8 from "../../img/Frame8.png";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import { BottomPrice, BottomPrices, Button } from "../Arena/Arena.styles";
import { ButtonChange } from "../../components/Healls/Healls.styles";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import { TopButton } from "../Tops/Tops.styles";
import noteImg from "../../img/map/Note.png";
import {
  ArrowPage,
  ArrowPagePrev,
  CloseNote,
  LocationNote,
  LocationsNote,
  NameNote,
} from "../../components/Map/Map.styles";
import closeImg from "../../img/map/Close.png";
import closeHoverImg from "../../img/map/CloseHover.png";
import pageArrowImg from "../../img/map/PageArrow.png";
import pageArrowHoverImg from "../../img/map/PageArrowHover.png";
import grassRoomImg from "../../img/map/GrassRoom.png";
import grassRoomHoverImg from "../../img/map/GrassRoomHover.png";
import potionRoomImg from "../../img/map/PotionRoom.png";
import potionRoomHoverImg from "../../img/map/PotionRoomHover.png";
import frame6 from "../../img/Frame6.png";
import { createPortal } from "react-dom";
import { MenuElemRight, MenuRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import timeIcon from "../../img/menuIcons/Time.png";
import betFrame from "../../img/BetFrame.png";
import { Header } from "../../components/Ordens/Ordens.styles";

import {
  BottomInfo,
  ButtonValueInput,
} from "../../components/DiceGame/DiceGame.styles";
import InputNumber from "../../components/InputNumber/InputNumber";

const types = {
  Зельеварение: {
    work: "potionLab",
    helper: "helpersPotionLab",
    name: "Зельеварения",
  },
  Растениеводство: {
    work: "greenHouse",
    helper: "helpersGreenHouse",
    name: "Растениеводства",
  },
};

const popups = [
  [
    {
      top: 245,
      left: 130,
    },
    {
      top: 400,
      left: 670,
    },
    {
      top: 165,
      left: 415,
    },
    {
      top: 410,
      left: 405,
    },
  ],
  [
    {
      top: 285,
      left: 640,
    },
    {
      top: 360,
      left: 335,
    },
    {
      top: 295,
      left: 140,
    },
    {
      top: 160,
      left: 100,
    },
  ],
];

const popupsActive = [
  [
    {
      top: 170,
      left: 130,
    },
    {
      top: 325,
      left: 670,
    },
    {
      top: 135,
      left: 415,
    },
    {
      top: 335,
      left: 405,
    },
  ],
  [
    {
      top: 285,
      left: 640,
    },
    {
      top: 330,
      left: 335,
    },
    {
      top: 295,
      left: 140,
    },
    {
      top: 160,
      left: 100,
    },
  ],
];

const items = [
  [
    {
      left: 121,
      top: 296,
      width: 197,
      height: 158,
      img: itemPotion2Img,
    },
    {
      left: 546,
      top: 316,
      width: 134,
      height: 119,
      img: itemPotion3Img,
    },
    {
      left: 557,
      top: 142,
      width: 182,
      height: 88,
      img: itemPotion4Img,
    },
    {
      left: 250,
      top: 403,
      width: 320,
      height: 95,
      img: itemPotion1Img,
    },
  ],
  [
    {
      left: 500,
      top: 0,
      width: 235,
      height: 285,
      img: itemGrass1Img,
    },
    {
      left: 333,
      top: 216,
      width: 139,
      height: 141,
      img: itemGrass2Img,
    },
    {
      left: 52,
      top: 343,
      width: 275,
      height: 198,
      img: itemGrass3Img,
    },
    {
      left: 121,
      top: 0,
      width: 400,
      height: 170,
      img: itemGrass4Img,
    },
  ],
];

const infoItems = [
  {
    left: 348,
    top: 279,
    width: 110,
    height: 125,
    img: infoPotionImg,
  },
  {
    left: 488,
    top: 297,
    width: 100,
    height: 70,
    img: infoGrassImg,
  },
];

const locationsOpen = [
  {
    location: "grassRoom",
    name: "Растениеводство",
    src: grassRoomImg,
    srcHover: grassRoomHoverImg,
  },
  {
    location: "potionRoom",
    name: "Зельеварение",
    src: potionRoomImg,
    srcHover: potionRoomHoverImg,
  },
];

function Classroom({
  app,
  user,
  setUser,
  setIsFetching,
  isFetching,
  classRoomIndex,
  setIsLvlUpOpen,
  setCollectionElem,
  setIsPopupCollection,
  setModalError,
  serverTime,
  name,
  rewards,
  steps,
  changeOptionsLocation,
  id,
  warOrdenTask,
  warOrdenDayTask,
  moveLocation,
  helpers,
  bridge,
  isLvlUpOpen,
}) {
  const [helperOpen, setHelperOpen] = useState(false);
  const [helpXEnergy, setHelpXEnergy] = useState(0);
  const [timeHelp, setTimeHelp] = useState(null);
  const [helpX, setHelpX] = useState(1);

  const [locationsOpenArray, setLocationsOpenArray] = useState([]);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [notePage, setNotePage] = useState(0);

  const [classRoom, setClassRoom] = useState(user.classrooms[classRoomIndex]);
  const [activeNumber, setActiveNumber] = useState(-1);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAll, setIsAll] = useState(false);

  const [domReady, setDomReady] = React.useState(false);
  const [betOpen, setBetOpen] = useState(false);

  useEffect(() => {
    setDomReady(true);
    changeActive(-1);
    changeOptionsLocation(id);
  }, []);

  useEffect(() => {
    if ((helpers?.[types[name]?.work] || 0) >= serverTime) {
      setTimeHelp(
        secondsToDhm(((helpers?.[types[name]?.work] || 0) - serverTime) / 1000)
      );
    } else {
      setHelpX(1);
      setTimeHelp(null);
    }
  }, [serverTime, helpers]);

  useEffect(() => {
    if (classRoom) {
      if (
        activeNumber > -1 &&
        classRoom.steps[activeNumber] >= steps[activeNumber].countNeed
      ) {
        changeActive(-1);
      }
    }

    if (helpX > 0) {
      let energy = 0;
      classRoom.steps.forEach((step, i) => {
        energy +=
          (steps[i].countNeed - step) *
          steps[i].energy *
          Math.pow(2, user[types[name]?.work]?.lvl - 1);
      });

      for (let i = 0; i < helpX - 1; i++) {
        classRoom.steps.forEach((step, i) => {
          energy +=
            steps[i].countNeed *
            steps[i].energy *
            Math.pow(2, user[types[name]?.work]?.lvl - 1);
        });
      }

      setHelpXEnergy(energy);
    } else {
      setHelpXEnergy(0);
    }
  }, [helpX, steps, classRoom]);

  useEffect(() => {
    setClassRoom(user.classrooms[classRoomIndex]);
  }, [user, classRoomIndex]);

  useEffect(() => {
    setLocationsOpenArray(locationsOpen.slice(notePage, notePage + 2));
  }, [notePage]);

  function changeNotePage(page) {
    if (page > 0) {
      setNotePage((prev) => prev + 1);
    } else {
      setNotePage((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }
  }

  function changeBetOpen(open) {
    setBetOpen(open);
  }

  function changeOpenMap(isOpen) {
    setIsOpenMap(isOpen);
  }

  function changeActive(number) {
    setActiveNumber(number);
  }

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function changeAll(isAll) {
    setIsAll(isAll);
  }

  function handleEndSteps() {
    setIsFetching(true);
    setLoading(true);

    let isUp = 0;
    let goldUp = 0;
    let lvls = 1;
    let expOnLvl =
      user.allExp +
      rewards.exp *
        Math.pow(3, user[types[name]?.work]?.lvl - 1) *
        (classRoom.endX || 1);
    let expNeed = 300;

    while (expOnLvl >= expNeed) {
      expOnLvl -= expNeed;
      expNeed = roundLvlExp(
        expNeed *
          (lvls > 100 ? 1.1 : lvls > 150 ? 1.05 : lvls > 200 ? 1.02 : 1.2)
      );
      lvls++;
    }

    isUp = lvls - user.lvl;

    if (isUp > 0) {
      for (let step = 0; step < isUp; step++) {
        goldUp += user.lvl + step + 1;
      }
    } else {
      isUp = 0;
    }

    const characteristicInfo = {};
    Object.entries(user.characteristic).forEach((elem) => {
      characteristicInfo[elem[0]] = {
        ...elem[1],
        lvl: lvls * 5,
      };
    });

    const scales = {};
    const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
    user.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const hp = Math.floor(
      Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * 5 * scales.health;
        }
        return acc + cur * 5;
      }, 0)
    );

    app
      .service("users")
      .patch(
        user._id,
        {
          [`classrooms.${classRoomIndex}.steps.$[]`]: 0,
          [`classrooms.${classRoomIndex}.endX`]: 0,
          $inc: {
            mana:
              rewards.mana *
              Math.pow(2, user[types[name]?.work]?.lvl - 1) *
              (classRoom.endX || 1),
            silver:
              rewards.silver *
              Math.pow(2, user[types[name]?.work]?.lvl - 1) *
              (classRoom.endX || 1),
            allExp:
              rewards.exp *
              Math.pow(3, user[types[name]?.work]?.lvl - 1) *
              (classRoom.endX || 1),
            gold: goldUp,
            [`classrooms.${classRoomIndex}.done`]: classRoom.endX || 1,
            currentEnergy:
              isUp &&
              user.currentEnergy <
                user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1]
                ? user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1] -
                  user.currentEnergy
                : 0,
            [`newQuest.results.classroom${classRoomIndex + 1}`]:
              classRoom.endX || 1,
            [`summerSave.results.classroom${classRoomIndex + 1}`]:
              classRoom.endX || 1,
            "dayQuest.classroomsGet": classRoom.endX || 1,
            "spells.points": isUp,
            "spells.pointsAll": isUp,
          },
          lvl: lvls,
          currentExpOnLvl: expOnLvl,
          onLvlExpNeed: expNeed,
          health: {
            current: isUp ? hp : user.health.current,
            max: hp,
          },
          characteristic: characteristicInfo,
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "classrooms",
              "currentEnergy",
              "mana",
              "allExp",
              "lvl",
              "gold",
              "silver",
              "health",
              "currentExpOnLvl",
              "characteristic",
              "onLvlExpNeed",
              "dayQuest",
              "spells",
              "newQuest",
              "summerSave",
            ],
          },
        }
      )
      .then((data) => {
        changeActive(-1);
        setUser((prev) => ({ ...prev, ...data }));

        setIsFetching(false);

        setTimeout(() => {
          setLoading(false);
        }, 250);

        if (isUp) {
          setIsLvlUpOpen({ lvls: isUp, gold: goldUp });
        }

        const tasks = [
          {
            property: `classroomsGet`,
            count: classRoom.endX || 1,
          },
        ];

        const tasksDay = [
          {
            property: `classroomsGet${classRoomIndex}`,
            count: classRoom.endX || 1,
          },
        ];

        warOrdenTask(tasks);
        warOrdenDayTask(tasksDay);
      })
      .catch((e) => {
        setLoading(false);
        setIsFetching(false);
        console.log(e);
        setModalError(e);
      });
  }

  function handleStep({ energy, stepNumber, isAll = false }) {
    const newSteps = classRoom.steps;

    const count = isAll
      ? steps[stepNumber].countNeed - classRoom.steps[stepNumber]
      : 1;

    if (count > 0) {
      setLoading(true);
      setIsFetching(true);

      let elem = -1;
      let elemCount = 0;
      const elemsCount = [0, 0, 0, 0, 0];

      for (let i = 0; i < count; i++) {
        const coll = randomGetCollection();
        if (coll > -1) {
          elem = coll;
          elemCount++;
          elemsCount[coll]++;
        }
      }

      if (
        user.currentEnergy -
          count * energy * Math.pow(2, user[types[name]?.work]?.lvl - 1) >=
        0
      ) {
        let isUp = 0;
        let goldUp = 0;
        let lvls = 1;
        let expOnLvl =
          user.allExp +
          rewards.exp *
            Math.pow(3, user[types[name]?.work]?.lvl - 1) *
            (classRoom.endX || 1);
        let expNeed = 300;

        while (expOnLvl >= expNeed) {
          expOnLvl -= expNeed;
          expNeed = roundLvlExp(
            expNeed *
              (lvls > 100 ? 1.1 : lvls > 150 ? 1.05 : lvls > 200 ? 1.02 : 1.2)
          );
          console.log(expNeed);
          lvls++;
        }

        isUp = lvls - user.lvl;

        if (isUp > 0) {
          for (let step = 0; step < isUp; step++) {
            goldUp += user.lvl + step + 1;
          }
        } else {
          isUp = 0;
        }

        const characteristicInfo = {};
        Object.entries(user.characteristic).forEach((elem) => {
          characteristicInfo[elem[0]] = {
            ...elem[1],
            lvl: lvls * 5,
          };
        });

        const scales = {};
        const grindValue =
          grindData["book"].bonus[user.grind["book"] - 1] / 100;
        user.damageTalents.characteristicTalents.forEach((elem) => {
          scales[elem.property] =
            1 +
            grindValue +
            Math.round(elem.step * elem.countDone * 100) / 10000;
        });

        const hp = Math.floor(
          Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
            if (i < 2) {
              return acc + cur * 5 * scales.health;
            }
            return acc + cur * 5;
          }, 0)
        );

        const objData = {
          $inc: {
            weakEnergy:
              count * energy * Math.pow(2, user[types[name]?.work]?.lvl - 1),
            currentEnergy:
              isUp &&
              user.currentEnergy -
                count * energy * Math.pow(2, user[types[name]?.work]?.lvl - 1) <
                user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1]
                ? user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1] -
                  user.currentEnergy
                : -count *
                  energy *
                  Math.pow(2, user[types[name]?.work]?.lvl - 1),
            mana:
              count *
              steps[stepNumber].rewards.mana *
              Math.pow(2, user[types[name]?.work]?.lvl - 1),
            allExp:
              count *
              steps[stepNumber].rewards.exp *
              Math.pow(3, user[types[name]?.work]?.lvl - 1),
            gold: goldUp,
            [`classrooms.${classRoomIndex}.steps.${stepNumber}`]: count,
            "dayQuest.classroomsEnergy":
              count * energy * Math.pow(2, user[types[name]?.work]?.lvl - 1),
            "spells.points": isUp,
            "spells.pointsAll": isUp,
          },
          health: {
            current: isUp ? hp : user.health.current,
            max: hp,
          },
          lvl: lvls,
          currentExpOnLvl: expOnLvl,
          onLvlExpNeed: expNeed,
          characteristic: characteristicInfo,
          field: serverTime,
        };

        newSteps[stepNumber] += count;

        if (newSteps.findIndex((step, i) => step < steps[i].countNeed) === -1) {
          objData[`classrooms.${classRoomIndex}.endX`] = 1;
        }

        if (elemsCount[0] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${0}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[0];
        }
        if (elemsCount[1] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${1}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[1];
        }
        if (elemsCount[2] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${2}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[2];
        }
        if (elemsCount[3] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${3}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[3];
        }
        if (elemsCount[4] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${4}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[4];
        }

        app
          .service("users")
          .patch(user._id, objData, {
            query: {
              $select: [
                "_id",
                "email",
                "classrooms",
                "currentEnergy",
                "mana",
                "silver",
                "allExp",
                "lvl",
                "gold",
                "health",
                "currentExpOnLvl",
                "characteristic",
                "onLvlExpNeed",
                "dayQuest",
                "spells",
                "weakEnergy",
                "collections",
              ],
            },
          })
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);

            setTimeout(() => {
              setLoading(false);
            }, 250);

            const tasks = [];

            if (elem > -1) {
              setCollectionElem({
                collectionIndex: classRoomIndex,
                elemIndex: elem,
                count:
                  Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemCount,
              });

              setIsPopupCollection(true);

              tasks.push({
                property: `collectionsElemGet`,
                count: elemsCount.reduce((acc, cur) => {
                  return (
                    acc + Math.pow(2, user[types[name]?.work]?.lvl - 1) * cur
                  );
                }, 0),
              });

              if (tasks.length) {
                warOrdenTask(tasks);
              }
            }

            if (isUp) {
              setIsLvlUpOpen({ lvls: isUp, gold: goldUp });
            }
          })
          .catch((e) => {
            setLoading(false);
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        console.log("Недостаточно энергии");
        setModalError({
          string: "Недостаточно энергии",
          buttons: ["shop", "inventory"],
        });

        setLoading(false);
        setIsFetching(false);
      }
    } else {
      setModalError("Задания уже выполнены");
      console.log("Задания уже выполнены");
      setLoading(false);
      setIsFetching(false);
    }
  }

  function handleClassrooms() {
    if (isNaN(helpX)) {
      return 0;
    }
    if (helpX < 1) {
      return 0;
    } else {
      setLoading(true);
      setIsFetching(true);

      let energy = 0;
      let exp = 0;
      let mana = 0;

      let elem = -1;
      let elemCount = 0;
      const elemsCount = [0, 0, 0, 0, 0];

      classRoom.steps.forEach((step, i) => {
        const count = steps[i].countNeed - step;
        energy +=
          count *
          steps[i].energy *
          Math.pow(2, user[types[name]?.work]?.lvl - 1);

        exp +=
          count *
          steps[i].rewards.exp *
          Math.pow(3, user[types[name]?.work]?.lvl - 1);

        mana +=
          count *
          steps[i].rewards.mana *
          Math.pow(2, user[types[name]?.work]?.lvl - 1);

        for (let j = 0; j < count; j++) {
          const coll = randomGetCollection();
          if (coll > -1) {
            elem = coll;
            elemCount++;
            elemsCount[coll]++;
          }
        }
      });

      for (let i = 0; i < helpX - 1; i++) {
        classRoom.steps.forEach((step, j) => {
          energy +=
            steps[j].countNeed *
            steps[j].energy *
            Math.pow(2, user[types[name]?.work]?.lvl - 1);

          exp +=
            steps[j].countNeed *
            steps[j].rewards.exp *
            Math.pow(3, user[types[name]?.work]?.lvl - 1);

          mana +=
            steps[j].countNeed *
            steps[j].rewards.mana *
            Math.pow(2, user[types[name]?.work]?.lvl - 1);

          for (let k = 0; k < steps[j].countNeed; k++) {
            const coll = randomGetCollection();
            if (coll > -1) {
              elem = coll;
              elemCount++;
              elemsCount[coll]++;
            }
          }
        });
      }

      if (user.currentEnergy - energy >= 0) {
        let isUp = 0;
        let goldUp = 0;
        let lvls = 1;
        let expOnLvl =
          user.allExp +
          rewards.exp *
            Math.pow(3, user[types[name]?.work]?.lvl - 1) *
            (classRoom.endX || 1);
        let expNeed = 300;

        while (expOnLvl >= expNeed) {
          expOnLvl -= expNeed;
          expNeed = roundLvlExp(
            expNeed *
              (lvls > 100 ? 1.1 : lvls > 150 ? 1.05 : lvls > 200 ? 1.02 : 1.2)
          );
          lvls++;
        }

        isUp = lvls - user.lvl;

        if (isUp > 0) {
          for (let step = 0; step < isUp; step++) {
            goldUp += user.lvl + step + 1;
          }
        } else {
          isUp = 0;
        }

        const characteristicInfo = {};
        Object.entries(user.characteristic).forEach((elem) => {
          characteristicInfo[elem[0]] = {
            ...elem[1],
            lvl: lvls * 5,
          };
        });

        const scales = {};
        const grindValue =
          grindData["book"].bonus[user.grind["book"] - 1] / 100;
        user.damageTalents.characteristicTalents.forEach((elem) => {
          scales[elem.property] =
            1 +
            grindValue +
            Math.round(elem.step * elem.countDone * 100) / 10000;
        });

        const hp = Math.floor(
          Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
            if (i < 2) {
              return acc + cur * 5 * scales.health;
            }
            return acc + cur * 5;
          }, 0)
        );

        const objData = {
          [`classrooms.${classRoomIndex}.steps.$[]`]: 0,
          [`classrooms.${classRoomIndex}.endX`]: helpX,
          $inc: {
            weakEnergy: energy,
            currentEnergy:
              isUp &&
              user.currentEnergy - energy <
                user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1]
                ? user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1] -
                  user.currentEnergy
                : -energy,
            mana: mana,
            allExp: exp,
            gold: goldUp,
            "dayQuest.classroomsEnergy": energy,
            "spells.points": isUp,
            "spells.pointsAll": isUp,
          },
          lvl: lvls,
          currentExpOnLvl: expOnLvl,
          onLvlExpNeed: expNeed,
          health: {
            current: isUp ? hp : user.health.current,
            max: hp,
          },
          characteristic: characteristicInfo,
          field: serverTime,
        };

        if (elemsCount[0] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${0}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[0];
        }
        if (elemsCount[1] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${1}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[1];
        }
        if (elemsCount[2] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${2}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[2];
        }
        if (elemsCount[3] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${3}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[3];
        }
        if (elemsCount[4] > 0) {
          objData.$inc[`collections.${classRoomIndex}.counts.${4}`] =
            Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemsCount[4];
        }

        app
          .service("users")
          .patch(user._id, objData, {
            query: {
              $select: [
                "_id",
                "email",
                "classrooms",
                "currentEnergy",
                "mana",
                "silver",
                "allExp",
                "lvl",
                "gold",
                "health",
                "currentExpOnLvl",
                "characteristic",
                "onLvlExpNeed",
                "dayQuest",
                "spells",
                "weakEnergy",
                "collections",
              ],
            },
          })
          .then((data) => {
            changeBetOpen(false);
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);

            setTimeout(() => {
              setLoading(false);
            }, 250);

            const tasks = [];

            if (elem > -1) {
              setCollectionElem({
                collectionIndex: classRoomIndex,
                elemIndex: elem,
                count:
                  Math.pow(2, user[types[name]?.work]?.lvl - 1) * elemCount,
              });

              setIsPopupCollection(true);

              tasks.push({
                property: `collectionsElemGet`,
                count: elemsCount.reduce((acc, cur) => {
                  return (
                    acc + Math.pow(2, user[types[name]?.work]?.lvl - 1) * cur
                  );
                }, 0),
              });

              if (tasks.length) {
                warOrdenTask(tasks);
              }
            }

            if (isUp) {
              setIsLvlUpOpen({ lvls: isUp, gold: goldUp });
            }
          })
          .catch((e) => {
            setLoading(false);
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        changeBetOpen(false);
        console.log("Недостаточно энергии");
        setModalError({
          string: "Недостаточно энергии",
          buttons: ["shop", "inventory"],
        });

        setLoading(false);
        setIsFetching(false);
      }
    }
  }

  function randomGetCollection() {
    const rand = (Math.random() * 100).toFixed(1);
    const chance = user.damageTalents.simpleTalents.collectionChance - rand > 0;
    if (chance) {
      return Math.floor(Math.random() * 5);
    } else {
      return -1;
    }
  }

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          [types[name]?.helper]: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  return (
    <>
      <TopPart>
        <img src={imgTop} alt="интерфейс" />
      </TopPart>

      <CentredWrapper onClick={changeActive.bind(null, -1)}>
        <TopButton>
          <Button width={130} onClick={changeOpenMap.bind(null, true)}>
            <div>Соседние локации</div>
          </Button>
        </TopButton>
        <S.Info
          top={infoItems[classRoomIndex].top}
          left={infoItems[classRoomIndex].left}
          width={infoItems[classRoomIndex].width}
          height={infoItems[classRoomIndex].height}
          onClick={changeInfoOpen.bind(null, true)}
        >
          <img src={infoItems[classRoomIndex].img} alt="предмет" />
        </S.Info>
        {items[classRoomIndex].map((elem, i) => {
          return (
            <S.Item
              key={i}
              top={elem.top}
              left={elem.left}
              isDone={classRoom.steps[i] >= steps[i].countNeed}
              isActive={activeNumber === i}
              onClick={(e) => {
                e.stopPropagation();
                if (classRoom.steps[i] < steps[i].countNeed) {
                  changeActive(i);
                }
              }}
            >
              <img
                src={elem.img}
                width={elem.width}
                height={elem.height}
                alt="предмет"
              />
            </S.Item>
          );
        })}

        {popups[classRoomIndex].map((elem, i) => {
          return (
            <S.Popup
              key={steps[i].name}
              left={elem.left}
              top={elem.top}
              isVisible={
                activeNumber !== i && classRoom.steps[i] < steps[i].countNeed
              }
            >
              <div>
                <div>
                  {classRoom.steps[i]}/{steps[i].countNeed}
                </div>
                <S.RewardsPopup>
                  <Reward
                    name={"energy"}
                    count={
                      -steps[i].energy *
                      Math.pow(2, user[types[name]?.work]?.lvl - 1)
                    }
                    w={9}
                  />
                  <Reward
                    name={"mana"}
                    count={
                      steps[i].rewards.mana *
                      Math.pow(2, user[types[name]?.work]?.lvl - 1)
                    }
                    w={9}
                  />
                  <Reward
                    name={"exp"}
                    count={
                      steps[i].rewards.exp *
                      Math.pow(3, user[types[name]?.work]?.lvl - 1)
                    }
                    w={9}
                  />
                </S.RewardsPopup>
              </div>
            </S.Popup>
          );
        })}

        {activeNumber > -1 && (
          <S.PopupActive
            key={steps[activeNumber].name}
            left={popupsActive[classRoomIndex][activeNumber].left}
            top={popupsActive[classRoomIndex][activeNumber].top}
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div>{steps[activeNumber].name}</div>
              <div>
                {classRoom.steps[activeNumber]}/{steps[activeNumber].countNeed}
              </div>
              <S.RewardsPopup>
                <Reward
                  name={"energy"}
                  count={
                    -steps[activeNumber].energy *
                    Math.pow(2, user[types[name]?.work]?.lvl - 1)
                  }
                  w={9}
                />
                <Reward
                  name={"mana"}
                  count={
                    steps[activeNumber].rewards.mana *
                    Math.pow(2, user[types[name]?.work]?.lvl - 1)
                  }
                  w={9}
                />
                <Reward
                  name={"exp"}
                  count={
                    steps[activeNumber].rewards.exp *
                    Math.pow(3, user[types[name]?.work]?.lvl - 1)
                  }
                  w={9}
                />
              </S.RewardsPopup>
              <S.ButtonChangeWrapper>
                <ButtonChange
                  islast={true}
                  disabled={isAll}
                  onClick={changeAll.bind(null, true)}
                >
                  <div>Все</div>
                </ButtonChange>
                <ButtonChange
                  disabled={!isAll}
                  onClick={changeAll.bind(null, false)}
                >
                  <div>X1</div>
                </ButtonChange>
              </S.ButtonChangeWrapper>
              <S.ButtonWrapper>
                <Button
                  disabled={
                    loading ||
                    classRoom.steps[activeNumber] >=
                      steps[activeNumber].countNeed
                  }
                  onClick={
                    !loading &&
                    !isFetching &&
                    classRoom.steps[activeNumber] <
                      steps[activeNumber].countNeed
                      ? handleStep.bind(null, {
                          energy: steps[activeNumber].energy,
                          stepNumber: activeNumber,
                          isAll: isAll,
                        })
                      : null
                  }
                >
                  <div>
                    {classRoom.steps[activeNumber] >=
                    steps[activeNumber].countNeed
                      ? "Выполнено"
                      : "Выполнить"}
                  </div>
                </Button>
              </S.ButtonWrapper>
            </div>
          </S.PopupActive>
        )}

        <Popup
          isOpen={isInfoOpen}
          setIsOpen={setIsInfoOpen}
          w={556}
          h={320}
          back={frame8}
        >
          <Close onClick={changeInfoOpen.bind(null, false)} />

          <b>Урок: {name}</b>
          <span>Уровень практики: {user[types[name]?.work]?.lvl}</span>
          <span>Пройдено уроков: {classRoom.done}</span>
          <span>Награда за прохождение урока:</span>
          <S.Rewards>
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"exp"}
                  count={
                    rewards.exp * Math.pow(3, user[types[name]?.work]?.lvl - 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>

            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"silver"}
                  count={
                    rewards.silver *
                    Math.pow(2, user[types[name]?.work]?.lvl - 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>

            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"mana"}
                  count={
                    rewards.mana * Math.pow(2, user[types[name]?.work]?.lvl - 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          </S.Rewards>
          <span>
            От уровня практики зависит количество награды и затрачиваемая
            энергия на прохождение урока. Повысить уровень практики можно в
            соответствующих комнатах на первом этаже замка.
          </span>
        </Popup>

        <Popup
          isOpen={(classRoom?.endX || 0) > 0 && !isLvlUpOpen}
          w={317}
          h={217}
          back={frame3}
          img={
            classRoom.done % 3 === 0
              ? frameClass1
              : classRoom.done % 3 === 1
              ? frameClass2
              : frameClass3
          }
        >
          <b>
            Вы завершили урок {name}{" "}
            {(classRoom.endX || 1) > 1 &&
              `(пройдено раз: ${classRoom.endX || 1})`}
          </b>
          <S.Rewards>
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"exp"}
                  count={
                    rewards.exp *
                    Math.pow(3, user[types[name]?.work]?.lvl - 1) *
                    (classRoom.endX || 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>

            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"silver"}
                  count={
                    rewards.silver *
                    Math.pow(2, user[types[name]?.work]?.lvl - 1) *
                    (classRoom.endX || 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>

            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"mana"}
                  count={
                    rewards.mana *
                    Math.pow(2, user[types[name]?.work]?.lvl - 1) *
                    (classRoom.endX || 1)
                  }
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          </S.Rewards>
          <Button onClick={!loading && !isFetching ? handleEndSteps : null}>
            <div>Забрать награду</div>
          </Button>
        </Popup>
      </CentredWrapper>

      {domReady
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Помощник"}>
                <div onClick={changeHelperOpen.bind(null, true)} />
                <img width={80} height={80} src={helperIcon} alt={"помощник"} />
              </MenuElemRight>
              {timeHelp && (
                <MenuElemRight name={"Ускорение"}>
                  <div onClick={changeBetOpen.bind(null, true)} />
                  <img
                    width={80}
                    height={80}
                    src={timeIcon}
                    alt={"ускорение"}
                  />
                </MenuElemRight>
              )}
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Помощник {types[name]?.name}</b>
        <span>Ускоренное прохождение уроков</span>
        <span>Помощь в Теплице</span>
        {timeHelp && (
          <>
            <span>Оставшееся время помощника:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить помощника?</span>
          ) : (
            <span>Доступные варианты для помощника:</span>
          )}
          <BottomPrices>
            <div>
              <span>3 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 7 })}
              >
                <div>7 голосов</div>
              </Button>
            </div>
            <div>
              <span>7 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 10 })}
              >
                <div>10 голосов</div>
              </Button>
            </div>
            <div>
              <span>30 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 30 })}
              >
                <div>30 голосов</div>
              </Button>
            </div>
          </BottomPrices>
        </BottomPrice>
      </Popup>

      <Popup
        isOpen={betOpen}
        setIsOpen={setBetOpen}
        w={317}
        h={217}
        back={betFrame}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setBetOpen(false);
          }}
        />

        <Header>Количество уроков</Header>

        <ButtonValueInput style={{ marginTop: "25px" }}>
          <InputNumber count={helpX} min={1} max={9999} setCount={setHelpX} />
        </ButtonValueInput>

        <BottomInfo>
          <Reward
            button={true}
            name={"energy"}
            count={`Потребуется: ${helpXEnergy.toLocaleString("ru") || 0}`}
            w={15}
          />
        </BottomInfo>

        <Button
          width={130}
          onClick={!isFetching && !loading ? handleClassrooms : null}
        >
          <div>Пройти уроки</div>
        </Button>
      </Popup>

      <Popup
        isOpen={isOpenMap}
        setIsOpen={changeOpenMap}
        w={900}
        h={340}
        back={noteImg}
      >
        <CloseNote onClick={changeOpenMap.bind(null, false)}>
          <img width={36} height={36} src={closeImg} alt="" />
          <img width={36} height={36} src={closeHoverImg} alt="" />
        </CloseNote>
        {locationsOpenArray.length > 0 && (
          <>
            <NameNote>Уроки</NameNote>

            <ArrowPagePrev
              disabled={notePage - 1 < 0}
              onClick={notePage - 1 >= 0 ? changeNotePage.bind(null, -1) : null}
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </ArrowPagePrev>

            <ArrowPage
              disabled={notePage >= locationsOpen.length - 2}
              onClick={
                notePage + 1 <= locationsOpen.length - 2
                  ? changeNotePage.bind(null, 1)
                  : null
              }
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </ArrowPage>

            <LocationsNote>
              {locationsOpenArray.map(
                ({ src, srcHover, location, name, type }, i) => {
                  return (
                    <LocationNote
                      key={name}
                      type={type}
                      location={location}
                      onClick={
                        !isFetching && location
                          ? moveLocation.bind(null, location)
                          : null
                      }
                    >
                      <img width={300} height={233} src={src} alt={name} />

                      {srcHover && (
                        <img
                          width={300}
                          height={233}
                          src={srcHover}
                          alt={name}
                        />
                      )}
                    </LocationNote>
                  );
                }
              )}
            </LocationsNote>
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Classroom);
