import React, { useState } from "react";
import * as S from "./AdminPanel.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import frame4 from "../../img/Frame4.png";
import { Button } from "../../panels/Arena/Arena.styles";
import { Close } from "../../App.styles";

function AdminPanel({ app }) {
  const [open, setOpen] = useState(false);
  const [func, setFunc] = useState({});

  function updateInfo() {
    app.service("users").updateAdminInfo({});
  }

  function updateLimits() {
    app.service("users").updateLimitsInfo({});
  }

  function jobEveryDay() {
    app.service("users").removeStock({ type: "jobEveryDay" });
  }

  function removeDamage() {
    app.service("users").removeStock({ type: "damage" });
  }

  function removeStock() {
    app.service("users").removeStock({ type: "stock" });
  }

  function removeBox() {
    app.service("users").removeStock({ type: "box" });
  }

  function stockClear() {
    app.service("users").removeStock({ type: "promoClear" });
  }

  function shytClear() {
    app.service("users").removeStock({ type: "shyt" });
  }

  function stockClearDonate() {
    app.service("users").removeStock({ type: "promoClearDonate" });
  }

  function limitChange() {
    app.service("users").removeStock({ type: "limitChange" });
  }

  function warOrdensClose() {
    app.service("users").removeStock({ type: "warOrdensClose" });
  }

  function warOrdensClear() {
    app.service("users").removeStock({ type: "warOrdensClear" });
  }

  function updateTalents() {
    app.service("users").updateTalentsInfo({});
  }

  function updateMagic() {
    app.service("users").updateMagic({});
  }

  function raidBossClose() {
    app.service("users").raidBossClose({ name: "end" });
  }

  function raidBossClear() {
    app.service("users").raidBossClose({ name: "clear" });
  }

  function tournament21Close() {
    app.service("users").removeStock({ type: "tournament21Close" });
  }

  function tournamentDiceClose() {
    app.service("users").removeStock({ type: "tournamentDiceClose" });
  }

  function tournamentArenaClose() {
    app.service("users").removeStock({ type: "tournamentArenaClose" });
  }

  function tournamentClear() {
    app.service("users").removeStock({ type: "tournamentClear" });
  }

  function handleClick(func) {
    setFunc({ func });
  }

  const array = [
    "Amazon-Elastic-Container-Service.svg",
    "Amazon-Elastic.svg",
    "Amazon-S3.svg",
    "Ansible.svg",
    "AWS-DynamoDB.svg",
    "AWS.svg",
    "Azure-Blob-Storage.svg",
    "Azure-Data-Lake.svg",
    "Azure-DevOps.svg",
    "Azure-Functions.svg",
    "Azure-IoT-Hub.svg",
    "Azure-Notification-Hub.svg",
    "azure-pipline.svg",
    "Azure-SQL-Database.svg",
    "Azure-Web-Apps.svg",
    "DigitalOcean.svg",
    "Docker.svg",
    "Droplets.svg",
    "Google-BigTable.svg",
    "Google-Cloud-Datastore.svg",
    "Google-Cloud-IoT.svg",
    "Google-Cloud-Platform-GCP.svg",
    "Google-Cloud-SQL.svg",
    "Kubernetes.svg",
    "Microsoft-Azure-Application-Insights.svg",
    "Microsoft-Azure.svg",
    "OpenShift.svg",
    "PowerShell.svg",
    "Terraform.svg",
    "Tesseract.svg",
    "Virtual-Machine.svg",
  ];

  const data = [
    {
      h: `LMS consulting`,
      p: `Our LMS consultants will help you specify the solution requirements, prioritize features, choose the right tech stack, and outline an implementation roadmap. With our hands-on LMS expertise, we’ll also estimate the development timeframe time and budget.`,
    },

    {
      h: `Custom LMS development`,
      p: `Our LMS development company delivers full-cycle services — from requirements analysis and design to testing and deployment. Following best engineering practices and using the optimal technology stack, we ensure LMS scalability, fault tolerance and performance.`,
    },

    {
      h: `Platform-based software development`,
      p: `Looking to reduce costs and time to market? Our LMS professionals can adapt market-ready eLearning solutions (think Totara, Chamilo, Moodle, and ILIAS) for your content and learning process specifics.`,
    },

    {
      h: `LMS integration`,
      p: `We’ll integrate your LMS with software for trainers (CMS, CRM) and trainees (Google Office, Google Classroom) to help you create a coherent, intuitive learning ecosystem. We'll also incorporate APIs for online conferencing, discussion forums, and live chats.`,
    },

    {
      h: `LMS automation`,
      p: `Offer a seamless user experience by saving their time and effort. Our custom LMS development team automates numerous tasks that involve course management, user enrollment, certificate issuance, reporting, scheduling, and notifications.`,
    },

    {
      h: `LMS customization`,
      p: `Whether you have the most specific LMS requirements and user scenarios ever, we’ll implement them. Our LMS experts can deliver a range of features like gamification, leaderboards, trainee performance training, content personalization, and AR/VR modes.`,
    },

    {
      h: `Mobile LMS development`,
      p: `We create UX-optimized, responsive, and visually appealing mobile apps that offer on-the-go access to education. By building LMS solutions for iOS and Android, our engineers help you stand out with advanced technologies, such as AI, AR, VR, and MR.`,
    },

    {
      h: `LMS modernization`,
      p: `Keep up with changing end-user needs with your LMS revamped by our team. Whether you want to redesign the user interface, add new features, integrate APIs, migrate to the cloud, or upgrade to the latest system version — we’re here to help.`,
    },

    {
      h: `LMS migration`,
      p: `Do you aim to address performance, scalability, or security issues and ensure a smooth user experience? Whether you need to move away from an outdated tech stack, an on-premise solution, or the current cloud provider, we’ll conduct migration with zero downtime.`,
    },

    {
      h: `White-label LMS solution`,
      p: `Attract users and generate revenue with our white-label LMS development services. We build fully customizable LMSs and provide maintenance to ship upgrades and new functionality on your clients’ demand. We can roll out new features every 1-2 weeks if needed.`,
    },
  ];

  return (
    <S.Wrapper>
      <Button
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div>Админка</div>
      </Button>
      <Popup isOpen={open} setIsOpen={setOpen} w={506} h={502} back={frame4}>
        <Close
          onClick={() => {
            setOpen(false);
          }}
        />
        <S.Content>
          {/*<div>*/}
          {/*  {array.map((elem, i) => {*/}
          {/*    return (*/}
          {/*      <img*/}
          {/*        src={`https://innowise.com/wp-content/uploads/2025/02/${elem}`}*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*  {data.map(({ h, p }, i) => {*/}
          {/*    return (*/}
          {/*      <div className="domains__descriptions-item-new ">*/}
          {/*        <div className="slider-title-new">*/}
          {/*          <h3>{h}</h3>*/}
          {/*        </div>*/}
          {/*        <p className="slider-desc-new">{p}</p>*/}
          {/*        <div className="slider-image-block-new">*/}
          {/*          <img*/}
          {/*            src={`https://innowise.com/wp-content/uploads/2024/10/image-14.10-${*/}
          {/*              i + 1*/}
          {/*            }.jpg`}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}

          {/*      // <div*/}
          {/*      //   className="mobile-domains-navitem-new"*/}
          {/*      //   data-mobile-domain={`domain-${i + 1}`}*/}
          {/*      // >*/}
          {/*      //   <span>{h}</span>*/}
          {/*      //   <p className="slider-desc-new mobile-domain-list-new">*/}
          {/*      //     {p}*/}
          {/*      //     <img*/}
          {/*      //       src={`https://innowise.com/wp-content/uploads/2024/10/image-14.10-${*/}
          {/*      //         i + 1*/}
          {/*      //       }.jpg`}*/}
          {/*      //     />*/}
          {/*      //     />*/}
          {/*      //   </p>*/}
          {/*      // </div>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}

          <button tabIndex="-1" onClick={handleClick.bind(null, updateInfo)}>
            Обновить админ. инфо
          </button>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, limitChange)}>
              Повысить лимит
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, raidBossClear)}
            >
              Рейд босс очистить
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, warOrdensClear)}
            >
              Ордена очистить
            </button>
          </div>

          <div>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, raidBossClose)}
            >
              Рейд босс награды
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, warOrdensClose)}
            >
              Ордена награды
            </button>
          </div>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, removeStock)}>
              Очистить акцию банк
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, removeDamage)}
            >
              Очистить акцию урон
            </button>

            <button tabIndex="-1" onClick={handleClick.bind(null, removeBox)}>
              Очистить акцию коробки
            </button>

            <button tabIndex="-1" onClick={handleClick.bind(null, stockClear)}>
              Очистить промку
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, stockClearDonate)}
            >
              Очистить промку донат
            </button>

            <button tabIndex="-1" onClick={handleClick.bind(null, shytClear)}>
              Шут очистить
            </button>
          </div>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, jobEveryDay)}>
              Конец дня
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, updateLimits)}
            >
              Сброс лимитов
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournamentClear)}
            >
              Турнир очистить
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournament21Close)}
            >
              Турнир 21 награды
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournamentDiceClose)}
            >
              Турнир кости награды
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournamentArenaClose)}
            >
              Турнир арена награды
            </button>

            {/*<button tabIndex="-1" onClick={handleClick.bind(null, updateTalents)}>*/}
            {/*  БД запрос*/}
            {/*</button>*/}
          </div>
        </S.Content>
      </Popup>
      <Popup isOpen={func.func} w={281} h={152} back={frame1}>
        <b>Вы уверены?</b>
        <button
          tabIndex="-1"
          onClick={() => {
            func.func();
            setFunc({});
          }}
        >
          Да
        </button>
        <button
          tabIndex="-1"
          onClick={() => {
            setFunc({});
          }}
        >
          Нет
        </button>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(AdminPanel);
