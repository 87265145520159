import React, { useEffect, useState } from "react";
import * as S from "./RaidBoss.styles";
import {
  bosses2Array,
  bossesArray,
  eventBossArray,
  grindData,
  raidBossesArray,
  soloBossArray,
  spellsArray,
} from "../../info/data";

import imgTongue from "../../img/Tongue.png";

import trollImg from "../../img/bossesPictures/Troll.png";
import krysyakiImg from "../../img/bossesPictures/Krysyaki.png";
import snegovikImg from "../../img/bossesPictures/Snegovik.png";
import bunshiImg from "../../img/bossesPictures/Bunshi.png";
import inspectorImg from "../../img/bossesPictures/Inspector.png";
import dvylikiyImg from "../../img/bossesPictures/Dvylikiy.png";
import boshkiImg from "../../img/bossesPictures/Boshki.png";

import ActiveRaidBoss from "../../components/ActiveRaidBoss/ActiveRaidBoss";
import { roundProperty } from "../../helpers";
import Reward from "../../components/Reward/Reward";
import { Rewards } from "../../components/ActiveBoss/ActiveBoss.styles";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import { CentredWrapper } from "../../App.styles";
import BossInterface from "../../components/BossInterface/BossInterface";
import { MarkerButtonStyled } from "../Bosses/Bosses.styles";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

const photos = [
  trollImg,
  krysyakiImg,
  snegovikImg,
  bunshiImg,
  inspectorImg,
  dvylikiyImg,
  boshkiImg,
];

function RaidBoss(props) {
  const [sumCharac, setSumCharac] = useState(
    Math.floor(props.getCharacterSum(props.user) / 1000)
  );

  const [goldCount, setGoldCount] = useState(
    Math.floor(props.getCharacterSum(props.user) / 10)
  );

  const [maxDamage, setMaxDamage] = useState(0);
  const [countAttacks, setCountAttacks] = useState(0);
  const [healsCount, setHealsCount] = useState(0);

  useEffect(() => {
    props.changeOptionsLocation("raidBoss");
  }, []);

  useEffect(() => {
    const characSum = props.getCharacterSum(props.user);
    // const characSum = 500000;
    let gold = Math.floor(characSum / 5) + 500;

    if (gold > 100000) {
      gold = 100000;
    }

    setSumCharac(Math.floor(characSum / 1000));
    setGoldCount(Math.floor(gold * 0.75));

    let attacks = 5000;

    for (let i = 0; i <= Math.floor(characSum / 500); i++) {
      attacks += 250;
    }

    if (attacks > 50000) {
      attacks = 50000;
    }

    setCountAttacks(attacks);

    let heals = Math.floor(attacks / 50) + Math.floor(gold * 0.25);

    if (heals > 25000) {
      heals = 25000;
    }

    setHealsCount(heals);

    if (props.isActive >= 0) {
      setMaxDamage(calcInfoDamage(props.user));
    }
  }, [props.user, props.isActive]);

  useEffect(() => {
    if (props.isActive === -1) {
      props.setIsModalOpen(false);
      props.moveLocation("home");
    }
  }, [props.isActive]);

  function calcInfoDamage({ characteristic, damageTalents, grind, spells }) {
    const force = characteristic.force;
    const spell = spellsArray[raidBossesArray[props.isActive].classType];

    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const forceAll = Math.floor(
      Object.values(force).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.force;
        }
        return acc + cur;
      }, 0)
    );

    return Math.floor(forceAll * spell.damage);
  }

  function handleAttackBoss() {
    if (
      !(
        props.isActive > -1 &&
        props.serverTime > props.endTime - props.maxTime * 3600000
      )
    ) {
      props.setModalError("Босс ещё не доступен для нападения");
    } else {
      if (props.user.activeBoss.number > -1) {
        props.setModalError(
          `Вы уже находитесь в бою с "${
            props.user.activeBoss.isShyt
              ? "Шутом"
              : props.user.activeBoss.type === "location"
              ? soloBossArray[props.user.activeBoss.number][0].name
              : props.user.activeBoss.type === "eventBoss"
              ? eventBossArray[props.user.activeBoss.number].name
              : props.user.activeBoss.type === "dungeon2"
              ? bosses2Array[props.user.activeBoss.number][0].name
              : bossesArray[props.user.activeBoss.number][0].name
          }"`
        );
      } else {
        props.setIsFetching(true);
        props.app
          .service("users")
          .patch(
            props.user._id,
            {
              "raidBoss.maxDamage": maxDamage * countAttacks,
              "raidBoss.meReward": {
                gold: goldCount,
                heals: healsCount,
              },
              "raidBoss.isAttack": true,
              "raidBoss.countAttacks": 0,
              "raidBoss.myDamage": 0,
              "raidBoss.quests": [false, false, false, false],
              "raidBoss.damageLog": [],
              field: props.serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "raidBoss"],
              },
            }
          )
          .then((data) => {
            props.setUser((prev) => ({ ...prev, ...data }));
            setTimeout(() => {
              props.setIsFetching(false);
            }, 100);
          })
          .catch((e) => {
            props.setIsFetching(false);
            console.log(e);
          });
      }
    }
  }

  return (
    props.isActive > -1 && (
      <>
        {props.isAttack ? (
          <>
            <BossInterface bossLog={props.bossLog} />
            <CentredWrapper>
              <ActiveRaidBoss
                {...props}
                photos={photos}
                damage={sumCharac * props.damage + props.damage}
              />
            </CentredWrapper>
          </>
        ) : (
          <S.Content>
            <S.BookPages>
              <div>
                <S.Text size={18}>
                  {raidBossesArray[props.isActive].name}
                </S.Text>

                <S.Text size={14}>Тип: Рейдовый Босс</S.Text>

                <S.Picture
                  src={photos[props.isActive]}
                  width={240}
                  height={166}
                  alt=""
                />

                <S.Text size={14}>
                  Класс: {types[raidBossesArray[props.isActive].classType]}
                </S.Text>

                <S.Text>{raidBossesArray[props.isActive].string}</S.Text>
              </div>
              <div>
                <S.Text>{raidBossesArray[props.isActive].text[0]}</S.Text>

                <S.Line />

                <S.Text>Характеристики:</S.Text>

                <S.Columns2>
                  <div>
                    Здоровье:
                    <span>
                      {roundProperty(props.allHp).toLocaleString("ru")}
                    </span>
                  </div>
                  <div>
                    Урон:
                    <span>
                      {props.percentDamage}% /{" "}
                      {roundProperty(
                        sumCharac * props.damage + props.damage
                      ).toLocaleString("ru")}
                    </span>
                  </div>
                </S.Columns2>

                <S.Columns2>
                  <div>
                    Крит:
                    <span>
                      {raidBossesArray[props.isActive].bossKritChance}%
                    </span>
                  </div>
                  <div>
                    Уклонение:
                    <span>
                      {raidBossesArray[props.isActive].bossEvasionChance}%
                    </span>
                  </div>
                </S.Columns2>

                <S.Line />

                <S.Text>Максимальная награда:</S.Text>

                <Rewards>
                  <RewardWrapper light={true} w={65}>
                    <div>
                      <Reward
                        name={"gold"}
                        count={goldCount}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#281707"}
                      />
                    </div>
                  </RewardWrapper>
                  <RewardWrapper light={true} w={65}>
                    <div>
                      <Reward
                        name={"potion"}
                        category={"hp"}
                        potionName={"+100%"}
                        count={healsCount}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#281707"}
                      />
                    </div>
                  </RewardWrapper>
                </Rewards>

                <S.Line />

                <S.Text>{raidBossesArray[props.isActive].text[1]}</S.Text>

                <S.ButtonsWrapper>
                  <MarkerButtonStyled
                    onClick={!props.isFetching ? handleAttackBoss : null}
                  >
                    <div>Вступить в сражение</div>
                  </MarkerButtonStyled>
                </S.ButtonsWrapper>
              </div>
            </S.BookPages>
            <S.Tongue src={imgTongue} width={97} height={238} alt="язык" />
          </S.Content>
        )}
      </>
    )
  );
}

export default React.memo(RaidBoss);
