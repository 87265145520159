import React, { useCallback, useEffect, useState } from "react";
import * as S from "./ArenaCharacteristics.styles";
import imgHealth from "../../img/book/Health.png";
import imgForce from "../../img/book/Force.png";
import imgPower from "../../img/book/Power.png";
import imgProtection from "../../img/book/Protection.png";
import imgAccuracy from "../../img/book/Accuracy.png";
import imgEvasion from "../../img/book/Evasion.png";
import imgSpeed from "../../img/book/Speed.png";
import { Header } from "../NewUserQuests/NewUserQuests.styles";
import Reward from "../Reward/Reward";
import ArenaCharact from "../ArenaCharact/ArenaCharact";
import { Button } from "../../panels/Arena/Arena.styles";

const essenceAll = 1000000000;

const names = {
  health: {
    img: imgHealth,
    name: "Здоровье",
    string:
      "Чем выше этот важный показатель, тем больше моя готовность вступить в опасную схватку с самым сильным противником волшебного мира",
  },
  force: {
    img: imgForce,
    name: "Сила магии",
    string:
      "В школе необходимо постоянно изучать новые заклинания, однако их эффективность напрямую зависит от силы магии, влияющей на мой урон",
  },
  power: {
    img: imgPower,
    name: "Ярость",
    string:
      "Увеличит шанс на критический урон, а также даст возможность пробить защиту врага",
  },
  protection: {
    img: imgProtection,
    name: "Сопротивление",
    string:
      "С каждым разом, тренируя этот показатель, я делаю себя более устойчивым к критическим атакам врага, а также повышаю шанс блока заклинания",
  },
  accuracy: {
    img: imgAccuracy,
    name: "Точность",
    string: "Уменьшит мой шанс промазать по врагу в самый ответственный момент",
  },
  evasion: {
    img: imgEvasion,
    name: "Ловкость",
    string:
      "Ловкость дает преимущество даже перед сильнейшим врагом - у меня будет шанс уклониться от атаки противника",
  },
  speed: {
    img: imgSpeed,
    name: "Скорость",
    string:
      "Иногда скорость может быть решающим фактором в сражении бок о бок со своими партнерами. У кого выше скорость - тот и атакует первым",
  },
};

const namesArray = Object.entries(names);

function ArenaCharacteristics({
  activeRegister,
  user,
  isOpen,
  setIsOpen,
  isFetching,
  setIsFetching,
  setUser,
  app,
  setModalError,
  serverTime,
  setAdmin,
}) {
  const [characteristic, setCharacteristic] = useState(
    user.arenaTournament.characteristics
  );
  const [essence, setEssence] = useState(user.arenaTournament.essence);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCharacteristic(user.arenaTournament.characteristics);
    setEssence(user.arenaTournament.essence);
  }, [user]);

  function sumPrice(price, count) {
    let newPrice = price;
    let newCount = 1;
    for (let i = 1; i < count; i++) {
      if (newPrice + price + 10 * i <= essence) {
        newPrice += price + 10 * i;
        newCount++;
      }
    }
    return { count: newCount, price: newPrice };
  }

  function sumPriceMinus(price, count) {
    let newPrice = price;
    let newCount = -1;
    for (let i = count + 1; i < 0; i++) {
      if (price + 10 * i >= 1010) {
        newPrice += price + 10 * i;
        newCount--;
      }
    }

    return { count: newCount, price: -newPrice };
  }

  const changeMoney = useCallback(
    ({
      health = 0,
      force = 0,
      power = 0,
      protection = 0,
      accuracy = 0,
      evasion = 0,
      speed = 0,
    }) => {
      let transaction = 1;
      let essencePrice = 0;

      if (health > 0) {
        if ((characteristic.health + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.health + 1) * 10, health);
          health = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (health < 0) {
        if (characteristic.health > 100) {
          const info = sumPriceMinus(characteristic.health * 10, health);
          health = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (force > 0) {
        if ((characteristic.force + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.force + 1) * 10, force);
          force = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (force < 0) {
        if (characteristic.force > 100) {
          const info = sumPriceMinus(characteristic.force * 10, force);
          force = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (power > 0) {
        if ((characteristic.power + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.power + 1) * 10, power);
          power = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (power < 0) {
        if (characteristic.power > 100) {
          const info = sumPriceMinus(characteristic.power * 10, power);
          power = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (protection > 0) {
        if ((characteristic.protection + 1) * 10 <= essence) {
          const info = sumPrice(
            (characteristic.protection + 1) * 10,
            protection
          );
          protection = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (protection < 0) {
        if (characteristic.protection > 100) {
          const info = sumPriceMinus(
            characteristic.protection * 10,
            protection
          );
          protection = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (accuracy > 0) {
        if ((characteristic.accuracy + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.accuracy + 1) * 10, accuracy);
          accuracy = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (accuracy < 0) {
        if (characteristic.accuracy > 100) {
          const info = sumPriceMinus(characteristic.accuracy * 10, accuracy);
          accuracy = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (evasion > 0) {
        if ((characteristic.evasion + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.evasion + 1) * 10, evasion);
          evasion = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (evasion < 0) {
        if (characteristic.evasion > 100) {
          const info = sumPriceMinus(characteristic.evasion * 10, evasion);
          evasion = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (speed > 0) {
        if ((characteristic.speed + 1) * 10 <= essence) {
          const info = sumPrice((characteristic.speed + 1) * 10, speed);
          speed = info.count;
          essencePrice = info.price;
          transaction = essencePrice <= essence;
        } else {
          transaction = -1;
        }
      }

      if (speed < 0) {
        if (characteristic.speed > 100) {
          const info = sumPriceMinus(characteristic.speed * 10, speed);
          speed = info.count;
          essencePrice = info.price;
          transaction = true;
        } else {
          transaction = -1;
        }
      }

      if (transaction > 0) {
        setLoading(true);
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              $inc: {
                "arenaTournament.characteristics.health": health,
                "arenaTournament.characteristics.force": force,
                "arenaTournament.characteristics.power": power,
                "arenaTournament.characteristics.protection": protection,
                "arenaTournament.characteristics.accuracy": accuracy,
                "arenaTournament.characteristics.evasion": evasion,
                "arenaTournament.characteristics.speed": speed,
                "arenaTournament.essence": -essencePrice,
              },
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "arenaTournament"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setLoading(false);
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setLoading(false);
            setIsFetching(false);
          });
      } else if (!transaction) {
        console.log("Недостаточно эссенций");

        setModalError({
          string: "Недостаточно эссенций",
          buttons: ["bank"],
        });
      }
    },
    [characteristic, essence, serverTime]
  );

  function startRegister() {
    if (!activeRegister) {
      setModalError("Регистрация закончена");
      console.log("Регистрация закончена");
    } else {
      setIsFetching(true);

      const characSum = Object.entries(characteristic).reduce(
        (acc, cur) => acc + cur[1],
        0
      );

      app
        .service("admin")
        .patch(
          user.admin,
          {
            [`arenaTournament.users.${user.email}`]: {
              _id: user._id,
              rate: characSum,
              count: 0,
              maxPoints: 0,
              lvl: user.lvl,
              ...characteristic,
              hp: characteristic.health * 5,
            },
          },
          {
            query: {
              $select: ["arenaTournament"],
            },
          }
        )
        .then((adminData) => {
          console.log(adminData);
          setAdmin((prev) => ({ ...prev, ...adminData }));
          app
            .service("users")
            .patch(
              user._id,
              {
                "arenaTournament.isRegister": true,
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "arenaTournament"],
                },
              }
            )
            .then((data) => {
              setIsOpen(false);
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setModalError(e);
              console.log(e);
              setIsFetching(false);
            });
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    }
  }

  return (
    <S.Wrapper isOpen={isOpen}>
      <Header>Запись на Турнир Дуэлей</Header>

      <span>
        Прокачайте характеристики Вашего персонажа распределив доступные
        эссенции
      </span>

      <Reward
        name={"essence"}
        count={`Доступно эссенций: ${essence.toLocaleString(
          "ru"
        )} (${Math.floor((essence / essenceAll) * 100)}%)`}
        w={15}
        button={true}
      />

      <S.CharactWrapper>
        {namesArray.map((el, i) => {
          return (
            <ArenaCharact
              key={i}
              isLoading={isFetching || loading}
              charac={el}
              characteristic={characteristic}
              changeMoney={changeMoney}
            />
          );
        })}
      </S.CharactWrapper>

      <Button
        width={130}
        disabled={essence / essenceAll > 0.1}
        onClick={
          essence / essenceAll <= 0.1 && !isFetching && !loading
            ? startRegister
            : null
        }
      >
        <div>Подать заявку</div>
      </Button>
    </S.Wrapper>
  );
}

export default React.memo(ArenaCharacteristics);
