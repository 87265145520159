import React, { useEffect, useState } from "react";
import * as S from "./DamageTop.styles";
import { roundPropertyLow } from "../../helpers";

function DamageTop({
  damageTop,
  allUsers,
  name,
  nickName,
  orden,
  email,
  allHp,
}) {
  const [arrayDamage, setArrayDamage] = useState([]);
  const [meDamage, setMeDamage] = useState({
    place: -1,
    percent: 0,
    damage: 0,
  });

  useEffect(() => {
    if (damageTop) {
      const damageInfo = Object.entries(damageTop).sort((a, b) => b[1] - a[1]);

      if (damageInfo.length > 0) {
        const index = damageInfo.findIndex((elem, i) => email == elem[0]);
        if (index > -1) {
          setMeDamage({
            place: index + 1,
            damage: damageInfo[index][1],
            name: name,
            nickName: nickName,
            orden: orden,
            percent: (((damageInfo[index][1] || 0) / allHp) * 100).toFixed(2),
          });
        } else {
          setMeDamage({
            place: -1,
            percent: 0,
            damage: 0,
          });
        }

        setArrayDamage(
          damageInfo
            .map((elem) => {
              const user = allUsers.find((user) => user.email == elem[0]);
              return {
                email: user?.email,
                damage: elem[1],
                name: user?.name,
                nickName: user?.nickName,
                orden: user?.orden,
                percent: (((elem[1] || 0) / allHp) * 100).toFixed(2),
              };
            })
            .sort((a, b) => b.damage - a.damage)
            .slice(0, 100)
        );
      }
    }
  }, [damageTop, allUsers, orden, name, nickName]);

  return arrayDamage.length > 0 ? (
    <>
      {arrayDamage.map(
        ({ name, damage, nickName, orden, email: emailLocal, percent }, i) => {
          return (
            <S.DamageInfo key={i} isMe={email == emailLocal}>
              <div>
                <span>{i + 1}. </span>
                <span>{orden?.tag ? `[${orden?.tag}] ` : ""}</span>
                <span>
                  {nickName
                    ? nickName + " "
                    : name?.firstName + " " + name?.lastName + " "}
                  = {roundPropertyLow(damage).toLocaleString("ru")} ({percent}%)
                </span>
              </div>
            </S.DamageInfo>
          );
        }
      )}
      {meDamage.place >= 100 && (
        <S.DamageInfo isMe={true}>
          <div>
            <span>{meDamage.place}. </span>
            <span>
              Вы = {roundPropertyLow(meDamage.damage).toLocaleString("ru")} (
              {meDamage.percent}%)
            </span>
          </div>
        </S.DamageInfo>
      )}
    </>
  ) : null;
}

export default React.memo(DamageTop);
