import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7b471;
  background-color: ${(props) => !props.img && "rgba(0, 0, 0, 0.6)"};
  background-image: url(${(props) => (props.img ? props.img : "")});
  background-size: cover;

  transition: ${(props) => (props.isMove ? "none" : "opacity 0.3s ease")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? (props.z ? props.z : "1") : "-1")};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => (props.w ? props.w : 250)}px;
  height: ${(props) => (props.h ? `${props.h}px` : "auto")};
  background-image: url(${(props) => (props.back ? props.back : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${(props) => (props.padding ? props.padding : "20px")};

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justify && props.justify};
    gap: 8px;
    text-align: center;
    align-items: center;
    height: 100%;
    font-family: "Bellota-Regular";
    font-size: 14px;

    & > b {
      font-size: 15px;
    }
  }
`;
