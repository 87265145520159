import React, { useCallback, useEffect, useState } from "react";

import * as S from "./Spell.styles";
import InfoDamage from "../InfoDamage/InfoDamage";

import { grindData, spellsArray, stockDamage } from "../../info/data";

import imgMana from "../../img/book/Mana.png";
import {
  ButtonChange,
  ButtonChangeWrapper,
  ButtonValue,
} from "../Healls/Healls.styles";

function Spell({
  grind,
  name,
  damage,
  krit,
  kritChance,
  mana,
  force,
  power,
  accuracy,
  isFetching,
  isModalOpen,
  dealDamage,
  setIsFetching,
  setLoading,
  damageTalents,
  bossDamage,
  userHp,
  userMana,
  setModalError,
  stockLvl,
  damageScales,
  damageBase,
  deffence,
  left,
  bottom,
  imgBoss,
  bossesInfo,
  setBossesInfo,
  serverTime,
  isRaid,
  lvl,
  balance,
  currentSpell,
  setBossAuto,
}) {
  const [countAttacks, setCountAttacks] = useState(
    bossesInfo[`countAttack`] > 0 ? bossesInfo[`countAttack`] : 1
  );

  const [spellsBonus, setSpellsBonus] = useState({
    mana: 40,
    krit: 0,
    deffence: 0,
  });

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      [`countAttack`]: countAttacks || 1,
    }));
  }, [countAttacks]);

  useEffect(() => {
    let bonus = 0;

    for (let i = 0; i < lvl; i++) {
      if ((i + 1) % 10 === 0) {
        bonus += 1;
      }
    }

    if (bonus > 5) {
      bonus = 5;
    }

    setSpellsBonus({
      mana: mana - (balance - 5) * 4,
      krit: bonus > 0 ? bonus * (10 - balance) * 0.04 : (5 - balance) * 0.04,
      deffence: bonus > 0 ? bonus * balance * 0.01 : (balance - 5) * 0.01,
    });
  }, [lvl, balance, mana]);

  function changeCount(add) {
    setCountAttacks((prev) => {
      if (add) {
        if (+prev + 1 > 100) {
          return 100;
        }
        return +prev + 1;
      } else {
        if (+prev - 1 >= 1) {
          return +prev - 1;
        }
      }
      return +prev;
    });
  }

  function handleInput(e) {
    let value = e.target.value;
    if (value > 100) {
      if (value >= 1) {
        value = 100;
      } else {
        value = 1;
      }
    }

    setCountAttacks(!isNaN(value) ? Math.abs(+Math.floor(value)) : "");
  }

  const calcInfoDamage = useCallback(() => {
    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const forceAll = Math.floor(
      Object.values(force).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.force;
        }
        return acc + cur;
      }, 0)
    );

    let stockScale = 1;

    if (stockLvl.damage === 0 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[0].scale;
    }
    if (stockLvl.damage === 1 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[1].scale;
    }
    if (stockLvl.damage === 2 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[2].scale;
    }

    let lvlDamage = 1;

    for (let i = 1; i < lvl; i++) {
      if ((i + 1) % 10 !== 0) {
        lvlDamage += 0.005;
      }
    }

    return Math.floor(forceAll * damage * lvlDamage * stockScale);
  }, [grind, force, stockLvl, damage, lvl]);

  function calcDamage({ damage, krit }) {
    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const forceAll = Math.floor(
      Object.values(force).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.force;
        }
        return acc + cur;
      }, 0)
    );

    const rand = (Math.random() * 100).toFixed(1);
    let stockScale = 1;
    if (stockLvl.damage === 0 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[0].scale;
    }
    if (stockLvl.damage === 1 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[1].scale;
    }
    if (stockLvl.damage === 2 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[2].scale;
    }

    let lvlDamage = 1;

    for (let i = 1; i < lvl; i++) {
      if ((i + 1) % 10 !== 0) {
        lvlDamage += 0.005;
      }
    }

    return 100 - kritChance - rand > 0
      ? {
          value: Math.floor(forceAll * damage * lvlDamage * stockScale),
          isKrit: false,
        }
      : {
          value: Math.floor(forceAll * damage * lvlDamage * stockScale),
          krit: krit,
          isKrit: true,
        };
  }

  function handleClick() {
    setLoading(true);
    setIsFetching(true);
    let isAttack = true;
    if (userHp <= 0) {
      isAttack = false;

      console.log("Недостаточно здоровья");

      setModalError({
        string: "Недостаточно здоровья",
        buttons: ["shop", "inventory"],
      });
    }
    if (userMana < mana * (countAttacks || 1)) {
      isAttack = false;
      console.log("Недостаточно маны");

      setModalError({
        string: "Недостаточно маны",
        buttons: ["shop", "inventory"],
      });
    }

    if (isAttack) {
      const damageInfo = calcDamage({
        damage: damage,
        krit: krit + spellsBonus.krit,
      });
      dealDamage({
        damage: damageInfo,
        countAttacks: countAttacks || 1,
        mana: spellsBonus.mana,
        deffence: deffence + spellsBonus.deffence,
        bossDamage: bossDamage,
      });
    } else {
      setBossAuto(false);
      setLoading(false);
      setIsFetching(false);
    }
  }

  return (
    <S.Wrapper left={left} bottom={bottom}>
      <S.Content>
        <S.ImgSpell disabled={isFetching}>
          <img
            ref={currentSpell}
            onClick={!isFetching && !isModalOpen ? handleClick : null}
            src={imgBoss}
            width={46}
            height={46}
            alt={"заклинание"}
          />
          <S.InfoSpell>
            <div>
              <div>
                <span>{name}</span>
              </div>
              <div>
                <span>Крит:</span>{" "}
                {Math.round((krit + spellsBonus.krit - 1) * 100)}%
              </div>
              <div>
                <span>Снижение урона:</span>{" "}
                {Math.round((deffence + spellsBonus.deffence - 1) * 100)}%
              </div>
              <div>
                <span>Стоимость: </span>{" "}
                {spellsBonus.mana * (countAttacks || 1)}
                <img src={imgMana} width={14} height={14} alt="мана" />
              </div>
              <div>
                <span>Урон:</span>{" "}
                <InfoDamage
                  damage={damage}
                  countAttacks={countAttacks || 1}
                  calcInfoDamage={calcInfoDamage}
                />
              </div>
            </div>
          </S.InfoSpell>
        </S.ImgSpell>

        <ButtonChangeWrapper>
          <ButtonChange islast={true} onClick={changeCount.bind(null, true)}>
            <div>+1</div>
          </ButtonChange>
          <ButtonValue islast={false}>
            <S.InputAttacks>
              <input
                value={countAttacks}
                onChange={handleInput}
                type="number"
                max={100}
                min={1}
                placeholder={""}
                step={1}
              />
            </S.InputAttacks>
          </ButtonValue>
          <ButtonChange islast={false} onClick={changeCount.bind(null, false)}>
            <div>-1</div>
          </ButtonChange>
        </ButtonChangeWrapper>
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Spell);
