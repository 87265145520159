import React, { useEffect, useState } from "react";
import * as S from "./Guides.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import { Wrapper, Screen, ButtonsWrapper } from "../Guide/Guide.styles";
import { useAudioPlayer } from "react-use-audio-player";

// import soundBosses1 from "../../img/guide/bosses/1.mp3";
// import soundBosses2 from "../../img/guide/bosses/2.mp3";
// import soundBosses3 from "../../img/guide/bosses/3.mp3";
// import soundBosses4 from "../../img/guide/bosses/4.mp3";
// import soundBosses5 from "../../img/guide/bosses/5.mp3";
//
// import soundBook1 from "../../img/guide/book/1.mp3";
// import soundBook2 from "../../img/guide/book/2.mp3";
// import soundBook3 from "../../img/guide/book/3.mp3";
// import soundBook4 from "../../img/guide/book/4.mp3";
// import soundBook5 from "../../img/guide/book/5.mp3";
// import soundBook6 from "../../img/guide/book/6.mp3";
// import soundBook7 from "../../img/guide/book/7.mp3";
// import soundBook8 from "../../img/guide/book/8.mp3";
// import soundBook9 from "../../img/guide/book/9.mp3";
// import soundBook10 from "../../img/guide/book/10.mp3";
// import soundBook11 from "../../img/guide/book/11.mp3";
// import soundBook12 from "../../img/guide/book/12.mp3";
// import soundBook13 from "../../img/guide/book/13.mp3";
// import soundBook14 from "../../img/guide/book/14.mp3";

// const songs = {
//   book: [
//     soundBook1,
//     soundBook2,
//     soundBook3,
//     soundBook4,
//     soundBook5,
//     soundBook6,
//     soundBook7,
//     soundBook8,
//     soundBook9,
//     soundBook10,
//     soundBook11,
//     soundBook12,
//     soundBook13,
//     soundBook14,
//   ],
// };

const stages = {
  bosses: 5,
  bosses2: 5,
  soloBoss: 3,
  potionLab: 3,
  greenHouse: 3,
  arena: 5,
  potionRoom: 4,
  grassRoom: 4,
  book: 14,
};

const folder = {
  bosses: "bosses",
  bosses2: "bosses",
  soloBoss: "soloBoss",
  potionLab: "potionLab",
  greenHouse: "greenHouse",
  arena: "arena",
  potionRoom: "potionRoom",
  grassRoom: "grassRoom",
  book: "book",
};

function Guides({
  user,
  app,
  setUser,
  isFetching,
  setIsFetching,
  setModalError,
  location,
  serverTime,
  setActiveTabBook,
  isVisible,
  isFirstClick,
}) {
  const { load, pause, play, isReady, setVolume, mute, playing, getPosition } =
    useAudioPlayer();
  const [stepNumber, setStepNumber] = useState(0);
  const [endDobbi, setEndDobbi] = useState(false);

  useEffect(() => {
    return () => {
      pause();
    };
  }, []);

  useEffect(() => {
    if (isFirstClick) {
      const sound = require(`../../img/guide/${folder[location]}/${
        stepNumber + 1
      }.mp3`);
      // songs[folder[location]][stepNumber]

      load(sound, {
        autoplay: true,
        initialVolume: 0.65,
        html5: true,
        format: "mp3",
        onload: () => {
          setEndDobbi(true);
        },
        onend: () => {
          setEndDobbi(false);
        },
      });
    }
  }, [stepNumber, load, isFirstClick, location]);

  useEffect(() => {
    if (isReady && isVisible) {
      if (!playing && (getPosition() > 0 || endDobbi)) {
        play();
      }
    } else {
      pause();
    }
  }, [isReady, pause, play, playing, isVisible, getPosition, endDobbi]);

  function handleNextClick() {
    setEndDobbi(true);
    if (stepNumber < stages[location] - 1) {
      if (location === "book") {
        if (stepNumber === 2) {
          setActiveTabBook(1);
        }
        if (stepNumber === 4) {
          setActiveTabBook(2);
        }
        if (stepNumber === 6) {
          setActiveTabBook(3);
        }
        if (stepNumber === 10) {
          setActiveTabBook(4);
        }
        if (stepNumber === 12) {
          setActiveTabBook(2);
        }
      }
      setStepNumber((prevState) => prevState + 1);
    }
  }

  function endGuide() {
    setIsFetching(true);

    const obj = {
      [`guides.${location}`]: true,
      field: serverTime,
    };

    if (location === "potionRoom") {
      obj[`guides.grassRoom`] = true;
    }

    if (location === "grassRoom") {
      obj[`guides.potionRoom`] = true;
    }

    if (location === "bosses") {
      obj[`guides.bosses2`] = true;
    }

    if (location === "bosses2") {
      obj[`guides.bosses`] = true;
    }

    app
      .service("users")
      .patch(user._id, obj, {
        query: {
          $select: ["_id", "email", "guides"],
        },
      })
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        setIsFetching(false);
        console.log(e);
      });
  }

  return (
    <Wrapper isOpen>
      <Screen
        src={require(`../../img/guide/${folder[location]}/${
          stepNumber + 1
        }.png`)}
      />

      {stepNumber < stages[location] - 1 ? (
        <ButtonsWrapper>
          <Button width={140} onClick={handleNextClick}>
            <div>Продолжить</div>
          </Button>
        </ButtonsWrapper>
      ) : (
        <ButtonsWrapper>
          <Button width={140} onClick={!isFetching ? endGuide : null}>
            <div>Закончить</div>
          </Button>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
}

export default React.memo(Guides);
