import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./Game21.styles";
import Cards from "../Cards/Cards";
import Popup from "../Popup/Popup";
import { Close } from "../../App.styles";
import Reward from "../Reward/Reward";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import { Button } from "../../panels/Arena/Arena.styles";
import { BottomInfo, ButtonValueInput } from "../DiceGame/DiceGame.styles";
import { Header } from "../Ordens/Ordens.styles";
import { ButtonsXWrapper, ButtonXChange } from "./Game21.styles";
import betFrame from "../../img/BetFrame.png";

const suits = ["Чирва", "Пики", "Бубны", "Крести"];
const values = [
  "Туз",
  "Король",
  "Дама",
  "Валет",
  "Десятка",
  "Девятка",
  "Восьмёрка",
  "Семёрка",
  "Шестёрка",
];

function createDeck() {
  const deck = [];
  suits.forEach((suit) => {
    return values.forEach((value) => {
      deck.push({
        suit,
        value,
      });
    });
  });
  return deck;
}

function shuffleDeck() {
  const deckShuffled = createDeck();
  for (let i = deckShuffled.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [deckShuffled[i], deckShuffled[j]] = [deckShuffled[j], deckShuffled[i]];
  }
  return deckShuffled;
}

function getCardNumericValue(card) {
  switch (card.value) {
    case "Туз":
      return 1;
    case "Валет":
      return 2;
    case "Дама":
      return 3;
    case "Король":
      return 4;
    case "Шестёрка":
      return 6;
    case "Семёрка":
      return 7;
    case "Восьмёрка":
      return 8;
    case "Девятка":
      return 9;
    case "Десятка":
      return 10;
    default:
      return 10;
  }
}

const prizes = {
  ace: 4,
  img: 5,
  comb678: 5,
  comb777: 5,
};

function Game21({
  setIsModalOpen,
  isModalOpen,
  user,
  app,
  setUser,
  setIsFetching,
  setActiveGame,
  isFetching,
  setModalError,
  serverTime,
  warOrdenTask,
  betOpen,
  setBetOpen,
}) {
  const [dealerCards, setDealerCards] = useState(user.game21.dealerCards);
  const [playerCards, setPlayerCards] = useState(user.game21.playerCards);
  const [gameStarted, setGameStarted] = useState(user.game21.isGame);
  const [dealerScore, setDealerScore] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deck, setDeck] = useState(user.game21.deck);
  const [isWin, setIsWin] = useState(false);
  const [gameX, setGamesX] = useState(user.game21?.gameX || 1);
  const [prize, setPrize] = useState("");
  const isFirstDropRef = useRef(true);
  const gameOverRef = useRef(false);
  const dealerScoreRef = useRef(0);
  const playerScoreRef = useRef(0);
  const isWinRef = useRef(false);

  useEffect(() => {
    return () => {
      setActiveGame("");
    };
  }, []);

  useEffect(() => {
    if (
      user.game21.isFree > 0 &&
      user.game21.isFree <= 10 &&
      gameX > user.game21.isFree
    ) {
      setGamesX(user.game21.isFree);
    }
  }, [user]);

  useEffect(() => {
    gameOverRef.current = gameOver;
    if (gameOver) {
      getDealerCards();
    }
  }, [gameOver]);

  useEffect(() => {
    setPlayerScore(getScore(playerCards, false));
  }, [playerCards]);

  useEffect(() => {
    setDealerScore(getScore(dealerCards, true));
  }, [dealerCards]);

  function changeCount(value) {
    if (value > 100) {
      if (value >= 1) {
        value = 100;
      } else {
        value = 1;
      }
    }
    setGamesX(!isNaN(value) ? Math.abs(+Math.floor(value)) : "");
  }

  function changeCountInput(e) {
    let value = e.target.value;

    if (value > 100) {
      if (value >= 1) {
        value = 100;
      } else {
        value = 1;
      }
    }
    setGamesX(!isNaN(value) ? Math.abs(+Math.floor(value)) : "");
  }

  function getScore(cardArray, dealer) {
    let score = 0;
    let hasAce = false;
    let dblAce = 0;
    let fiveImg = 0;

    cardArray.forEach((card) => {
      score += getCardNumericValue(card);
      if (card.value === "Туз") {
        dblAce += 1;
        hasAce = true;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImg += 1;
      } else {
        fiveImg -= 1;
      }
    });

    if (dblAce === 2 && cardArray.length === 2) {
      score = 21;
    }
    if (fiveImg === 5) {
      score = 21;
    }
    if (hasAce && score + 10 <= 21) {
      score += 10;
    }

    if (dealer) {
      dealerScoreRef.current = score;
    } else {
      playerScoreRef.current = score;
      checkForEndOfGame();
    }

    if (gameOverRef.current && dealer) {
      getDealerCards();
    }

    if (
      !isFirstDropRef.current &&
      cardArray.length > 0 &&
      !gameOverRef.current
    ) {
      setIsFetching(true);
      setLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            "game21.isGame": true,
            "game21.deck": deck,
            "game21.playerCards": dealer ? user.game21.playerCards : cardArray,
            "game21.dealerCards": !dealer ? user.game21.dealerCards : cardArray,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "game21"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));

          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setLoading(false);
        });
    }

    return score;
  }

  const checkWin = useCallback(() => {
    let dblAceDealer = 0;
    let dblAcePlayer = 0;
    let fiveImgDealer = 0;
    let fiveImgPlayer = 0;

    dealerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAceDealer += 1;
      } else {
        dblAceDealer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgDealer += 1;
      } else {
        fiveImgDealer -= 1;
      }
    });

    playerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAcePlayer += 1;
      } else {
        dblAcePlayer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgPlayer += 1;
      } else {
        fiveImgPlayer -= 1;
      }
    });

    if (playerScoreRef.current > 21) {
      setIsWin(false);
      isWinRef.current = false;
    } else if (dealerScoreRef.current > 21) {
      setIsWin(true);
      isWinRef.current = true;
    } else if (playerScoreRef.current > dealerScoreRef.current) {
      setIsWin(true);
      isWinRef.current = true;
    } else {
      setIsWin(false);
      isWinRef.current = false;
    }
    if (playerCards.length === 2 && dblAcePlayer === 2) {
      setPrize("ace");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (fiveImgPlayer === 5) {
      setPrize("img");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (
      playerCards.length === 3 &&
      ["Шестёрка", "Семёрка", "Восьмёрка"].filter(
        (item) => !playerCards.map((elem) => elem.value).includes(item)
      ).length === 0
    ) {
      setPrize("comb678");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (
      playerCards.length === 3 &&
      playerCards.every((elem) => elem.value === "Семёрка")
    ) {
      setPrize("comb777");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (!isFirstDropRef.current) {
      setIsFetching(true);
      setLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            "game21.isGame": true,
            "game21.deck": deck,
            "game21.playerCards": playerCards,
            "game21.dealerCards": dealerCards,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "game21"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));

          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setLoading(false);
        });
    }
  }, [playerCards, dealerCards]);

  function getDealerCards() {
    if (
      dealerScoreRef.current < playerScoreRef.current &&
      playerScoreRef.current <= 21 &&
      dealerScoreRef.current <= 21 &&
      dealerCards.length < 5
    ) {
      isFirstDropRef.current = false;
      setDeck((prev) => {
        setDealerCards((prevState) => {
          return [...prevState, prev[prev.length - 1]];
        });
        return [...prev].slice(0, -1);
      });
    } else {
      checkWin();
      setTimeout(() => {
        setIsModalOpen(true);
      }, 400);
    }
  }

  function checkForEndOfGame() {
    let dblAceDealer = 0;
    let dblAcePlayer = 0;
    let fiveImgDealer = 0;
    let fiveImgPlayer = 0;

    dealerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAceDealer += 1;
      } else {
        dblAceDealer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgDealer += 1;
      } else {
        fiveImgDealer -= 1;
      }
    });

    playerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAcePlayer += 1;
      } else {
        dblAcePlayer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgPlayer += 1;
      } else {
        fiveImgPlayer -= 1;
      }
    });

    if (playerScoreRef.current >= 21) {
      setGameOver(true);
    } else if (
      (playerCards.length === 2 && dblAcePlayer === 2) ||
      playerCards.length === 5 ||
      dealerCards.length > 2
    ) {
      setGameOver(true);
    }
  }

  function startGame(value) {
    if (value) {
      const deck = shuffleDeck();
      const dealerCard1 = deck.shift();
      const dealerCard2 = deck.shift();
      const playerCard1 = deck.shift();
      const playerCard2 = deck.shift();

      const price = (gameX || 1) - user.game21.isFree;
      let free = 0;

      if (user.game21.isFree > 0) {
        if (user.game21.isFree >= (gameX || 1)) {
          free = gameX || 1;
        } else {
          free = user.game21.isFree;
        }
      }

      if (user.gold + free >= price * 2) {
        setIsFetching(true);
        setLoading(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              "game21.isGame": true,
              "game21.gameX": gameX,
              "game21.deck": deck,
              "game21.dealerCards": [dealerCard1, dealerCard2],
              "game21.playerCards": [playerCard1, playerCard2],
              $inc: {
                gold: price > 0 ? -price * 2 : 0,
                "game21.isFree": -free,
                "dayQuest.game21Games": 1,
                "summerSave.results.game21Games": 1,
              },
              field: serverTime,
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "game21",
                  "gold",
                  "dayQuest",
                  "summerSave",
                ],
              },
            }
          )
          .then((data) => {
            setBetOpen(false);
            setDeck(data.game21.deck);
            setGameStarted(value);
            setGameOver(false);
            setIsWin(false);
            setPlayerCards([playerCard1, playerCard2]);
            setDealerCards([dealerCard1, dealerCard2]);
            setUser((prev) => ({ ...prev, ...data }));

            setIsFetching(false);
            setLoading(false);
          })
          .catch((e) => {
            setIsFetching(false);
            setLoading(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        setBetOpen(false);

        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      }
    } else {
      setGameOver(true);
    }
  }

  function endGame() {
    setIsFetching(true);
    setLoading(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "game21.gameX": 1,
          "game21.isGame": false,
          "game21.deck": [],
          "game21.dealerCards": [],
          "game21.playerCards": [],
          $inc: {
            "game21.countGames": 1,
            "game21.countWins": isWin ? 1 : 0,
            "dayQuest.game21Win": isWin ? 1 : 0,
            "dayQuest.game21Combinations": prizes[prize] ? 1 : 0,
            "summerSave.results.game21Win": isWin ? 1 : 0,
            "summerSave.results.game21Aces": prize === "ace" ? 1 : 0,
            "achievements.game21.ace": prize === "ace" ? 1 : 0,
            "achievements.game21.img": prize === "img" ? 1 : 0,
            "achievements.game21.678": prize === "comb678" ? 1 : 0,
            "achievements.game21.777": prize === "comb777" ? 1 : 0,

            [`raidActiveTask.tasks.game21Combs`]:
              prize === "img" ||
              prize === "ace" ||
              prize === "comb678" ||
              prize === "comb777"
                ? 1
                : 0,

            [`bosses.${
              prize.length > 0
                ? prizes[prize]
                : isWin && playerScore === 21
                ? 3
                : isWin && playerScore === 20
                ? 2
                : isWin && playerScore <= 19
                ? 1
                : 0
            }.souls`]:
              prize.length > 0
                ? 1 * (user.game21?.gameX || 1)
                : isWin && playerScore === 21
                ? 1 * (user.game21?.gameX || 1)
                : isWin && playerScore === 20
                ? 1 * (user.game21?.gameX || 1)
                : isWin && playerScore <= 19
                ? 1 * (user.game21?.gameX || 1)
                : 0,
            weakGamesPoints:
              prize.length > 0
                ? prizes[prize] * 20
                : isWin && playerScore === 21
                ? 25
                : isWin && playerScore === 20
                ? 15
                : isWin && playerScore <= 19
                ? 10
                : 0,
            "newQuest.results.game21": 1,
          },
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "game21",
              "bosses",
              "dayQuest",
              "weakGamesPoints",
              "newQuest",
              "summerSave",
              "achievements",
              "raidActiveTask",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setLoading(false);

        const tasks = [
          {
            property: `game21Games`,
            count: 1,
          },
        ];

        if (
          prize.length > 0 ||
          (isWin && playerScore === 21) ||
          (isWin && playerScore === 20) ||
          (isWin && playerScore <= 19)
        ) {
          tasks.push({
            property: `game21Souls`,
            count: 1 * (user.game21?.gameX || 1),
          });
        }

        warOrdenTask(tasks);
      })
      .catch((e) => {
        setIsFetching(false);
        setLoading(false);
        setModalError(e);
        console.log(e);
      })
      .finally(() => {
        isFirstDropRef.current = true;
        gameOverRef.current = false;
        setPlayerCards([]);
        setDealerCards([]);
        setGameStarted(false);
        setIsModalOpen(false);
        setPrize("");
      });
  }

  function getPlayerCard() {
    isFirstDropRef.current = false;
    setDeck((prev) => {
      setPlayerCards((prevState) => [...prevState, prev[prev.length - 1]]);
      return [...prev].slice(0, -1);
    });
  }

  return (
    <S.Wrapper>
      <S.GamesInfo>
        <span>Всего игр: {user.game21.countGames}</span>
        <span>
          Процент побед:{" "}
          {user.game21.countWins > 0
            ? parseFloat(
                (
                  (user.game21.countWins / user.game21.countGames) *
                  100
                ).toFixed(1)
              )
            : 0}
          %
        </span>
      </S.GamesInfo>

      <S.DialerWrapper>
        <S.DialerInfo>
          <S.Name>Крупье</S.Name>
          <S.Score>Очки: {dealerScore}</S.Score>
          {dealerCards.length > 0 &&
            dealerCards.map((card, i) => {
              return (
                <S.CardName key={i}>
                  {card.value} {card.suit}
                </S.CardName>
              );
            })}
        </S.DialerInfo>
        <S.DialerCards>
          <Cards cards={dealerCards} right="true" />
        </S.DialerCards>
      </S.DialerWrapper>
      <S.PlayerWrapper>
        <S.PlayerCards>
          <Cards cards={playerCards} bottom="true" />
        </S.PlayerCards>
        <S.PlayerInfo>
          <S.Name>Игрок</S.Name>
          <S.Score>Очки: {playerScore}</S.Score>
          {playerCards.length > 0 &&
            playerCards.map((card, i) => {
              return (
                <S.CardName key={i}>
                  {card.value} {card.suit}
                </S.CardName>
              );
            })}
        </S.PlayerInfo>
      </S.PlayerWrapper>

      {!gameStarted ? (
        <S.ButtonsWrapper>
          <S.BetString>
            Ставка:{" "}
            {user.game21.isFree > 0 ? (
              user.game21.isFree >= (gameX || 1) ? (
                <Reward
                  name={"game21"}
                  count={gameX || 1}
                  w={16}
                  lineH={23}
                  button={true}
                />
              ) : (
                <>
                  <Reward
                    name={"gold"}
                    count={((gameX || 1) - user.game21.isFree) * 2}
                    w={16}
                    lineH={23}
                    button={true}
                  />

                  <Reward
                    name={"game21"}
                    count={user.game21.isFree}
                    w={16}
                    lineH={23}
                    button={true}
                  />
                </>
              )
            ) : (
              <Reward
                name={"gold"}
                count={(gameX || 1) * 2}
                w={16}
                lineH={23}
                button={true}
              />
            )}
          </S.BetString>
          <S.MarkerButtonStyled
            width={100}
            onClick={!isFetching ? startGame : null}
          >
            <div>Начать</div>
          </S.MarkerButtonStyled>
        </S.ButtonsWrapper>
      ) : (
        !gameOver && (
          <S.ButtonsWrapper>
            <S.MarkerButtonStyled
              width={100}
              onClick={
                !isFetching && !loading && playerCards.length <= 5
                  ? getPlayerCard
                  : null
              }
            >
              <div>Взять ещё</div>
            </S.MarkerButtonStyled>
            <S.MarkerButtonStyled
              width={100}
              onClick={
                !isFetching && !loading ? startGame.bind(null, false) : null
              }
            >
              <div>Хватит</div>
            </S.MarkerButtonStyled>
          </S.ButtonsWrapper>
        )
      )}

      <Popup
        isOpen={betOpen}
        setIsOpen={setBetOpen}
        w={317}
        h={217}
        back={betFrame}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setBetOpen(false);
          }}
        />

        <Header>Выбор ставки</Header>

        <ButtonsXWrapper>
          <ButtonXChange
            disabled={gameX === 100}
            onClick={changeCount.bind(null, 100)}
          >
            <div>100</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 50}
            onClick={changeCount.bind(null, 50)}
          >
            <div>50</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 10}
            onClick={changeCount.bind(null, 10)}
          >
            <div>10</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 5}
            onClick={changeCount.bind(null, 5)}
          >
            <div>5</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 1}
            onClick={changeCount.bind(null, 1)}
          >
            <div>1</div>
          </ButtonXChange>
        </ButtonsXWrapper>

        <ButtonValueInput>
          <div>
            <input
              value={gameX}
              onChange={changeCountInput}
              type="number"
              max={100}
              min={1}
              placeholder={""}
              step={1}
            />
          </div>
        </ButtonValueInput>

        <BottomInfo>
          <Reward
            name={"game21"}
            count={`Бесплатных игр: ${user.game21.isFree}`}
            w={16}
            button={false}
          />
        </BottomInfo>
      </Popup>

      <Popup
        isOpen={isModalOpen}
        onClick={!isFetching && !loading ? endGame : null}
        w={317}
        h={217}
        back={frame3}
      >
        {!isWin && prize.length <= 0 && (
          <Close onClick={!isFetching && !loading ? endGame : null} />
        )}

        <b>Результат игры: {isWin ? "победа" : "поражение"}</b>

        {prize.length > 0 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1 * user.game21.gameX}
                soul={prizes[prize]}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : isWin && prize.length === 0 && playerScore === 21 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1 * user.game21.gameX}
                soul={3}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : isWin && prize.length === 0 && playerScore === 20 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1 * user.game21.gameX}
                soul={2}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : (
          isWin &&
          prize.length === 0 &&
          playerScore <= 19 && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"soul"}
                  count={1 * user.game21.gameX}
                  soul={1}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )
        )}

        {(isWin || prize.length) > 0 ? (
          <Button
            width={100}
            onClick={!isFetching && !loading ? endGame : null}
          >
            <div>Забрать</div>
          </Button>
        ) : (
          <Button
            width={100}
            onClick={!isFetching && !loading ? endGame : null}
          >
            <div>Закрыть</div>
          </Button>
        )}
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(Game21);
