import styled, { css, keyframes } from "styled-components";
import { AppRoot } from "@vkontakte/vkui";
import { Icon28CancelCircleOutline } from "@vkontakte/icons";
import backMenu from "../src/img/menuIcons/Back.png";
import backHome from "../src/img/menuIcons/BackHome.png";
import backOrden from "../src/img/menuIcons/BackOrden.png";

export const InterfaceTop = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  clip-path: polygon(
    100.11% 101.33%,
    63.78% 101.33%,
    63.53% 79.07%,
    56.71% 84.68%,
    50.02% 101.33%,
    43.45% 84.53%,
    36.73% 82.54%,
    36.44% 101.33%,
    0% 100%,
    0% 0%,
    100% 0%
  );
`;

export const InterfaceBottom = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const InterfaceLeft = styled.img`
  position: absolute;
  left: 0;
  top: 74px;
  clip-path: polygon(
    80.83% 14.34%,
    100% 5.05%,
    100% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    78.18% 96.46%,
    81.03% 84.5%,
    66.8% 82.43%,
    66.8% 16.7%
  );
`;

export const InterfaceRight = styled(InterfaceLeft)`
  backface-visibility: hidden;
  transform: scale(-1, 1);
  left: unset;
  right: 0;
`;

export const StartVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 900px;
  height: 700px;
`;

export const MoveVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 900px;
  height: 700px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 100;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
`;

// export const MoveVideo = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 100;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   opacity: ${(props) => (props.isOpen ? "1" : "0")};
//   visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
//   pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
//   & > img {
//     image-rendering: crisp-edges;
//   }
// `;

export const Close = styled(Icon28CancelCircleOutline)`
  width: 25px !important;
  height: 25px !important;
  z-index: 2;
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 7px;
  color: #f7b471;
  background-color: #3f2001;
  border-radius: 50%;
  transition: filter 0.15s;
  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const Home = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  top: 132px;
  left: 69px;
  width: 32px;
  height: 32px;
  transform: translateX(-50%);
  &:after {
    top: 0px;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "Гостиная";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
  img {
    width: 100%;
    height: 100%;
  }
  & img:hover {
    transition: 0.1s ease;
    cursor: pointer;
    filter: brightness(1.2);
  }
  & img:active {
    filter: brightness(0.9);
  }
`;

export const LoadingWrapper = styled.div`
  transform-origin: top left;
  font-family: "Bellota-Regular";
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  width: 900px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: scale(${(props) => (props.scale ? props.scale : 1)})
    translate(-50%, -50%);
`;

export const InfoError = styled.div`
  font-family: "Spectrashell";
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 190px;
  transform: translate(-50%, -50%);
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    text-align: center;
    color: #ffe5b9;
    font-size: 26px;
    text-shadow: 0 0 black;
  }
`;

const show = (isAnim) => keyframes`
  from {
opacity: ${isAnim ? "0" : "1"};
  }

  to {
opacity: 1;
  }
`;

export const Location = styled.img`
  animation: ${(props) => show(props.isAnim)} 0.5s ease;
  filter: ${(props) => props.isBlur && "blur(2px)"};
  transition: filter 0.15s linear;
  position: absolute;
  width: 840px;
  height: 570px;
  left: 30px;
  top: 50px;
  object-fit: cover;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const AppRootWrapper = styled(AppRoot)`
  background-color: #261706;
  position: relative;
  width: 900px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: scale(${(props) => (props.scale ? props.scale : 1)})
    translate(-50%, -50%);
  transform-origin: top left;

  font-family: "Bellota-Regular";

  overflow: hidden;

  & button {
    font-family: "Bellota-Regular";
    background-color: #6b3700;
    color: #f7b471;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 3px 6px;
    transition: background-color 0.15s linear;
  }

  & input {
    max-width: 100%;
  }

  & button:not([disabled]):hover {
    background-color: #7d4100;
  }

  & button a {
    color: #f7b471;
    text-decoration: none;
  }

  & button:not([disabled]):active {
    background-color: #552a00;
  }

  & button:not([disabled]) {
    cursor: pointer;
  }

  & button[disabled] {
    background-color: #402000;
  }

  & * {
    box-sizing: border-box;
    user-drag: none;
  }

  & ::-webkit-scrollbar {
    height: 15px;
    width: 5px;
    background: #362a1b;
  }

  & ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgba(244, 188, 127, 0) 0%,
      rgba(244, 188, 127, 1) 30%,
      rgba(244, 188, 127, 1) 50%,
      rgba(244, 188, 127, 1) 70%,
      rgba(244, 188, 127, 0) 100%
    );
    border-radius: 4px;
  }

  & ::-webkit-scrollbar-corner {
    background: #362a1b;
  }

  & ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const CollectionPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
  & > div {
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${(props) => (props.w ? props.w : 250)}px;
    height: ${(props) => (props.h ? `${props.h}px` : "auto")};
    background-image: url(${(props) => (props.back ? props.back : "")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }
`;

export const CentredWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 50px;
  bottom: 80px;
  left: 30px;
  right: 30px;
  width: 840px;
  height: 570px;
  padding: 55px 70px 65px 70px;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  position: absolute;
  align-items: center;
  top: 168px;
  left: 0;
  gap: 0;
`;

export const MenuRight = styled(Menu)`
  left: unset;
  right: 0;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  gap: 0;
`;

export const ButtonUp = styled.div`
  position: absolute;
  top: -22px;
  display: flex;
  cursor: pointer;
  transition: scale 0.6s linear;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover {
        transform: unset;
      }
    `};
`;

export const ButtonDown = styled(ButtonUp)`
  top: unset;
  bottom: -30px;
  transform: rotateX(180deg);
  &:hover {
    transform: scale(1.05) rotateX(180deg);
  }
  &:active {
    transform: scale(1) rotateX(180deg);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover {
        transform: rotateX(180deg);
      }
    `};
`;

export const MenuElem = styled.div`
  width: 80px;
  height: 80px;
  background-image: url("${backMenu}");
  background-size: cover;
  position: relative;
  display: flex;

  & > img {
    will-change: transform;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.1s linear;
  }

  & > div:hover + img {
    transform: translate(-50%, -50%) scale(1.06);
  }

  & > div:active + img {
    transform: translate(-50%, -50%);
  }

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 1px), calc(-50% + 4px));
    width: 52px;
    height: 52px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1;
    &:after {
      top: 0;
      left: 0;
      transform: translate(0, -80%) scale(0.85);
      position: absolute;
      visibility: hidden;
      opacity: 0;
      color: #f7b471;
      font-size: 13px;
      transition: 0.1s linear;
      padding: 3px 6px;
      background: rgba(17, 12, 9, 0.8);
      border-radius: 5px;

      outline: 1px solid #281707;
      box-shadow: inset 0px 0px 0px 1px #281707;
      border: 2px solid #fc9800;
      z-index: 1;
      width: max-content;
      content: "${(props) => (props.name ? `${props.name}` : "")}";
    }
    &:hover:after {
      visibility: visible;
      opacity: 1;
      transform: translate(0, -100%) scale(1);
    }
  }
`;

export const MenuElemMini1 = styled(MenuElem)`
  z-index: 1;
  background-image: url("${backHome}");
  position: absolute;
  left: 28px;
  top: -32px;
  width: 50px;
  height: 50px;

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 1px), calc(-50% + 4px));
    width: 32px;
    height: 32px;
  }
`;

export const MenuElemMini2 = styled(MenuElem)`
  z-index: 1;
  background-image: url("${backOrden}");
  position: absolute;
  left: 64px;
  top: 0;
  width: 50px;
  height: 50px;
  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 1px), calc(-50% + 4px));
    width: 32px;
    height: 32px;
  }
`;

export const MenuElemRight = styled(MenuElem)`
  & > div {
    transform: translate(calc(50% + 1px), calc(-50% + 4px));
  }
`;
