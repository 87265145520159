import React, { useEffect, useState } from "react";
import * as S from "./Guide.styles";
import { Button } from "../../panels/Arena/Arena.styles";

import img1 from "../../img/guide/1.png";
import img2gryffindor from "../../img/guide/2gryffindor.png";
import img2ravenclaw from "../../img/guide/2ravenclaw.png";
import img2hufflepuff from "../../img/guide/2hufflepuff.png";
import img2slytherin from "../../img/guide/2slytherin.png";
import img3 from "../../img/guide/3.png";
import img4 from "../../img/guide/4.png";
import img5 from "../../img/guide/5.png";
import img6 from "../../img/guide/6.png";
import img7 from "../../img/guide/7.png";
import img8 from "../../img/guide/8.png";
import img9 from "../../img/guide/9.png";
import img10 from "../../img/guide/10.png";
import img11 from "../../img/guide/11.png";
import img12 from "../../img/guide/12.png";
import img13 from "../../img/guide/13.png";
import img14 from "../../img/guide/14.png";
import img15 from "../../img/guide/15.png";
import img16 from "../../img/guide/16.png";
import img17 from "../../img/guide/17.png";

import sound1 from "../../img/guide/1.mp3";

import sound2gryffindor from "../../img/guide/2gryffindor.mp3";
import sound2ravenclaw from "../../img/guide/2ravenclaw.mp3";
import sound2hufflepuff from "../../img/guide/2hufflepuff.mp3";
import sound2slytherin from "../../img/guide/2slytherin.mp3";

import sound3 from "../../img/guide/3.mp3";
import sound4 from "../../img/guide/4.mp3";
import sound5 from "../../img/guide/5.mp3";
import sound6 from "../../img/guide/6.mp3";
import sound7 from "../../img/guide/7.mp3";
import sound8 from "../../img/guide/8.mp3";
import sound9 from "../../img/guide/9.mp3";
import sound10 from "../../img/guide/10.mp3";
import sound11 from "../../img/guide/11.mp3";
import sound12 from "../../img/guide/12.mp3";
import sound13 from "../../img/guide/13.mp3";
import sound14 from "../../img/guide/14.mp3";
import sound15 from "../../img/guide/15.mp3";
import sound16 from "../../img/guide/16.mp3";
import sound17 from "../../img/guide/17.mp3";

import { useAudioPlayer } from "react-use-audio-player";

const images = [
  img1,
  {
    gryffindor: img2gryffindor,
    ravenclaw: img2ravenclaw,
    hufflepuff: img2hufflepuff,
    slytherin: img2slytherin,
  },
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
];

const songs = [
  sound1,
  {
    gryffindor: sound2gryffindor,
    ravenclaw: sound2ravenclaw,
    hufflepuff: sound2hufflepuff,
    slytherin: sound2slytherin,
  },
  sound3,
  sound4,
  sound5,
  sound6,
  sound7,
  sound8,
  sound9,
  sound10,
  sound11,
  sound12,
  sound13,
  sound14,
  sound15,
  sound16,
  sound17,
];

function Guide({
  userId,
  faculty,
  isOpen,
  app,
  setUser,
  setIsFetching,
  setModalError,
  serverTime,
  isFetching,
  isVisible,
  isFirstClick,
}) {
  const { load, pause, play, isReady, setVolume, mute, playing, getPosition } =
    useAudioPlayer();
  const [stepNumber, setStepNumber] = useState(0);
  const [endDobbi, setEndDobbi] = useState(false);

  useEffect(() => {
    if (isFirstClick && isOpen) {
      const sound =
        typeof songs[stepNumber] === "object"
          ? songs[stepNumber][faculty]
          : songs[stepNumber];

      load(sound, {
        autoplay: true,
        initialVolume: 0.65,
        html5: true,
        format: "mp3",
        onload: () => {
          setEndDobbi(true);
        },
        onend: () => {
          setEndDobbi(false);
        },
      });
    }
  }, [stepNumber, load, isFirstClick, isOpen]);

  useEffect(() => {
    if (isReady && isVisible && isOpen) {
      if (!playing && (getPosition() > 0 || endDobbi)) {
        play();
      }
    } else {
      pause();
    }
  }, [isReady, pause, play, playing, isVisible, getPosition, endDobbi, isOpen]);

  function handleNextClick() {
    setEndDobbi(true);
    if (stepNumber < images.length - 1) {
      setStepNumber((prevState) => prevState + 1);
    }
  }

  function endGuide() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        userId,
        {
          isGuide: true,
          registerTime: serverTime,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "isGuide", "registerTime"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        setIsFetching(false);
        console.log(e);
      });
  }

  // <S.Screen
  //     active={stepNumber > 0 && stepNumber < stages - 1}
  //     onClick={
  //       stepNumber > 0 && stepNumber < stages ? handleNextClick : null
  //     }
  //     src={images[stepNumber]}
  // />

  return (
    <S.Wrapper isOpen={isOpen}>
      {isOpen && (
        <>
          <S.Screen
            src={
              typeof images[stepNumber] === "object"
                ? images[stepNumber][faculty]
                : images[stepNumber]
            }
          />

          {stepNumber < images.length - 1 ? (
            <S.ButtonsWrapper>
              {/*<Button width={140} onClick={!isFetching ? endGuide : null}>*/}
              {/*  <div>Пропустить обучение</div>*/}
              {/*</Button>*/}

              <Button width={140} onClick={handleNextClick}>
                <div>{stepNumber === 0 ? "Начать обучение" : "Продолжить"}</div>
              </Button>
            </S.ButtonsWrapper>
          ) : (
            <S.ButtonsWrapper>
              <Button width={140} onClick={!isFetching ? endGuide : null}>
                <div>Закончить обучение</div>
              </Button>
            </S.ButtonsWrapper>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

export default React.memo(Guide);
