import styled from "styled-components";
import buttonPart from "../../img/boss/ButtonPart.png";
import imgSpell from "../../img/boss/Spell.png";
import imgMarker3 from "../../img/Marker3Long.png";
import imgAvatar from "../../img/arena/Avatar.png";

export const DamageLog = styled.div`
  position: absolute;
  right: 30px;
  bottom: 85px;
  width: 200px;
  height: fit-content;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  overflow: hidden;
  transition-delay: 0.5s;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  opacity: ${(props) => (props.bossLog ? 0 : 1)};
  visibility: ${(props) => (props.bossLog ? "hidden" : "visible")};
`;

export const BarsPart = styled.div`
  position: absolute;
  display: flex;
  left: 50%;
  bottom: 29px;
  transform: translate(-50%);
`;

export const Time = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 38px;
  transform: translateX(62px);
  font-family: "Bellota-Regular";

  gap: 2px;
  align-items: center;
  color: #f7b471;
  font-size: 12px;
  line-height: 18px;
  text-shadow: 1px 1px #210326;
`;

export const BarsHp = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 38px;
  transform: translateX(-134px);
  font-family: "Bellota-Regular";
  & img {
    clip-path: polygon(
      0% 0%,
      ${(props) => props.value}%0%,
      ${(props) => props.value}% 50%,
      ${(props) => props.value}% 100%,
      0% 100%
    );
    transition: all 0.5s ease-in-out;
  }

  & span {
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    font-size: 12px;
    color: #e3cd32;
    line-height: 18px;
    text-shadow: 1px 1px #210326;
  }
`;

export const BarsHpBack = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 36px;
  transform: translateX(-50%);
`;

export const BarsTime = styled.div`
  width: 128px;
  height: 25px;
  position: absolute;
  display: flex;
  left: 484px;
  bottom: 17px;
  & > img {
    position: absolute;
    width: 100%;
    clip-path: polygon(
      0% 0%,
      ${(props) => props.value}%0%,
      ${(props) => props.value}% 50%,
      ${(props) => props.value}% 100%,
      0% 100%
    );
    transition: all 0.5s ease-in-out;
  }
  & span {
    font-weight: 600;
    color: #e3cd32;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    line-height: 24px;
    font-size: 14px;
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const EndButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1.4fr 1fr 1fr 0.7fr 0.7fr;
  width: 100%;
  text-align: center;
  align-items: start;
`;

export const LogOpen = styled.div`
  position: absolute;
  right: 130px;
  bottom: 37px;
  transform: translateX(50%);
  display: flex;
  gap: 15px;
`;

export const LogUsers = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 135px;
  top: 235px;
  transform: translateX(50%);
  transition-duration: 0.1s;
  transition-delay: 0.5s;
  transition-timing-function: linear;
  transition-property: opacity;
  opacity: ${(props) => (props.bossLog ? "1" : 0)};
  visibility: ${(props) => (props.bossLog ? "visible" : "hidden")};
  backface-visibility: hidden;
  will-change: transform;
`;

export const LogUsersDamage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  right: 18px;
  bottom: 72px;
  width: 247px;
  height: 230px;
  padding-right: 5px;
  overflow: hidden;
  overflow-y: scroll;
  transition-duration: 0.1s;
  transition-delay: 0.5s;
  transition-timing-function: linear;
  transition-property: opacity;
  opacity: ${(props) => (props.bossLog ? "1" : 0)};
  visibility: ${(props) => (props.bossLog ? "visible" : "hidden")};
  backface-visibility: hidden;
  will-change: transform;
  & > div {
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-items: end;
    align-items: center;
    gap: 5px;
  }
`;

export const InfoBoss = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 66px;
  transform: translateX(-50%);
`;

export const Heal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  position: absolute;
  left: 160px;
  bottom: 62px;
`;

export const FirstSpell = styled.div`
  position: absolute;
  left: 45px;
  bottom: 62px;
`;

export const SpellWrapper = styled.div`
  width: 55px;
  height: 55px;
  background-image: url("${imgSpell}");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 45px;
    height: 45px;
    position: relative;
  }
`;

export const EndFightWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 25px 10px 0 10px;

  & > img {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const EndLeftPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
`;

export const Hand = styled.img`
  position: absolute;
  left: 0;
  top: 100px;
`;

export const LoseText = styled.div`
  height: 34px;
  font-family: "Inkverse-BWDRx";
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  color: #ff2d2d;
`;

export const EndRightPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const BossName = styled.div`
  width: 100%;
  padding: 4px;
  background-image: url(${imgMarker3});
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fcc382;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 600;
`;

export const EndBossInfo = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fcc382;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
`;

export const MeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > div {
    color: #fcc382;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }
`;

export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > div {
    color: #fcc382;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3px;
  margin-bottom: 5px;

  & > img {
    border-radius: 10px;
  }

  & > div {
    display: flex;
    position: absolute;
    bottom: 0;
    transform: translateY(40%);
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${imgAvatar}");
    background-size: contain;
  }
`;

export const ButtonValue = styled.div`
  bottom: 0;
  position: relative;
  min-width: ${(props) => props.width && `${props.width}px`};
  max-width: 90px;
  background-color: #140c02;

  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    padding: 2px 8px 2px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
`;
