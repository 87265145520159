import styled from "styled-components";

import imgAvatar from "../../img/FriendsFrame.png";
import buttonPart from "../../img/boss/ButtonPart.png";

export const ArenaWrapper = styled.div`
  display: flex;
  gap: 12px;
  color: white;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 15px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3px;

  & > img {
    border-radius: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${imgAvatar}");
    background-size: contain;
  }
`;

export const Name = styled.div`
  width: 180px;
  position: absolute;
  bottom: 150px;
  transform: translateY(50%);

  display: flex;
  gap: 2px;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f7b471;
  text-shadow: 0 0 #281707;
  font-weight: 600;
  line-height: 14px;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Lvl = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #281707;
  font-weight: 600;
  text-shadow: 0 0 #281707;
  margin-bottom: 5px;
`;

export const ButtonValue = styled.div`
  bottom: 0;
  position: relative;
  min-width: ${(props) => props.width && `${props.width}px`};
  background-color: #140c02;
  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    padding: 2px 8px 2px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
`;

export const Button = styled.div`
  min-width: ${(props) => props.width && `${props.width}px`};
  cursor: ${(props) => (!props.disabled ? "pointer" : "default")};
  position: relative;

  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
    z-index: 1;
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
    z-index: 1;
  }

  border-radius: 36px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background-color: ${(props) =>
    props.isActive
      ? "#8d2800"
      : props.isDone
      ? "#364500"
      : props.disabled
      ? "#140C02"
      : "#6B3700"};

  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? "#8d2800"
        : props.isDone
        ? "#364500"
        : props.disabled
        ? "#140C02"
        : "#7d4100"};
  }
  &:active {
    background-color: ${(props) =>
      props.isActive
        ? "#8d2800"
        : props.isDone
        ? "#364500"
        : props.disabled
        ? "#140C02"
        : "#552a00"};
  }

  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 36px;
    padding: 2px 10px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const MiniButton = styled(Button)`
  & > div {
    font-size: 11px;
    line-height: 1;
  }
`;

export const BottomPrices = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const BottomPrice = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  s {
    text-decoration-color: #f7b471b0;
  }
`;
