import styled from "styled-components";
import buttonPart from "../../img/boss/ButtonPart.png";

export const Damage = styled.div`
  position: absolute;
  left: 430px;
  top: 192px;
  width: 75px;
  height: 114px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const BoxHover = styled.div`
  position: absolute;
  left: 329px;
  top: 325px;
  width: 141px;
  height: 175px;
  background-image: url("${(props) => props.back}");
  background-size: cover;

  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }
`;

export const Essence = styled.div`
  display: flex;
  position: absolute;
  left: 124px;
  top: 341px;
  width: 200px;
  height: 171px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(0 0, 52% 0, 100% 50%, 100% 100%, 0 100%);

  &:hover {
    animation-play-state: paused;
    transition: 0.3s linear;
    opacity: 1 !important;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Mana = styled.div`
  display: flex;
  position: absolute;
  left: 454px;
  top: 280px;
  width: 180px;
  height: 225px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(45% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 40%);
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Dobbi = styled.div`
  display: flex;
  position: absolute;
  left: 574px;
  top: 316px;
  width: 115px;
  height: 185px;

  background-image: url("${(props) => props.back}");
  background-size: cover;

  clip-path: polygon(
    50% 0,
    83% 6%,
    100% 60%,
    85% 100%,
    25% 100%,
    0 45%,
    10% 8%
  );
`;

export const DobbiHover = styled.div`
  display: flex;
  position: absolute;
  left: 574px;
  top: 316px;
  width: 115px;
  height: 185px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;

  clip-path: polygon(
    50% 0,
    83% 6%,
    100% 60%,
    85% 100%,
    25% 100%,
    0 45%,
    10% 8%
  );

  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Gold = styled.div`
  display: flex;
  position: absolute;
  left: 201px;
  top: 232px;
  width: 288px;
  height: 234px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(0 0, 52% 0, 100% 50%, 100% 100%, 0 100%);
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const DamageBoard = styled.div`
  position: absolute;
  left: 430px;
  top: 192px;
  width: 75px;
  height: 114px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Box = styled.div`
  position: absolute;
  left: 329px;
  top: 325px;
  width: 141px;
  height: 175px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const SaleWrapper = styled.div`
  position: absolute;
  left: 311px;
  top: 344px;
  width: 145px;
  height: 162px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 800px;
  height: 548px;
  gap: 10px;
  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Sale = styled.div`
  text-align: center;
  & > span {
    font-size: 16px;
  }
  display: flex;
  flex-direction: column;
`;

export const StockLvl = styled.div`
  & > span {
    font-size: 16px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
      display: flex;
      justify-content: center;
      gap: 5px;
      line-height: 1.5;
    }
  }
`;

export const Stock = styled.div`
  margin-top: 17px;
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "5px")};
`;

export const PropertyWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    & button {
      font-size: 12px;
    }
  }
`;

export const Price = styled.div`
  font-size: 12px;
`;

export const Benefits = styled.div`
  font-size: 12px;
  color: #b0ff42;
`;

export const BoxButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const BottomSum = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 8px;
  bottom: 25px;
`;
