import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${(props) => props.width && `${props.width}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  justify-items: center;
  align-items: center;
  background: rgba(17, 12, 9, 0.7);

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #8b541a;
  border-radius: 3px;
  font-size: 12px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 6px;
`;

export const TopString = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 34px;
  padding: 0 3px;
  font-size: 12px;
  line-height: 15px;
`;

export const ButtonBottom = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3px 3px;
  background-color: ${(props) => (props.disabled ? "#241601" : "#6b3700")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  transition: background-color 0.15s linear;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#241601" : "#7d4100")};
  }
  &:active {
    background-color: ${(props) => (props.disabled ? "#241601" : "#552a00")};
  }
`;

export const InputWrapper = styled.div`
  max-width: ${(props) => (props.width ? `${props.width}px` : "100px")};
  min-width: ${(props) => (props.width ? `${props.width}px` : "100px")};

  display: flex;
  position: relative;

  & > img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > div {
    width: 100%;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
    margin: 3px;
  }

  & input {
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #fcc382;
    font-family: "Bellota-Regular";
    padding: 0 !important ;
    border: none !important;
    background-color: transparent !important;
    outline: none !important;

    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }
`;

export const Minus = styled.div`
  font-size: 18px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  border-radius: 20px 0 0 20px;
  transition: background-color 0.15s linear;
  &:hover {
    background-color: #6b3700;
  }
  &:active {
    background-color: #2f1400;
  }
`;

export const Plus = styled.div`
  font-size: 18px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
  transition: background-color 0.15s linear;
  &:hover {
    background-color: #6b3700;
  }
  &:active {
    background-color: #2f1400;
  }
`;
