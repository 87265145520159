import React, { useCallback, useEffect, useState } from "react";

import * as S from "./Shop.styles";
import ShopElem from "../ShopElem/ShopElem";
import Popup from "../Popup/Popup";
import InventoryElem from "../InventoryElem/InventoryElem";
import backImg from "../../img/ShopBack.png";
import { Close } from "../../App.styles";
import frame3 from "../../img/Frame3.png";
import {
  allClothes,
  bankBonusArray,
  clothes1,
  grindData,
  inventoryItems,
  shopEventItems,
  shopItems,
} from "../../info/data";
import { Button } from "../../panels/Arena/Arena.styles";
import Reward from "../Reward/Reward";
import frame2 from "../../img/Frame2.png";
import InputNumber from "../InputNumber/InputNumber";
import {
  ButtonBottom,
  InputWrapper,
  Minus,
  Plus,
  TopString,
  TopWrapper,
} from "../ShopElem/ShopElem.styles";
import { roundConsumablesFixed } from "../../helpers";
import { ShopElemWrapper } from "./Shop.styles";
import inputImg from "../../img/InputWrapper.png";
import frame1 from "../../img/Frame1.png";

function Shop({
  _id,
  treasures,
  gold,
  silver,
  maxCharacCount,
  damageTalents,
  isOpen,
  setIsOpen,
  isFetching,
  isModalOpen,
  setIsFetching,
  setUser,
  app,
  setModalError,
  useItem,
  serverTime,
  inventory,
  bosses,
  bosses2,
  isEvent,
  clothes,
  sex,
  eventDiamondsCount,
  grind,
  characteristic,
  bank,
}) {
  const [inventoryArray, setInventoryArray] = useState([]);
  const [treasureChange, setTreasureChange] = useState(0);
  const [clothesEvent, setClothesEvent] = useState([]);
  const [countTreasure, setCountTreasure] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([...shopItems]);
  const [boughtElem, setBoughtElem] = useState(null);
  const [buyInfo, setBuyInfo] = useState(null);
  const [tab, setTab] = useState("potion");

  useEffect(() => {
    if (tab === "all") {
      setItems(shopItems);
    } else if (tab === "event") {
      setItems(shopEventItems);
    } else if (tab === "potion") {
      setItems(
        shopItems.filter(
          (elem) =>
            elem.category === "hp" ||
            elem.category === "energy" ||
            elem.category === "mana"
        )
      );
    } else if (tab === "soul") {
      setItems(
        shopItems.filter(
          (elem) => elem.category === "soul" || elem.category === "soul2"
        )
      );
    } else {
      setItems([]);
    }
  }, [tab]);

  useEffect(() => {
    if (isEvent) {
      const clothesArray = Object.entries(clothes1)
        .filter((elem) => elem[1].event === isEvent.type)
        .map((elem) => {
          elem[1].isBuy = clothes.isBuy.findIndex((el) => el === elem[0]) > -1;

          return elem;
        })
        .sort((a, b) => a[1].diamond - b[1].diamond);

      setClothesEvent(clothesArray);
    }
  }, [isEvent, clothes]);

  useEffect(() => {
    const array = [];

    inventoryItems.map((elem) => {
      const item = inventory[`${elem.category}${elem.name}`];
      if (item > 0) {
        array.push({ ...elem, count: item });
      }
    });

    setInventoryArray([...array]);
  }, [inventory]);

  function treasureOpen(number) {
    setTreasureChange(number);
  }

  function handleTab(name) {
    setTab(name);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function setBuy(info) {
    setBuyInfo(info);
  }

  function changeCount(number) {
    const max = Math.floor(treasures.countHave[treasureChange - 1] / 10);

    if (countTreasure + number <= max && countTreasure + number > 0) {
      setCountTreasure(countTreasure + number);
    } else {
      if (countTreasure + number > max && max >= 1) {
        setCountTreasure(max);
      } else {
        setCountTreasure(1);
      }
    }
  }

  function handleChange() {
    if (
      treasures.countHave[treasureChange - 1] < countTreasure * 10 ||
      !countTreasure
    ) {
      setModalError("Недостаточно сундуков для обмена");
      console.log("Недостаточно сундуков для обмена");
    } else {
      if (gold < 5 * countTreasure) {
        setModalError("Недостаточно золота для обмена");
        console.log("Недостаточно золота для обмена");
      } else {
        setIsFetching(true);
        setIsLoading(true);
        app
          .service("users")
          .patch(
            _id,
            {
              $inc: {
                [`treasures.countHave.${treasureChange - 1}`]:
                  -countTreasure * 10,
                [`treasures.countHave.${treasureChange}`]: countTreasure,
                gold: -5 * countTreasure,
              },
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "treasures", "gold"],
              },
            }
          )
          .then((data) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 250);
            setIsFetching(false);
            setUser((prev) => ({ ...prev, ...data }));
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsLoading(false);
            setIsFetching(false);
          });
      }
    }
  }

  const buyItem = useCallback(
    (item, count, packCount = 1) => {
      const hpPriceScale = 1 + Math.floor(maxCharacCount / 1000) * 0.25;
      const hpPrice = Math.floor(
        item.price.count *
          (damageTalents.simpleTalents.hpSilverDiscount > 0
            ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
            : 1) *
          hpPriceScale
      );
      count = Math.floor(count);
      if (isNaN(count)) {
        return 0;
      }
      if (count < 1) {
        return 0;
      }

      let transaction = true;
      if (item.price.property === "gold" && item.price.count * count > gold) {
        transaction = false;
        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      }

      if (
        item.price.property === "diamond" &&
        item.price.count * count > eventDiamondsCount
      ) {
        transaction = false;
        setModalError("Недостаточно изумрудов");
        console.log("Недостаточно изумрудов");
      }

      if (
        item.category === "hp" &&
        item.price.property === "silver" &&
        hpPrice * count > silver
      ) {
        transaction = false;
        setModalError("Недостаточно серебра");
        console.log("Недостаточно серебра");
      }
      if (
        item.category !== "hp" &&
        item.price.property === "silver" &&
        item.price.count * count > silver
      ) {
        transaction = false;
        setModalError("Недостаточно серебра");
        console.log("Недостаточно серебра");
      }

      if (item.category === "soul") {
        transaction = false;

        let soulTransaction = true;

        if (item.price.property === "gold" && gold < item.price.count * count) {
          console.log("Недостаточно золота");

          setModalError({
            string: "Недостаточно золота",
            buttons: ["bank"],
          });
          soulTransaction = false;
        }

        if (
          item.price.property === "diamond" &&
          eventDiamondsCount < item.price.count * count
        ) {
          setModalError("Недостаточно изумрудов");
          console.log("Недостаточно изумрудов");
          soulTransaction = false;
        }

        if (soulTransaction) {
          setLoading(true);
          setIsFetching(true);
          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  gold:
                    item.price.property === "gold"
                      ? -item.price.count * count
                      : 0,
                  "event.diamonds":
                    item.price.property === "diamond"
                      ? -item.price.count * count
                      : 0,
                  [`bosses.${item.value}.souls`]: count * packCount,
                },
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "gold", "bosses", "event"],
                },
              }
            )
            .then((data) => {
              setLoading(false);
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setLoading(false);
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        }
      }

      if (item.category === "soul2") {
        transaction = false;

        let soulTransaction = true;

        if (item.price.property === "gold" && gold < item.price.count * count) {
          console.log("Недостаточно золота");

          setModalError({
            string: "Недостаточно золота",
            buttons: ["bank"],
          });
          soulTransaction = false;
        }

        if (
          item.price.property === "diamond" &&
          eventDiamondsCount < item.price.count * count
        ) {
          setModalError("Недостаточно изумрудов");
          console.log("Недостаточно изумрудов");
          soulTransaction = false;
        }

        if (soulTransaction) {
          setLoading(true);
          setIsFetching(true);
          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  gold:
                    item.price.property === "gold"
                      ? -item.price.count * count
                      : 0,
                  "event.diamonds":
                    item.price.property === "diamond"
                      ? -item.price.count * count
                      : 0,
                  [`bosses2.${item.value}.souls`]: count * packCount,
                },
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "gold", "bosses2", "event"],
                },
              }
            )
            .then((data) => {
              setLoading(false);
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setLoading(false);
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        }
      }

      if (transaction) {
        setLoading(true);
        setIsFetching(true);

        app
          .service("users")
          .patch(
            _id,
            {
              $inc: {
                [`inventoryNew.${item.category}${item.name}`]:
                  count * packCount,
                gold:
                  item.price.property === "gold"
                    ? -item.price.count * count
                    : 0,
                "event.diamonds":
                  item.price.property === "diamond"
                    ? -item.price.count * count
                    : 0,
                silver:
                  item.price.property === "silver" && item.category === "hp"
                    ? -hpPrice * count
                    : item.price.property === "silver"
                    ? -item.price.count * count
                    : 0,
              },
              field: serverTime,
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "inventoryNew",
                  "gold",
                  "silver",
                  "event",
                ],
              },
            }
          )
          .then((data) => {
            setLoading(false);
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
            setIsPopupOpen(true);

            setBoughtElem(item);
          })
          .catch((e) => {
            setLoading(false);
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    },
    [serverTime, gold, silver, eventDiamondsCount]
  );

  function buyClothes({ diamond, name }) {
    let isTransaction = true;

    if (eventDiamondsCount < diamond) {
      isTransaction = false;
    }

    if (isTransaction) {
      setIsFetching(true);

      const buyClothes = [...clothes.isBuy, name];

      const newCharacteristic = { ...characteristic };

      const clothesScale = bankBonusArray?.[bank?.lvl]?.clothesScale || 1;

      for (const key in newCharacteristic) {
        newCharacteristic[key].armor = 0;
      }

      const clothesArray = Object.entries(allClothes).filter((elem) =>
        buyClothes.includes(elem[0])
      );

      clothesArray.forEach((elem) => {
        Object.entries(elem[1].characteristic).forEach((el) => {
          newCharacteristic[el[0]].armor += el[1] * clothesScale;
        });
      });

      const scales = {};
      const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
      damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      const hp = Math.floor(
        Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      );

      app
        .service("users")
        .patch(
          _id,
          {
            "health.max": hp,
            "clothes.isBuy": buyClothes,
            characteristic: newCharacteristic,
            $inc: {
              "event.diamonds": -diamond,
            },
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "health",
                "clothes",
                "characteristic",
                "event",
              ],
            },
          }
        )
        .then((data) => {
          setBuyInfo(null);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setBuyInfo(null);
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    } else {
      setBuyInfo(null);

      console.log("Недостаточно средств");

      setModalError({
        string: "Недостаточно средств",
        buttons: ["bank"],
      });
    }
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      {isOpen && (
        <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
          <div>
            <Close onClick={handleClose} />

            <S.ButtonsWrapper>
              <div>
                <Button
                  disabled={tab === "potion"}
                  onClick={handleTab.bind(null, "potion")}
                  width={100}
                >
                  <div>Лавка Зелий</div>
                </Button>

                <Button
                  disabled={tab === "soul"}
                  onClick={handleTab.bind(null, "soul")}
                  width={100}
                >
                  <div>Магазин Душ</div>
                </Button>

                <Button
                  disabled={tab === "treasure"}
                  onClick={handleTab.bind(null, "treasure")}
                  width={100}
                >
                  <div>Обменник</div>
                </Button>

                {isEvent && (
                  <Button
                    disabled={tab === "event"}
                    onClick={handleTab.bind(null, "event")}
                    width={100}
                  >
                    <Reward
                      name={"diamond"}
                      count={"Ивент"}
                      w={12}
                      button={true}
                      g={4}
                    />
                  </Button>
                )}
              </div>
              <div style={{ paddingRight: "5px" }}>
                <Reward
                  name={"gold"}
                  count={gold}
                  w={14}
                  button={true}
                  round={true}
                />
                <Reward
                  name={"silver"}
                  count={silver}
                  w={14}
                  button={true}
                  round={true}
                />
                {isEvent && (
                  <Reward
                    name={"diamond"}
                    count={eventDiamondsCount}
                    w={14}
                    round={true}
                    button={true}
                  />
                )}
              </div>
            </S.ButtonsWrapper>

            <S.ShopElements>
              {tab === "event" &&
                clothesEvent.map((elem, i) => {
                  return (
                    <S.ShopElemWrapper key={i}>
                      <TopWrapper>
                        <TopString>Предмет гардероба</TopString>
                        {elem[1].category === "broom" ||
                        elem[1].category === "wand" ? (
                          <img
                            width={68}
                            height={68}
                            src={require(`../../img/clothes/${
                              elem[1].category
                            }${elem[1].lvl >= 1 ? `${elem[1].lvl + 1}` : ""}/${
                              elem[1].src
                            }`)}
                            alt={elem[1].category}
                          />
                        ) : (
                          <img
                            width={68}
                            height={68}
                            src={require(`../../img/clothes/${
                              elem[1].category
                            }${
                              elem[1].lvl >= 1 ? `${elem[1].lvl + 1}` : ""
                            }/${sex}/${elem[1].src}`)}
                            alt={elem[1].category}
                          />
                        )}
                      </TopWrapper>

                      <ButtonBottom
                        disabled={elem[1].isBuy}
                        onClick={
                          !isFetching && !isLoading && !elem[1].isBuy
                            ? setBuy.bind(null, {
                                name: elem[0],
                                diamond: elem[1].diamond,
                              })
                            : null
                        }
                      >
                        {elem[1].isBuy ? (
                          "В гардеробе"
                        ) : (
                          <Reward
                            name={"diamond"}
                            count={elem[1]?.diamond}
                            w={14}
                            button={true}
                          />
                        )}
                      </ButtonBottom>
                    </S.ShopElemWrapper>
                  );
                })}

              {(tab === "potion" || tab === "soul" || tab === "event") &&
                items.length > 0 &&
                items.map((elem, i) => {
                  return (
                    <ShopElem
                      key={i}
                      gold={gold}
                      silver={silver}
                      maxCharacCount={maxCharacCount}
                      damageTalents={damageTalents}
                      elem={elem}
                      loading={loading || isFetching}
                      isModalOpen={isModalOpen}
                      buyItem={buyItem}
                      inventory={inventory}
                      bosses={bosses}
                      bosses2={bosses2}
                      eventDiamondsCount={eventDiamondsCount}
                    />
                  );
                })}
              {tab === "treasure" && (
                <>
                  <S.ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Сундук 1 (
                        {roundConsumablesFixed(
                          treasures.countHave[0] || 0
                        ).toLocaleString("ru")}
                        )
                      </TopString>
                      <Reward
                        button={true}
                        name={"treasure"}
                        treasure={0}
                        w={68}
                      />
                    </TopWrapper>

                    <ButtonBottom onClick={treasureOpen.bind(null, 1)}>
                      Обменять
                    </ButtonBottom>
                  </S.ShopElemWrapper>

                  <S.ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Сундук 2 (
                        {roundConsumablesFixed(
                          treasures.countHave[1] || 0
                        ).toLocaleString("ru")}
                        )
                      </TopString>
                      <Reward
                        button={true}
                        name={"treasure"}
                        treasure={1}
                        w={68}
                      />
                    </TopWrapper>

                    <ButtonBottom onClick={treasureOpen.bind(null, 2)}>
                      Обменять
                    </ButtonBottom>
                  </S.ShopElemWrapper>
                </>
              )}
            </S.ShopElements>
          </div>
          <Popup
            isOpen={isPopupOpen}
            setIsOpen={setIsPopupOpen}
            w={317}
            h={217}
            back={frame3}
          >
            <Close
              onClick={() => {
                setIsPopupOpen(false);
              }}
            />

            <b>Желаете сразу использовать?</b>
            {boughtElem && (
              <InventoryElem
                width={143}
                useItem={useItem}
                loading={loading || isFetching}
                isModalOpen={isModalOpen}
                elem={inventoryArray.find(
                  (elem) =>
                    elem.category === boughtElem.category &&
                    elem.name === boughtElem.name
                )}
                setIsPopupOpen={setIsPopupOpen}
              />
            )}
          </Popup>

          <Popup
            isOpen={treasureChange}
            setIsOpen={setTreasureChange}
            w={306}
            h={302}
            back={frame2}
          >
            <Close onClick={treasureOpen.bind(null, 0)} />
            {treasureChange > 0 && (
              <>
                <span>
                  <h3>
                    <b>Обмен сундуков 10 к 1</b>
                  </h3>
                </span>

                <ShopElemWrapper width={200}>
                  <TopWrapper>
                    <TopString>
                      Сундук {treasureChange} (
                      {roundConsumablesFixed(
                        treasures.countHave[treasureChange - 1]
                      )}
                      )
                    </TopString>
                    <Reward
                      name={"treasure"}
                      treasure={treasureChange - 1}
                      w={36}
                      button={true}
                    />
                    <TopString>
                      Сундук {treasureChange + 1} (
                      {roundConsumablesFixed(
                        treasures.countHave[treasureChange]
                      )}
                      )
                    </TopString>

                    <Reward
                      name={"treasure"}
                      treasure={treasureChange + 1}
                      w={36}
                      button={true}
                    />

                    <InputWrapper>
                      <img src={inputImg} alt="" />

                      <Minus onClick={changeCount.bind(null, -1)}>-</Minus>
                      <InputNumber
                        count={countTreasure}
                        width={80}
                        min={1}
                        max={Math.floor(
                          treasures.countHave[treasureChange - 1] / 10
                        )}
                        setCount={setCountTreasure}
                      />
                      <Plus onClick={changeCount.bind(null, 1)}>+</Plus>
                    </InputWrapper>
                  </TopWrapper>

                  <ButtonBottom
                    onClick={!isFetching && !isLoading ? handleChange : null}
                  >
                    <Reward
                      button={true}
                      name={"gold"}
                      count={`
                    Обменять ${5 * countTreasure}`}
                      w={15}
                    />
                  </ButtonBottom>
                </ShopElemWrapper>
              </>
            )}
          </Popup>

          <Popup
            isOpen={buyInfo}
            setIsOpen={setBuyInfo}
            w={281}
            h={152}
            back={frame1}
          >
            <Close
              onClick={() => {
                setBuyInfo(null);
              }}
            />

            {buyInfo && (
              <>
                <b>Желаете приобрести предмет?</b>

                <Button
                  disabled={isFetching}
                  onClick={!isFetching ? buyClothes.bind(null, buyInfo) : null}
                >
                  <Reward
                    name={"diamond"}
                    count={`Купить ${(buyInfo?.diamond).toLocaleString("ru")}`}
                    w={14}
                    button={true}
                  />
                </Button>
              </>
            )}
          </Popup>
        </S.Content>
      )}
    </S.Wrapper>
  );
}

export default React.memo(Shop);
