import React, { useEffect, useState } from "react";

import * as S from "./Collection.styles";
import { roundConsumablesFixed, roundLvlExp } from "../../helpers";
import Reward from "../Reward/Reward";
import { grindData } from "../../info/data";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import plusImg from "../../img/collection/Plus.png";
import { MenuButtons } from "../Collections/Collections.styles";

function Collection({
  app,
  setCollectionName,
  collection,
  isFetching,
  setIsFetching,
  setUser,
  setIsModal,
  collectionsNeed,
  user,
  setIsLvlUpOpen,
  setModalError,
  rewards,
  name,
  counts,
  collectionIndex,
  serverTime,
  warOrdenDayTask,
}) {
  const [loading, setLoading] = useState(false);
  const [canGet, setCanGet] = useState(false);

  useEffect(() => {
    setCanGet(collection.counts.find((count) => count === 0) === undefined);
  }, [collection]);

  function getCollection(isAll = false) {
    if (collection.counts.find((count) => count === 0) === undefined) {
      setLoading(true);
      setIsFetching(true);

      let countMin = 1;

      if (isAll) {
        countMin = Math.min(...collection.counts);
      }

      setCollectionName({ name: name, count: countMin, ...rewards });

      const collectionInfo = {
        ...collection,
        done: +collection.done + countMin,
        counts: collection.counts.map((count) => count - countMin),
      };

      let isUp = 0;
      let goldUp = 0;
      let lvls = 1;
      let expOnLvl = user.allExp + rewards.exp * countMin;
      let expNeed = 300;

      while (expOnLvl >= expNeed) {
        expOnLvl -= expNeed;
        expNeed = roundLvlExp(
          expNeed *
            (lvls > 100 ? 1.1 : lvls > 150 ? 1.05 : lvls > 200 ? 1.02 : 1.2)
        );
        lvls++;
      }

      isUp = lvls - user.lvl;

      if (isUp > 0) {
        for (let step = 0; step < isUp; step++) {
          goldUp += user.lvl + step + 1;
        }
      } else {
        isUp = 0;
      }

      const characteristicInfo = {};
      Object.entries(user.characteristic).forEach((elem) => {
        characteristicInfo[elem[0]] = {
          ...elem[1],
          lvl: lvls * 5,
        };
      });

      const scales = {};
      const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
      user.damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      const hp = Math.floor(
        Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      );

      app
        .service("users")
        .patch(
          user._id,
          {
            [`collections.${collectionIndex}`]: collectionInfo,
            $inc: {
              mana: rewards.mana * countMin,
              silver: rewards.silver * countMin,
              allExp: rewards.exp * countMin,
              gold: goldUp,
              currentEnergy:
                isUp &&
                user.currentEnergy <
                  user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1]
                  ? user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1] -
                    user.currentEnergy
                  : 0,
              "dayQuest.collectionsGet": countMin,
              "spells.points": isUp,
              "spells.pointsAll": isUp,
              [`summerSave.results.collection${collectionIndex + 1}`]: countMin,
            },
            lvl: lvls,
            currentExpOnLvl: expOnLvl,
            onLvlExpNeed: expNeed,
            health: {
              current: isUp ? hp : user.health.current,
              max: hp,
            },
            characteristic: characteristicInfo,
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "mana",
                "silver",
                "allExp",
                "lvl",
                "gold",
                "currentEnergy",
                "health",
                "currentExpOnLvl",
                "collections",
                "characteristic",
                "onLvlExpNeed",
                "dayQuest",
                "spells",
                "summerSave",
              ],
            },
          }
        )
        .then((data) => {
          setTimeout(() => {
            setLoading(false);
          }, 250);
          setIsFetching(false);
          setIsModal(true);
          setUser((prev) => ({ ...prev, ...data }));
          if (isUp) {
            setIsLvlUpOpen({ lvls: isUp, gold: goldUp });
          }

          const tasksDay = [{ property: "collectionsGet", count: countMin }];
          warOrdenDayTask(tasksDay);
        })
        .catch((e) => {
          setLoading(false);
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    } else {
      setModalError("Недостаточно элементов коллекции");
      console.log("Недостаточно элементов");
    }
  }

  function addNeedCollection({ collectionIndex, elemIndex }) {
    if (
      [...collectionsNeed].findIndex(
        (elem) =>
          elem.collectionIndex === collectionIndex &&
          elem.elemIndex === elemIndex
      ) === -1
    ) {
      const collectionsNeedInfo = [
        ...collectionsNeed,
        { collectionIndex, elemIndex },
      ];
      if (collectionsNeedInfo.length <= 5) {
        setLoading(true);
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              collectionsNeed: collectionsNeedInfo,
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "collectionsNeed"],
              },
            }
          )
          .then((data) => {
            setTimeout(() => {
              setLoading(false);
            }, 250);
            setIsFetching(false);
            setUser((prev) => ({ ...prev, ...data }));
          })
          .catch((e) => {
            setLoading(false);
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        setModalError("Нет свободных мест");
        console.log("Нет свободных мест");
      }
    } else {
      setModalError("Элемент уже добавлен");
      console.log("Элемент уже добавлен");
    }
  }

  return (
    <S.Wrapper>
      <S.Info>
        <span>Собрано: {collection.done}</span>

        <S.Rewards>
          <span>Награда: </span>
          <Reward name={"mana"} count={rewards.mana} w={15} />
          <Reward name={"silver"} count={rewards.silver} w={15} />
          <Reward name={"exp"} count={rewards.exp} w={15} />
        </S.Rewards>
      </S.Info>
      <S.ElementsWrapper>
        {counts.map((elem, i) => {
          return (
            <S.Element key={i}>
              <RewardWrapper key={i} light={false} w={65}>
                <S.CollectionElem>
                  <img
                    src={require(`../../img/collection/${collectionIndex}/${
                      i + 1
                    }.png`)}
                    width={28}
                    height={28}
                    alt="коллекция"
                  />
                  <span>
                    {roundConsumablesFixed(collection.counts[i]).toLocaleString(
                      "ru"
                    )}
                  </span>
                </S.CollectionElem>
              </RewardWrapper>

              <S.Name>{elem.name}</S.Name>

              <S.AddButton>
                <Button
                  width={65}
                  onClick={
                    !loading && !isFetching
                      ? addNeedCollection.bind(null, {
                          collectionIndex: collectionIndex,
                          elemIndex: i,
                        })
                      : null
                  }
                >
                  <div>
                    <img src={plusImg} width={12} alt="добавить" />
                  </div>
                </Button>
              </S.AddButton>
            </S.Element>
          );
        })}
      </S.ElementsWrapper>
      <MenuButtons>
        <Button
          disabled={!canGet}
          width={130}
          onClick={
            !loading && !isFetching && canGet
              ? getCollection.bind(null, false)
              : null
          }
        >
          <div>Собрать</div>
        </Button>

        <Button
          disabled={!canGet}
          width={130}
          onClick={
            !loading && !isFetching && canGet
              ? getCollection.bind(null, true)
              : null
          }
        >
          <div>Собрать все</div>
        </Button>
      </MenuButtons>
    </S.Wrapper>
  );
}

export default React.memo(Collection);
