import imgSpellMan from "../img/skills/Man.png";
import imgSpellGhost from "../img/skills/Ghost.png";
import imgSpellEntity from "../img/skills/Entity.png";

import imgSpellManBoss from "../img/skills/ManBoss.png";
import imgSpellGhostBoss from "../img/skills/GhostBoss.png";
import imgSpellEntityBoss from "../img/skills/EntityBoss.png";

export const bankBonusArray = [
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
  {
    clothesScale: 1,
    greenHouseScale: 1,
    potionLabScale: 1,
    bossesLimitAdd: 0,
    bossesTimeAdd: 0,
    stockCount: 1,
    buyScale: 1,
  },
];

export const bankLvlMoney = [
  500, 1000, 2500, 5000, 10000, 20000, 40000, 70000, 110000, 160000, 220000,
  290000, 370000, 460000, 560000,
];

export const lvlStages = [
  30, 50, 60, 70, 80, 90, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150,
  155, 160, 165, 170, 175, 180, 185, 190, 195, 200,
];

export const notesInfo = {
  raidBoss: {
    name: "raidBoss",
    title: "Рейдовый босс уже доступен!",
    text: "",
    button: {
      text: "Перейти к боссу",
      location: "raidBoss",
    },
  },

  event: {
    name: "event",
    title: "Начался праздничный ивент!",
    text: "",
    button: {
      text: "Участвовать",
      location: "ascaban",
    },
  },

  shyt: {
    name: "shyt",
    title: "Шут явился вновь!",
    text: "",
    button: {
      text: "Перейти к боссу",
      location: "shyt",
    },
  },

  isRaid: {
    name: "isRaid",
    title: "Сражение орденов уже началось!",
    text: "",
    button: {
      text: "Начать сражение",
      menuModal: "ordens",
    },
  },

  arenaTournament: {
    name: "arenaTournament",
    title: "Регистрация на Турнир Дуэлей уже доступна!",
    titleStart: "Турнир Дуэлей уже начался!",
    text: "",
    button: {
      text: "Перейти на арену",
      location: "arena",
    },
  },

  diceGameTournament: {
    name: "diceGameTournament",
    title: "Турнир Кости уже начался!",
    text: "",
    button: {
      text: "Перейти в игровую",
      location: "gameRoom",
    },
  },

  game21Tournament: {
    name: "game21Tournament",
    title: "Турнир 21 уже начался!",
    text: "",
    button: {
      text: "Перейти в игровую",
      location: "gameRoom",
    },
  },

  hourInfo: {
    name: "hourInfo",
    title: "Час уже прошел!",
    text: "Заходи каждый час и забирай зелья здоровья и маны (все зелья попадают в инвентарь)",
    button: {
      type: "hourInfo",
    },
  },
};

export const tournamentPrizes = [
  {
    gold: 2500,
    clothes: {
      count: 1,
      event: true,
    },
  },
  {
    gold: 2500,
    clothes: {
      count: 2,
      event: false,
    },
  },
  {
    gold: 2500,
    clothes: {
      count: 1,
      event: false,
    },
  },
  {
    gold: 2500,
  },
  {
    gold: 2250,
  },
  {
    gold: 2000,
  },
  {
    gold: 1750,
  },
  {
    gold: 1500,
  },
  {
    gold: 1250,
  },
  {
    gold: 1000,
  },
];

export const newYearPrize = {
  gold: {
    count: 2024,
  },
  game21: {
    count: 100,
  },
  diceGame: {
    count: 200,
  },
};

export const newQuestPrizes = [
  {
    price: 10,
    gold: 1000,
    silver: 500000,
    essence: 100000,
  },
  {
    price: 20,
    gold: 2400,
    silver: 1200000,
    essence: 240000,
  },
  {
    price: 50,
    gold: 8000,
    silver: 4000000,
    essence: 800000,
  },
];

export const newSummerSavePrizes = [
  {
    price: 15,
    gold: 300,
    silver: 300000,
    mana: 15000,
  },
  {
    price: 35,
    gold: 1000,
    silver: 1000000,
    mana: 50000,
  },
  {
    price: 65,
    gold: 2000,
    silver: 2000000,
    mana: 100000,
  },
];

export const newSummerSaveClothes = [
  {
    category: "shoes",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "pants",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "hand",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "shirt",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "head",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "broom",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "wand",
    name: "Event1",
    lvl: 0,
  },
];

export const newQuestArray = [
  {
    property: "arenaWins",
    count: 3,
    reward: {
      property: "essence",
      count: 300,
    },
    place: "arena",
    string: "Победи волшебника в дуэли.",
  },
  {
    property: "addCharac",
    count: 1,
    reward: {
      property: "potion",
      count: 2,
      potionName: "+100%",
      category: "energy",
      value: 100,
      percent: true,
    },
    place: "book",
    string: "Увеличь любую из своих характеристик.",
  },
  {
    property: "classroom1",
    count: 1,
    reward: {
      property: "mana",
      count: 250,
    },
    place: "potionRoom",
    string: "Пройди урок зельеварения.",
  },
  {
    property: "classroom2",
    count: 1,
    reward: {
      property: "mana",
      count: 250,
    },
    place: "grassRoom",
    string: "Пройди урок растениеводства.",
  },
  {
    property: "bossesWins",
    count: 3,
    reward: {
      property: "magic",
      count: 2,
    },
    place: "bosses",
    string: `Одолей Босса Пикси в Бестиарии`,
  },
  {
    property: "potions",
    count: 3,
    reward: {
      property: "diceGame",
      count: 5,
    },
    place: "potionLab",
    string: "Начни приготовление зелий.",
  },
  {
    property: "grasses",
    count: 10,
    reward: {
      property: "game21",
      count: 5,
    },
    place: "greenHouse",
    string: "Засади всю теплицу растениями.",
  },
  {
    property: "diceGame",
    count: 5,
    reward: {
      property: "silver",
      count: 10000,
    },
    place: "gameRoom",
    string: `Сыграй в игру "кости"`,
  },
  {
    property: "game21",
    count: 5,
    reward: {
      property: "gold",
      count: 50,
    },
    place: "gameRoom",
    string: `Сыграй в игру "21"`,
  },
];

export const newQuestEndPrizes = [
  { property: "gold", count: 100 },
  { property: "silver", count: 50000 },
  { property: "magic", count: 10 },
  { property: "soul", number: 1, count: 20 },
  { property: "diceGame", count: 20 },
  { property: "game21", count: 20 },
];

export const boxPrice = [10, 20, 50];

export const boxPrizes = [
  [
    { property: "soul", number: 3, count: 30 }, //600
    { property: "soul", number: 4, count: 30 }, //1200
    { property: "diceGame", count: 100 }, // 100
    { property: "game21", count: 50 }, // 100
  ],
  [
    { property: "soul", number: 3, count: 65 }, //1300
    { property: "soul", number: 4, count: 65 }, //2500
    { property: "diceGame", count: 200 }, //200
    { property: "game21", count: 100 }, //200
  ],
  [
    { property: "soul", number: 3, count: 180 }, //3600
    { property: "soul", number: 4, count: 180 }, //7200
    { property: "diceGame", count: 500 }, //500
    { property: "game21", count: 250 }, //500
  ],
];

export const bankInfo = {
  gold: {
    name: "золото",
    prices: [1, 5, 10, 20, 50, 100],
    count: [100, 600, 1300, 2800, 8000, 20000],
    benefits: ["0%", "17%", "23%", "29%", "38%", "50%"],
  },

  mana: {
    name: "ману",
    prices: [1, 5, 10, 20, 50, 100],
    count: [10000, 60000, 130000, 280000, 800000, 2000000],
    benefits: ["0%", "17%", "23%", "29%", "38%", "50%"],
  },

  essence: {
    name: "эссенцию",
    prices: [1, 5, 10, 20, 50, 100],
    count: [7500, 45000, 97500, 225000, 600000, 1500000],
    additional: [750, 4500, 9750, 22500, 60000, 150000],
    benefits: ["0%", "17%", "23%", "29%", "38%", "50%"],
  },
};

export const grindData = {
  owl: {
    string: "Максимальная энергия",
    percent: false,
    price: {
      type: "silver",
      count: [
        500, 1000, 1500, 2000, 4000, 5000, 6000, 7000, 8000, 10000, 12500,
        15000, 25000, 50000,
      ],
    },
    chance: [95, 90, 85, 80, 75, 70, 65, 60, 55, 45, 40, 30, 20, 10],
    bonus: [0, 3, 6, 9, 12, 18, 24, 30, 36, 42, 60, 90, 120, 180, 270],
  },
  cup: {
    string: "Эссенции в дуэлях",
    percent: true,
    price: {
      type: "gold",
      count: [10, 20, 30, 40, 50, 80, 120, 160, 200, 250, 300, 350, 400, 500],
    },
    chance: [10, 10, 10, 10, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1],
    bonus: [0, 1, 2, 3, 4, 6, 8, 10, 12, 14, 20, 30, 40, 60, 90],
  },
  book: {
    string: "Повышение характеристик",
    percent: true,
    price: {
      type: "gold",
      count: [5, 10, 15, 20, 40, 50, 60, 70, 80, 100, 125, 150, 250, 500],
    },
    chance: [80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 10],
    bonus: [0, 1, 2, 3, 4, 6, 8, 10, 12, 14, 20, 30, 40, 60, 90],
  },
};

export const stockDamage = [
  { scale: 0.1, price: 15 },
  { scale: 0.2, price: 25 },
  { scale: 0.3, price: 40 },
];

export const inventoryItems = [
  {
    name: "+50%",
    category: "hp",
    value: 50,
    percent: true,
    time: 0,
  },
  {
    name: "+100%",
    category: "hp",
    value: 100,
    percent: true,
    time: 0,
  },
  {
    name: "+100",
    category: "energy",
    value: 100,
    percent: false,
    time: 0,
  },
  {
    name: "+200",
    category: "energy",
    value: 200,
    percent: false,

    time: 0,
  },
  {
    name: "+50%",
    category: "energy",
    value: 50,
    percent: true,
    time: 0,
  },
  {
    name: "+100%",
    category: "energy",
    value: 100,
    percent: true,
    time: 0,
  },
  {
    name: "+200",
    category: "mana",
    value: 200,
    percent: false,
    time: 0,
  },
];

export const shopEventItems = [
  {
    potion: true,
    name: "+100%",
    categoryName: "Здоровье",
    category: "hp",
    value: 100,
    percent: true,
    pack: 25,
    price: {
      property: "diamond",
      count: 10,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+50%",
    categoryName: "Энергия",
    category: "energy",
    value: 50,
    percent: true,
    pack: 10,
    price: {
      property: "diamond",
      count: 10,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+200",
    categoryName: "Мана",
    category: "mana",
    value: 200,
    percent: true,
    pack: 10,
    price: {
      property: "diamond",
      count: 10,
    },
    time: 0,
  },
  {
    name: "Полтергейст",
    category: "soul",
    value: 1,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 1,
    },
    time: 0,
  },
  {
    name: "Призрак Плаксы",
    category: "soul",
    value: 2,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 2,
    },
    time: 0,
  },
  {
    name: "Жуткий Завхоз",
    category: "soul",
    value: 3,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 4,
    },
    time: 0,
  },
  {
    name: "Кошмар",
    category: "soul",
    value: 4,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 8,
    },
    time: 0,
  },
  {
    name: "Зловещий Призрак",
    category: "soul",
    value: 5,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 16,
    },
    time: 0,
  },
  {
    name: "Обскур",
    category: "soul",
    value: 6,
    percent: false,
    pack: 2,
    price: {
      property: "diamond",
      count: 32,
    },
    time: 0,
  },
  {
    name: "Проклятый Эльф",
    category: "soul2",
    value: 1,
    percent: false,
    price: {
      property: "diamond",
      count: 40,
    },
    time: 0,
  },
  {
    name: "Зуфу",
    category: "soul2",
    value: 2,
    percent: false,
    price: {
      property: "diamond",
      count: 80,
    },
    time: 0,
  },
  {
    name: "Двухголовый",
    category: "soul2",
    value: 3,
    percent: false,
    price: {
      property: "diamond",
      count: 160,
    },
    time: 0,
  },
  {
    name: "Аркейн",
    category: "soul2",
    value: 4,
    percent: false,
    price: {
      property: "diamond",
      count: 320,
    },
    time: 0,
  },
  {
    name: "Гриндиллоу",
    category: "soul2",
    value: 5,
    percent: false,
    price: {
      property: "diamond",
      count: 640,
    },
    time: 0,
  },
  {
    name: "Хвост",
    category: "soul2",
    value: 6,
    percent: false,
    price: {
      property: "diamond",
      count: 1280,
    },
    time: 0,
  },
];

export const shopItems = [
  {
    potion: true,
    name: "+50%",
    categoryName: "Здоровье",
    category: "hp",
    value: 50,
    percent: true,
    price: {
      property: "silver",
      name: "серебра",
      count: 500,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+100%",
    categoryName: "Здоровье",
    category: "hp",
    value: 100,
    percent: true,
    price: {
      property: "silver",
      name: "серебра",
      count: 1000,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+50%",
    categoryName: "Здоровье",
    category: "hp",
    value: 50,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 1,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+100%",
    categoryName: "Здоровье",
    category: "hp",
    value: 100,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 2,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+100",
    categoryName: "Энергия",
    category: "energy",
    value: 100,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+200",
    categoryName: "Энергия",
    category: "energy",
    value: 200,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+50%",
    categoryName: "Энергия",
    category: "energy",
    value: 50,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 5,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+100%",
    categoryName: "Энергия",
    category: "energy",
    value: 100,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+200",
    categoryName: "Мана",
    category: "mana",
    value: 200,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    time: 0,
  },
  {
    potion: true,
    name: "+200",
    categoryName: "Мана",
    category: "mana",
    value: 200,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 5,
    },
    time: 0,
  },

  {
    name: "Полтергейст",
    category: "soul",
    value: 1,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 3,
    },
    time: 0,
  },
  {
    name: "Призрак Плаксы",
    category: "soul",
    value: 2,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 6,
    },
    time: 0,
  },
  {
    name: "Жуткий Завхоз",
    category: "soul",
    value: 3,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 12,
    },
    time: 0,
  },
  {
    name: "Кошмар",
    category: "soul",
    value: 4,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 24,
    },
    time: 0,
  },
  {
    name: "Зловещий Призрак",
    category: "soul",
    value: 5,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 48,
    },
    time: 0,
  },
  {
    name: "Обскур",
    category: "soul",
    value: 6,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 96,
    },
    time: 0,
  },

  {
    name: "Проклятый Эльф",
    category: "soul2",
    value: 1,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 200,
    },
    time: 0,
  },
  {
    name: "Зуфу",
    category: "soul2",
    value: 2,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 400,
    },
    time: 0,
  },
  {
    name: "Двухголовый",
    category: "soul2",
    value: 3,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    time: 0,
  },
  {
    name: "Аркейн",
    category: "soul2",
    value: 4,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 1600,
    },
    time: 0,
  },
  {
    name: "Гриндиллоу",
    category: "soul2",
    value: 5,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 3200,
    },
    time: 0,
  },
  {
    name: "Хвост",
    category: "soul2",
    value: 6,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 6400,
    },
    time: 0,
  },
];

export const weakDamagePrizes = [
  {
    gold: 3000,
    silver: 100000,
    mana: 30000,
  },
  {
    gold: 1500,
    silver: 500000,
    mana: 15000,
  },
  {
    gold: 800,
    silver: 300000,
    mana: 8000,
  },
  {
    gold: 500,
    silver: 180000,
    mana: 5000,
  },
  {
    gold: 300,
    silver: 150000,
    mana: 3000,
  },
  {
    gold: 200,
    silver: 120000,
    mana: 2000,
  },
  {
    gold: 100,
    silver: 90000,
    mana: 1000,
  },
  {
    gold: 80,
    silver: 60000,
    mana: 800,
  },
  {
    gold: 50,
    silver: 30000,
    mana: 500,
  },
  {
    gold: 30,
    silver: 20000,
    mana: 300,
  },
];

export const weakBossesPrizes = [
  {
    gold: 3000,
    exp: 6000,
  },
  {
    gold: 2750,
    exp: 5500,
  },
  {
    gold: 2500,
    exp: 5000,
  },
  {
    gold: 2250,
    exp: 4500,
  },
  {
    gold: 2000,
    exp: 4000,
  },
  {
    gold: 1750,
    exp: 3500,
  },
  {
    gold: 1500,
    exp: 3000,
  },
  {
    gold: 1250,
    exp: 2500,
  },
  {
    gold: 1000,
    exp: 2000,
  },
  {
    gold: 900,
    exp: 1800,
  },
  {
    gold: 800,
    exp: 1600,
  },
  {
    gold: 700,
    exp: 1400,
  },
  {
    gold: 600,
    exp: 1200,
  },
  {
    gold: 500,
    exp: 1000,
  },
  {
    gold: 400,
    exp: 800,
  },
  {
    gold: 300,
    exp: 600,
  },
  {
    gold: 250,
    exp: 500,
  },
  {
    gold: 200,
    exp: 400,
  },
  {
    gold: 150,
    exp: 300,
  },
  {
    gold: 100,
    exp: 200,
  },
];

export const weakArenaPrizes = [
  {
    mana: 30000,
    exp: 6000,
  },
  {
    mana: 27500,
    exp: 5500,
  },
  {
    mana: 25000,
    exp: 5000,
  },
  {
    mana: 22500,
    exp: 4500,
  },
  {
    mana: 20000,
    exp: 4000,
  },
  {
    mana: 17500,
    exp: 3500,
  },
  {
    mana: 15000,
    exp: 3000,
  },
  {
    mana: 12500,
    exp: 2500,
  },
  {
    mana: 10000,
    exp: 2000,
  },
  {
    mana: 9000,
    exp: 1800,
  },
  {
    mana: 8000,
    exp: 1600,
  },
  {
    mana: 7000,
    exp: 1400,
  },
  {
    mana: 6000,
    exp: 1200,
  },
  {
    mana: 5000,
    exp: 1000,
  },
  {
    mana: 4000,
    exp: 800,
  },
  {
    mana: 3000,
    exp: 600,
  },
  {
    mana: 2500,
    exp: 500,
  },
  {
    mana: 2000,
    exp: 400,
  },
  {
    mana: 1500,
    exp: 300,
  },
  {
    mana: 1000,
    exp: 200,
  },
];

export const weakGamesPrizes = [
  {
    silver: 1200000,
    exp: 3000,
  },
  {
    silver: 1100000,
    exp: 2750,
  },
  {
    silver: 1000000,
    exp: 2500,
  },
  {
    silver: 900000,
    exp: 2250,
  },
  {
    silver: 800000,
    exp: 2000,
  },
  {
    silver: 700000,
    exp: 1750,
  },
  {
    silver: 600000,
    exp: 1500,
  },
  {
    silver: 500000,
    exp: 1250,
  },
  {
    silver: 400000,
    exp: 1000,
  },
  {
    silver: 360000,
    exp: 900,
  },
  {
    silver: 320000,
    exp: 800,
  },
  {
    silver: 280000,
    exp: 700,
  },
  {
    silver: 240000,
    exp: 600,
  },
  {
    silver: 200000,
    exp: 500,
  },
  {
    silver: 160000,
    exp: 400,
  },
  {
    silver: 120000,
    exp: 300,
  },
  {
    silver: 100000,
    exp: 250,
  },
  {
    silver: 80000,
    exp: 200,
  },
  {
    silver: 60000,
    exp: 150,
  },
  {
    silver: 40000,
    exp: 100,
  },
];

export const weakClassroomsPrizes = [
  {
    essence: 300000,
    exp: 3000,
  },
  {
    essence: 275000,
    exp: 2750,
  },
  {
    essence: 250000,
    exp: 2500,
  },
  {
    essence: 225000,
    exp: 2250,
  },
  {
    essence: 200000,
    exp: 2000,
  },
  {
    essence: 175000,
    exp: 1750,
  },
  {
    essence: 150000,
    exp: 1500,
  },
  {
    essence: 125000,
    exp: 1250,
  },
  {
    essence: 100000,
    exp: 1000,
  },
  {
    essence: 90000,
    exp: 900,
  },
  {
    essence: 80000,
    exp: 800,
  },
  {
    essence: 70000,
    exp: 700,
  },
  {
    essence: 60000,
    exp: 600,
  },
  {
    essence: 50000,
    exp: 500,
  },
  {
    essence: 40000,
    exp: 400,
  },
  {
    essence: 30000,
    exp: 300,
  },
  {
    essence: 25000,
    exp: 250,
  },
  {
    essence: 20000,
    exp: 200,
  },
  {
    essence: 15000,
    exp: 150,
  },
  {
    essence: 10000,
    exp: 100,
  },
];

export const topsInfo = {
  bosses: {
    rewards: weakBossesPrizes,
    property: "weakBossesPoints",
    info: "weakBossesInfo",
    name: "Топ по Боссам",
    rewardName: "gold",
    strings: [
      "Очки начисляются за победу над Боссами в Бестиарии.",
      "Очки за сложность боссов: 'Пикси' - 1,  'Полтергейст'- 2, 'Призрак Плаксы'- 4 и т.д.",
      "Очки за победу в различных стадиях: 'обычный' - x1,  'агрессивный'- x2,  'проклятый'- x4.",
      "Очки в Соло режиме: 'Пикси' - x3,  'Полтергейст'- x5, 'Призрак Плаксы'- x7 и т.д.",
      "Дополнительное условие: при равенстве очков сравниваются показатели недельного урона.",
    ],
  },
  arena: {
    rewards: weakArenaPrizes,
    property: "weakEssence",
    info: "weakArenaInfo",
    name: "Топ по Арене",
    rewardName: "mana",
    strings: [
      "Очки начисляются за полученные эссенции при победе в дуэльной комнате, за каждую полученную эссенцию игрок получает 1 очко.",
      "Дополнительное условие: при равенстве очков сравнивается сумма всех побед на арене (в нападении и защите).",
    ],
  },
  games: {
    rewards: weakGamesPrizes,
    property: "weakGamesPoints",
    info: "weakGamesInfo",
    name: "Топ по Играм",
    rewardName: "silver",
    strings: [
      "Условия начисления очков в игре 'Кости': учитывается сумма на костях выигрышных комбинаций (две или более кости с одинаковым числом).",
      "Условия начисления очков в игре '21': победа с 21 очком - 25, с 20 очками - 15, с 19 очками и менее - 10, два туза - 80, пять 'картинок', 678 или 777 - 100.",
      "Дополнительное условие: при равенстве очков сравнивается сумма уровня игры в 'Кости' и количество побед в '21'.",
    ],
  },
  classrooms: {
    rewards: weakClassroomsPrizes,
    property: "weakEnergy",
    info: "weakClassroomsInfo",
    name: "Топ по Урокам",
    rewardName: "essence",
    strings: [
      "Очки начисляются за потраченную энергию при прохождении учебных классов,за каждую единицу энергии игрок получает 1 очко.",
      "Дополнительное условие: при равенстве очков сравнивается количество пройденных уроков во всех учебных классах.",
    ],
  },
};

export const classRoomsArray = [
  {
    name: "Зельеварение",
    rewards: {
      exp: 300,
      mana: 100,
      silver: 300,
    },
    steps: [
      {
        name: "Протереть склянки",
        energy: 4,
        countNeed: 6,
        rewards: {
          exp: 4,
          mana: 2,
        },
      },
      {
        name: "Изготовить зелье",
        energy: 16,
        countNeed: 10,
        rewards: {
          exp: 16,
          mana: 8,
        },
      },
      {
        name: "Подготовить ингредиенты",
        energy: 12,
        countNeed: 8,
        rewards: {
          exp: 12,
          mana: 6,
        },
      },
      {
        name: "Изучить рецепт",
        energy: 8,
        countNeed: 6,
        rewards: {
          exp: 8,
          mana: 4,
        },
      },
    ],
  },
  {
    name: "Растениеводство",
    rewards: {
      exp: 300,
      mana: 100,
      silver: 300,
    },
    steps: [
      {
        name: "Подготовить инструменты",
        energy: 4,
        countNeed: 6,
        rewards: {
          exp: 4,
          mana: 2,
        },
      },
      {
        name: "Подготовить воду для полива",
        energy: 8,
        countNeed: 6,
        rewards: {
          exp: 8,
          mana: 4,
        },
      },
      {
        name: "Подготовить удобрения",
        energy: 12,
        countNeed: 8,
        rewards: {
          exp: 12,
          mana: 6,
        },
      },
      {
        name: "Просушить растения",
        energy: 16,
        countNeed: 10,
        rewards: {
          exp: 16,
          mana: 8,
        },
      },
    ],
  },
];

export const collectionsArray = [
  {
    name: "Зельеварение",
    rewards: {
      exp: 200,
      silver: 200,
      mana: 50,
    },
    counts: [
      {
        name: "Колба с водой",
      },
      {
        name: "Огненный порошок",
      },
      {
        name: "Котёл",
      },
      {
        name: "Перо феникса",
      },
      {
        name: "Рыбий скелет",
      },
    ],
  },
  {
    name: "Растениеводство",
    rewards: {
      exp: 200,
      silver: 200,
      mana: 50,
    },
    counts: [
      {
        name: "Лопатка",
      },
      {
        name: "Семена рассады",
      },
      {
        name: "Горшок",
      },
      {
        name: "Удобрение",
      },
      {
        name: "Лейка",
      },
    ],
  },
];

export const bossesTalents = {
  bossExpScale: {
    entity: "bossEntityExpScale",
    man: "bossManExpScale",
    ghost: "bossGhostExpScale",
  },
  bossSilverScale: {
    entity: "bossEntitySilverScale",
    man: "bossManSilverScale",
    ghost: "bossGhostSilverScale",
  },
};

export const simpleTalentsDefault = {
  maxValueEnergy: 100,
  timerEnergy: 120,
  bossEntityExpScale: 0,
  bossManExpScale: 0,
  bossGhostExpScale: 0,
  bossManSilverScale: 0,
  bossEntitySilverScale: 0,
  bossGhostSilverScale: 0,
  collectionChance: 7,
  hpSilverDiscount: 0,
  manaScale: 0,
  kritChance: 5,
  accuracyChance: 0,
  bossDamageScale: 0,
  bossDefenceScale: 0,
};

export const simpleTalents = [
  {
    property: "maxValueEnergy",
    name: "Повышение максимального значения энергии",
    string: "Увеличивает максимальное значение энергии на 50 (5 за уровень)",
    number: 0,
    need: [],
    countNeed: 0,
    countHave: 10,
    step: 5,
    percent: false,
  },
  {
    property: "timerEnergy",
    name: "Уменьшение времени на восстановление энергии",
    string:
      "Уменьшает время на восстановление энергии на 1 минуту (6 секунд за уровень)",
    number: 1,
    need: [],
    countNeed: 0,
    countHave: 10,
    step: -6,
    percent: false,
  },
  {
    property: "bossManExpScale",
    name: "Повышенный опыт за пораженных людей",
    string:
      "Повышает получаемый опыт после поединка с Боссом (человеком) на 50% (2% за уровень)",
    number: 2,
    need: [0],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },
  {
    property: "bossGhostExpScale",
    name: "Повышенный опыт за пораженных призраков",
    string:
      "Повышает получаемый опыт после поединка с Боссом (призраком) на 50% (2% за уровень)",
    number: 3,
    need: [0, 1],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },
  {
    property: "bossEntityExpScale",
    name: "Повышенный опыт за пораженных существ",
    string:
      "Повышает получаемый опыт после поединка с Боссом (существом) на 50% (2% за уровень)",
    number: 4,
    need: [1],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },

  {
    property: "bossManSilverScale",
    name: "Повышенное серебро за пораженных людей",
    string:
      "Повышает получаемое серебро после поединка с Боссом (человеком) на 100% (2% за уровень)",
    number: 5,
    need: [2],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },
  {
    property: "bossGhostSilverScale",
    name: "Повышенное серебро за пораженных призраков",
    string:
      "Повышает получаемое серебро после поединка с Боссом (призраком) на 100% (2% за уровень)",
    number: 6,
    need: [3],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },
  {
    property: "bossEntitySilverScale",
    name: "Повышенное серебро за пораженных существ",
    string:
      "Повышает получаемое серебро после поединка с Боссом (существом) на 100% (2% за уровень)",
    number: 7,
    need: [4],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },

  {
    property: "hpSilverDiscount",
    name: "Уменьшение стоимости восстановления здоровья (за серебро)",
    string:
      "Уменьшает стоимость покупки зелий лечения за серебро на 50% (1% за уровень)",
    number: 8,
    need: [5],
    countNeed: 35,
    countHave: 50,
    step: 1,
    percent: true,
  },
  {
    property: "collectionChance",
    name: "Повышает шанс выпада коллекции",
    string: "Повышает шанс выпадения любой коллекции на 15% (0.3% за уровень)",
    number: 9,
    need: [7],
    countNeed: 35,
    countHave: 50,
    step: 0.3,
    percent: true,
  },

  {
    property: "kritChance",
    name: "Увеличение шанса критического попадания в поединке с Боссом",
    string:
      "Повышает шанс критического урона на 16% (0.2% за уровень) при поединке с любым Боссом",
    number: 10,
    need: [8],
    countNeed: 35,
    countHave: 80,
    step: 0.2,
    percent: true,
  },
  {
    property: "accuracyChance",
    name: "Уменьшает шанс промахнуться заклинанием в поединке с Боссом",
    string:
      "Повышает точность заклинаний в поединке с любым Боссом на 80% (1% за уровень)",
    number: 11,
    need: [9],
    countNeed: 35,
    countHave: 80,
    step: 1,
    percent: true,
  },

  {
    property: "bossDamageScale",
    name: "Увеличение урона от заклинания в поединке с Боссом",
    string:
      "Повышает урон на 30% (0.3% за уровень) при поединке с любым Боссом",
    number: 12,
    need: [10],
    countNeed: 60,
    countHave: 100,
    step: 0.3,
    percent: true,
  },
  {
    property: "bossDefenceScale",
    name: "Снижение получаемого урона в поединке с Боссом",
    string:
      "Понижает получаемый урон в поединке с любым Боссом на 40% (0.4% за уровень)",
    number: 13,
    need: [11],
    countNeed: 60,
    countHave: 100,
    step: 0.4,
    percent: true,
  },

  {
    property: "manaScale",
    name: "Снижение затрат маны на использование заклинаний",
    string:
      "Уменьшает затрату маны на 25% (0.25% за уровень) за один удар в сражении с Боссом",
    number: 14,
    need: [5, 6, 7],
    countNeed: 35,
    countHave: 100,
    step: 0.25,
    percent: true,
  },
];

export const characteristicTalents = [
  {
    name: "Здоровье",
    string: "C каждым улучшением навыка базовое здоровье увеличивается на 0.2%",
  },
  {
    name: "Сила магии",
    string:
      "C каждым улучшением навыка базовая сила магии увеличивается на 0.2%",
  },
  {
    name: "Ярость",
    string: "C каждым улучшением навыка базовая ярость увеличивается на 0.2%",
  },
  {
    name: "Сопротивление",
    string:
      "C каждым улучшением навыка базовое сопротивление увеличивается на 0.2%",
  },
  {
    name: "Точность",
    string: "C каждым улучшением навыка базовая точность увеличивается на 0.2%",
  },
  {
    name: "Ловкость",
    string: "C каждым улучшением навыка базовая ловкость увеличивается на 0.2%",
  },
  {
    name: "Скорость",
    string: "C каждым улучшением навыка базовая скорость увеличивается на 0.2%",
  },
];

export const dayQuestRewards = [
  {
    gold: 10,
    exp: 100,
    mana: 200,
    master: 1,
  },
  {
    gold: 30,
    exp: 300,
    mana: 600,
    master: 1,
  },
  {
    gold: 80,
    exp: 1600,
    mana: 1600,
    master: 1,
  },
  {
    gold: 200,
    exp: 8000,
    mana: 4000,
    master: 1,
  },
  {
    gold: 1000,
    exp: 50000,
    mana: 10000,
    master: 1,
  },
];

export const summerSaveQuestsArray = [
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Сыграть в кости",
      count: 5,
      property: "diceGameGames",
    },
    {
      string: "Сыграть в 21",
      count: 5,
      property: "game21Games",
    },
    {
      string: "Провести дуэль с волшебником",
      count: 5,
      property: "arenaFight",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Пройти урок Зельевариние",
      count: 2,
      property: "classroom1",
    },
    {
      string: "Собрать коллекцию Зельевариние",
      count: 2,
      property: "collection1",
    },
    {
      string: "Одолеть Босса в Бестиарии",
      count: 10,
      property: "bossesWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Пройти урок Растениеводство",
      count: 2,
      property: "classroom2",
    },
    {
      string: "Собрать коллекцию Растениеводство",
      count: 2,
      property: "collection2",
    },
    {
      string: "Одолеть лично Босса в Бестиарии",
      count: 10,
      property: "bossesSoloWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Вырастить растения в Теплице",
      count: 10,
      property: "grasses",
    },
    {
      string: "Сварить зелье в Лаборатории",
      count: 10,
      property: "potions",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 10,
      property: "arenaWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Выиграть в 21",
      count: 10,
      property: "game21Win",
    },
    {
      string: "Отправить драже 'Сюрприз' одногруппникам",
      count: 10,
      property: "candyMove",
    },
    {
      string: "Съесть драже 'Сюрприз'",
      count: 10,
      property: "candyGet",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Открыть сундуки",
      count: 10,
      property: "treasures",
    },
    {
      string: "Отправить элементы коллекций одногруппникам",
      count: 10,
      property: "collectionsMove",
    },
    {
      string: "Получить элементы коллекций от одногруппников",
      count: 10,
      property: "collectionsGetCount",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Одолеть Соло-Босса",
      count: 10,
      property: "soloBossesWins",
    },
    {
      string: "Собрать комбинацию из 2-ух тузов в 21",
      count: 1,
      property: "game21Aces",
    },
    {
      string: "Собрать комбинацию из 4-ёх пятёрок в костях",
      count: 1,
      property: "diceGame5555",
    },
  ],
];

export const warOrdenPrizes = [
  {
    talent: 120,
    essence: 120,
  },
  {
    talent: 100,
    essence: 100,
  },
  {
    talent: 80,
    essence: 80,
  },
  {
    talent: 60,
    essence: 60,
  },
  {
    talent: 50,
    essence: 50,
  },
  {
    talent: 40,
    essence: 40,
  },
  {
    talent: 30,
    essence: 30,
  },
  {
    talent: 20,
    essence: 20,
  },
  {
    talent: 15,
    essence: 15,
  },
  {
    talent: 10,
    essence: 10,
  },
];

export const warOrdenQuests = [
  {
    string: "Одержать победу в дуэлях",
    count: 2500,
    property: "arenaWins",
    points: 50,
    gold: 10000,
  },
  {
    string: "Сварить зелье в Лаборатории",
    count: 2500,
    property: "potionsGet",
    points: 50,
    gold: 10000,
  },
  {
    string: "Вырастить растения в Теплице",
    count: 2500,
    property: "grasses",
    points: 50,
    gold: 10000,
  },
  {
    string: "Пройти урок зельеварения в учебном классе",
    count: 5000,
    property: "classroomsGet0",
    points: 50,
    gold: 10000,
  },
  {
    string: "Пройти урок растениеводства в учебном классе",
    count: 5000,
    property: "classroomsGet1",
    points: 50,
    gold: 10000,
  },
  {
    string: "Открыть сундук",
    count: 1000,
    property: "treasureOpen",
    points: 50,
    gold: 10000,
  },
  {
    string: "Собрать коллекцию",
    count: 10000,
    property: "collectionsGet",
    points: 50,
    gold: 10000,
  },
];

export const warSoloQuests = [
  {
    string: "Одержать победу в дуэлях",
    count: 100,
    property: "arenaWins",
    points: 5,
    magic: 5,
  },
  {
    string: "Посадить 'Аконит' в Теплице",
    count: 100,
    property: "grassesPut4",
    points: 5,
    magic: 5,
  },
  {
    string: "Собрать особую комбинацию в 21",
    count: 1,
    property: "game21Combs",
    points: 5,
    magic: 5,
  },
  {
    string: "Собрать комбинацию из 4-ёх чётвёрок в кости",
    count: 15,
    property: "diceGameComb4444",
    points: 5,
    magic: 5,
  },
  {
    string: "Собрать комбинацию из 4-ёх пятёрок в кости",
    count: 5,
    property: "diceGameComb5555",
    points: 5,
    magic: 5,
  },
  {
    string: "Отправить драже 'Сюрприз' одногруппникам",
    count: 100,
    property: "candyMove",
    points: 5,
    magic: 5,
  },
  {
    string: "Повысить характеристики персонажа",
    count: 1000,
    property: "addCharac",
    points: 5,
    magic: 5,
  },
];

export const warQuestArray = [
  [
    {
      string: "Одолеть Босса в Бестиарии",
      count: 4000,
      property: "bossesWins",
      points: 10,
      gold: 1000,
    },
    {
      string: "Пройти урок в учебном классе",
      count: 1000,
      property: "classroomsGet",
      points: 10,
      gold: 1000,
    },
    {
      string: "Вырастить Мухомор в Теплице",
      count: 2000,
      property: "grasses0",
      points: 10,
      gold: 1000,
    },
    {
      string: "Сварить зелье здоровья в Лаборатории",
      count: 1000,
      property: "potionsHealthGet",
      points: 10,
      gold: 1000,
    },
    {
      string: "Провести дуэль с волшебником",
      count: 2000,
      property: "arenaFight",
      points: 10,
      gold: 1000,
    },
  ],
  [
    {
      string: "Выбить сундук с Соло-Боссов",
      count: 2000,
      property: "treasuresGet",
      points: 15,
      gold: 1500,
    },
    {
      string: "Получить элементы коллекций на уроках",
      count: 4000,
      property: "collectionsElemGet",
      points: 15,
      gold: 1500,
    },
    {
      string: "Вырастить Прыгающую луковицу в Теплице",
      count: 2000,
      property: "grasses1",
      points: 15,
      gold: 1500,
    },
    {
      string: "Сыграть в кости",
      count: 4000,
      property: "diceGameGames",
      points: 15,
      gold: 1500,
    },
    {
      string: "Сыграть в 21",
      count: 4000,
      property: "game21Games",
      points: 15,
      gold: 1500,
    },
  ],
  [
    {
      string: "Одолеть агрессивного Босса в Бестиарии",
      count: 4000,
      property: "bossesStage1Wins",
      points: 20,
      gold: 2000,
    },
    {
      string: "Вырастить Водоросли в Теплице",
      count: 2500,
      property: "grasses3",
      points: 20,
      gold: 2000,
    },
    {
      string: "Сварить зелье энергии в Лаборатории",
      count: 2500,
      property: "potionsEnergyGet",
      points: 20,
      gold: 2000,
    },

    {
      string: "Отправить драже 'Сюрприз' одногруппникам",
      count: 2500,
      property: "candyMove",
      points: 20,
      gold: 2000,
    },

    {
      string: "Выиграть золото в кости",
      count: 10000,
      property: "diceGameGold",
      points: 20,
      gold: 2000,
    },
  ],
  [
    {
      string: "Одолеть проклятого Босса в Бестиарии",
      count: 4000,
      property: "bossesStage2Wins",
      points: 25,
      gold: 2500,
    },
    {
      string: "Вырастить Мимбулус мимблетония в Теплице",
      count: 4000,
      property: "grasses5",
      points: 25,
      gold: 2500,
    },
    {
      string: "Одержать победу в дуэлях",
      count: 8000,
      property: "arenaWins",
      points: 25,
      gold: 2500,
    },

    {
      string: "Съесть драже 'Сюрприз'",
      count: 8000,
      property: "candyGet",
      points: 25,
      gold: 2500,
    },

    {
      string: "Отправить элементы коллекций одногруппникам",
      count: 8000,
      property: "collectionsMove",
      points: 25,
      gold: 2500,
    },
  ],
  [
    {
      string: "Одолеть лично Босса в Бестиарии",
      count: 4000,
      property: "bossesSoloWins",
      points: 30,
      gold: 3000,
    },
    {
      string: "Пройти урок в учебном классе",
      count: 10000,
      property: "classroomsGet",
      points: 30,
      gold: 3000,
    },
    {
      string: "Вырастить Мандрагору в Теплице",
      count: 10000,
      property: "grasses7",
      points: 30,
      gold: 3000,
    },
    {
      string: "Сварить зелье в Лаборатории",
      count: 10000,
      property: "potionsGet",
      points: 30,
      gold: 3000,
    },
    {
      string: "Выиграть души в 21",
      count: 10000,
      property: "game21Souls",
      points: 30,
      gold: 3000,
    },
  ],
];

export const warPagePrizes = [
  {
    silver: 100000000,
    gold: 10000,
    points: 10,
  },
  {
    silver: 150000000,
    gold: 15000,
    points: 15,
  },
  {
    silver: 200000000,
    gold: 20000,
    points: 20,
  },
  {
    silver: 250000000,
    gold: 25000,
    points: 25,
  },
  {
    silver: 300000000,
    gold: 30000,
    points: 30,
  },
];

export const dayQuestArray = [
  [
    {
      string: "Сыграть в кости",
      count: 1,
      property: "diceGameGames",
    },
    {
      string: "Сыграть в 21",
      count: 1,
      property: "game21Games",
    },
    {
      string: "Отправить элементы коллекций одногруппникам",
      count: 2,
      property: "collectionsMove",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 10,
      property: "arenaWins",
    },
    {
      string: "Одолеть Босса в Бестиарии",
      count: 10,
      property: "bossesWins",
    },
    {
      string: "Израсходовать энергию на уроках",
      count: 200,
      property: "classroomsEnergy",
    },
  ],
  [
    {
      string: "Выиграть в 21",
      count: 3,
      property: "game21Win",
    },
    {
      string: "Собрать коллекцию",
      count: 1,
      property: "collectionsGet",
    },
    {
      string: "Полить растения в Теплице",
      count: 10,
      property: "plantsWater",
      price: 10,
    },
    {
      string: "Сварить зелье в Лаборатории",
      count: 3,
      property: "potionsGet",
      price: 10,
    },
    {
      string: "Пройти урок в учебном классе",
      count: 1,
      property: "classroomsGet",
    },
    {
      string: "Одолеть лично Босса в Бестиарии",
      count: 1,
      property: "bossesSoloWins",
    },
  ],
  [
    {
      string: "Собрать комбинацию в 21",
      count: 1,
      property: "game21Combinations",
      price: 15,
    },
    {
      string: "Выиграть золото в кости",
      count: 20,
      property: "diceGameGold",
      price: 15,
    },
    {
      string: "Собрать коллекцию",
      count: 3,
      property: "collectionsGet",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 40,
      property: "arenaWins",
      price: 25,
    },
    {
      string: "Одолеть Босса в Бестиарии",
      count: 40,
      property: "bossesWins",
    },
    {
      string: "Одолеть соло Босса на локации",
      count: 4,
      property: "soloBossesWins",
    },
  ],
  [
    {
      string: "Сварить зелье в Лаборатории",
      count: 20,
      property: "potionsGet",
      price: 40,
    },
    {
      string: "Посадить растения в Теплице",
      count: 30,
      property: "plantsSet",
      price: 40,
    },
    {
      string: "Одолеть лично Босса в Бестиарии",
      count: 30,
      property: "bossesSoloWins",
    },
    {
      string: "Израсходовать энергию на уроках",
      count: 10000,
      property: "classroomsEnergy",
    },
    {
      string: "Отправить драже 'Сюрприз' одногруппникам",
      count: 5,
      property: "candyMove",
    },
    {
      string: "Съесть драже 'Сюрприз'",
      count: 5,
      property: "candyGet",
      price: 25,
    },
  ],
  [
    {
      string: "Собрать комбинацию в 21",
      count: 8,
      property: "game21Combinations",
      price: 100,
    },
    {
      string: "Одержать победу в дуэлях",
      count: 80,
      property: "arenaWins",
      price: 100,
    },
    {
      string: "Сбросить лимит в Бестиарии",
      count: 1,
      property: "bossesLimit",
    },
    {
      string: "Израсходовать ману",
      count: 80000,
      property: "mana",
    },
    {
      string: "Одолеть Босса в Бестиарии",
      count: 80,
      property: "bossesWins",
    },
    {
      string: "Одолеть Соло-Босса",
      count: 20,
      property: "soloBossesWins",
    },
  ],
];

export const dayPrizes = [
  [
    {
      property: "silver",
      count: 1000,
    },
    {
      property: "mana",
      count: 500,
    },
  ],
  [
    {
      property: "gold",
      count: 20,
    },
    {
      property: "mana",
      count: 1000,
    },
  ],
  [
    {
      property: "silver",
      count: 3000,
    },
    {
      property: "diceGame",
      count: 30,
    },
  ],
  [
    {
      property: "soul",
      number: 3,
      count: 3,
    },
    {
      property: "game21",
      count: 30,
    },
  ],
  [
    {
      property: "silver",
      count: 6000,
    },
    {
      property: "mana",
      count: 3000,
    },
  ],
  [
    {
      property: "gold",
      count: 60,
    },
    {
      property: "soul",
      number: 4,
      count: 3,
    },
  ],
  [
    {
      property: "gold",
      count: 100,
    },
    {
      property: "magic",
      count: 5,
    },
  ],
];

export const spellsArray = {
  entity: {
    number: 4,
    name: "Реддукто",
    string:
      "Из палочки заклинателя вырывается красный луч, взрывающий твердый объект.",
    mana: 80,
    krit: 2,
    deffence: 1,
    damage: 1,
    raidDamage: 1,
    img: imgSpellEntity,
    imgBoss: imgSpellEntityBoss,
  },
  man: {
    number: 13,
    name: "Сектумсентра",
    string:
      "Заклинание, которое, будто мечом, разит противника, причиняя ему острую боль.",
    mana: 80,
    krit: 2,
    deffence: 1,
    damage: 1,
    raidDamage: 1,
    img: imgSpellMan,
    imgBoss: imgSpellManBoss,
  },
  ghost: {
    number: 13,
    name: "Эспекто Натронум",
    string:
      "Заклинание, вызывающее охранный магический дух, защищающий от врагов.",
    mana: 80,
    krit: 2,
    deffence: 1,
    damage: 1,
    raidDamage: 1,
    img: imgSpellGhost,
    imgBoss: imgSpellGhostBoss,
  },
};

export const treasuresArray = [
  [
    {
      property: "gold",
      count: 70,
    },
    {
      property: "gold",
      count: 25,
    },
    {
      property: "gold",
      count: 10,
    },
    {
      property: "gold",
      count: 5,
    },
    {
      property: "soul",
      number: 5,
      count: 1,
    },
    {
      property: "soul",
      number: 4,
      count: 1,
    },
    {
      property: "soul",
      number: 3,
      count: 1,
    },
    {
      property: "soul",
      number: 2,
      count: 1,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 1,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 1,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 2,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 2,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 2,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 3,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 5,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 5,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 10,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 15,
    },
    {
      property: "game21",
      count: 5,
    },
    {
      property: "diceGame",
      count: 10,
    },
  ],

  [
    {
      property: "gold",
      count: 150,
    },
    {
      property: "gold",
      count: 50,
    },
    {
      property: "gold",
      count: 30,
    },
    {
      property: "gold",
      count: 20,
    },
    {
      property: "soul2",
      number: 1,
      count: 1,
    },
    {
      property: "soul",
      number: 6,
      count: 1,
    },
    {
      property: "soul",
      number: 5,
      count: 1,
    },
    {
      property: "soul",
      number: 4,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 2,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 4,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 6,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 2,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 4,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 6,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 4,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 6,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 10,
    },

    {
      property: "game21",
      count: 15,
    },

    {
      property: "diceGame",
      count: 30,
    },
  ],

  [
    {
      property: "gold",
      count: 200,
    },
    {
      property: "gold",
      count: 80,
    },
    {
      property: "gold",
      count: 50,
    },
    {
      property: "gold",
      count: 30,
    },
    {
      property: "soul2",
      number: 2,
      count: 1,
    },
    {
      property: "soul2",
      number: 1,
      count: 1,
    },
    {
      property: "soul",
      number: 6,
      count: 1,
    },
    {
      property: "soul",
      number: 5,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 3,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 5,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 8,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 3,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 5,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 8,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 5,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 10,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 15,
    },

    {
      property: "game21",
      count: 25,
    },

    {
      property: "diceGame",
      count: 50,
    },
  ],
];

export const bossesStages = ["Обычный", "Агрессивный", "Проклятый"];

export const eventBossArray = [
  {
    lvlNeed: 5,
    postImage:
      "https://sun9-8.userapi.com/impg/46rYJ-PwFzIRdshzuGUN0m4BlkHo9CDBwfpvpw/ooDJyX8IFdw.jpg?size=1080x1920&quality=95&sign=8773c574338f9db51de8e849b85fff92&type=album",
    classType: "entity",
    name: "Порождения холода",
    attack: 15,
    allHp: 2500,
    currentHp: 2500,
    rewards: {
      exp: 200,
      silver: 2000,
      gold: 20,
    },
    time: {
      maxTime: 1,
    },
    bossKritChance: 7,
    bossEvasionChance: 5,
    string:
      "Ужасного вида снеговики с жуткими язвами на туловище используют тёмную магию холода и забирают тепло и свет у всего живого вокруг. Они устойчивы ко многим известным заклинаниям и держатся вместе.",
    text: [
      "Известно, что чудовища - это дело рук Верховного мага холода, которые решил окунуть замок в атмосферу мрака и лишить нас лета.",
    ],
  },
];

export const raidBossesArray = [
  {
    classType: "entity",
    bossKritChance: 22,
    bossEvasionChance: 8,
    photo:
      "https://sun9-35.userapi.com/impg/jQtjQCeyX1x9z8MCbiFhfdcUTE7NjD3Gkiq2Pg/gSl-Px0ydMY.jpg?size=1080x1920&quality=95&sign=31d50d8d01b35466137371473e4eec9b&type=album",
    name: "Тролль",
    text: [
      "Огромный и свирепый тролль напал на ученика! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Пугающе большое существо, издалека напоминающее груду камней. Однако вблизи - это неповоротливый, но грозный соперник, который одним ударом своего кулака сможет разнести все на пути.",
  },
  {
    classType: "entity",
    bossKritChance: 20,
    bossEvasionChance: 10,
    photo:
      "https://sun9-28.userapi.com/impg/dRmvlZdK8V7F7bFGRU69-3HfI3_pBlBjJGojXA/fcfgAxTK6Lk.jpg?size=1080x1920&quality=95&sign=7dd6485d93e8f54feb883e39ee532e47&type=album",
    name: "Сточные крысы",
    text: [
      "Сточные крысы заполонили подземелье! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Группа маленьких, хитрых и выносливых грызунов, которые обитают в запущенных и практически заброшенных уголках замка. Быстро передвигаются по водосточным трубам и не всегда дружелюбны к студентам.",
  },
  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo:
      "https://sun9-10.userapi.com/impg/tO1bQoCQ9AXZY8aPrwUbZriKP5xmEXn8rFJdbA/XVCRAUzgFyE.jpg?size=1080x1920&quality=95&sign=940d89d90bcf02599676865436b0e871&type=album",
    name: "Владыка холода",
    text: [
      "Школа в ледяном плену Владыки холода! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Владыка холода, чья власть надо льдом и снегом превращает его в воплощение  великой силы и чародейства. Он готов распрощаться с любой своей жертвой с помощью хладнокровности, присущей ему буквально.",
  },
  {
    classType: "ghost",
    bossKritChance: 8,
    bossEvasionChance: 22,
    photo:
      "https://sun9-49.userapi.com/impg/gAQUy_HqNcRkQrdtZGmEm_J9oYNtJDIiUeDL7Q/zgD21Wg1u3E.jpg?size=1080x1920&quality=95&sign=336baf1e9dc94a76f6bdcc56c198db32&type=album",
    name: "Банши",
    text: [
      "Школу потряс леденящий вой Банши! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Костлявая ведьма-привидение с длинными, до пола, чёрными волосами и зелёным лицом, вестница смерти. Обладает пронзительным, очень громким криком. Старается заставить человека кричать от ужаса, ведь при этом условии она сможет нанести ему вред.",
  },
  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo:
      "https://sun9-58.userapi.com/impg/yXE5ve-t_maa_ITnXzNXe9RqNam5Lywn5IjexA/D9xo5w3_iLk.jpg?size=1080x1920&quality=95&sign=08647012d8283302366c37b783a61e6a&type=album",
    name: "Инспектор",
    text: [
      "Генеральный инспектор перешел уже все границы, пора с этим покончить!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Пухлая женщина в розовых одеждах с прескверным характером. Является генеральным инспектором Школы. Выдумывает жесткие законы, несогласных ужасно наказывает, вплоть до пыток в своем кабинете.",
  },
  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo:
      "https://sun9-68.userapi.com/impg/RsA0CGVKsyINamBC2Mkg8ZFDEOYA_2DY_i_Gxg/mPMNtAlCcWM.jpg?size=1080x1920&quality=95&sign=2a88ebaa8ae796834eb08a85a4f80919&type=album",
    name: "Двуликий",
    text: [
      "Студенты застали профессора за жутким и неествественным занятием!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Будучи весьма молодым, но уже способным волшебником, будущий преподаватель отправился в кругосветное путешествие, чтобы доказать всему магическому сообществу свое мастерство. Однако нечто, произошедшее там, круто изменило судьбу амбициозного мага!",
  },
  {
    classType: "ghost",
    bossKritChance: 10,
    bossEvasionChance: 20,
    photo:
      "https://sun9-45.userapi.com/impg/ppwkgwEKMRGg7GdQIxOeIq39EEmzNwi1sHaCAw/QlUusSMsy10.jpg?size=1080x1920&quality=95&sign=61b8493c83339e6c641f63ef8759d994&type=album",
    name: "Мерзкие головы",
    text: [
      "Злоба Мерзких голов прольётся на всех! Встань на защиту Школы от опасного полтергейста!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой!",
    ],
    string:
      "Алчность, жадкость и зависть... Именно это способно погубить даже самую могущественную группировку Тёмных сил. Древнее проклятие слило головы мерзких магов в одно целое. Не в силах больше терпеть друг друга, огромный фантом стал источать огромную энергию ярости и злобы.",
  },
];

export const shytArray = [
  [
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-59.userapi.com/impg/74Oq9EPNrHQfpnUT6Y5s7SfFEQ0nIqtgr8Uo0Q/zMmHEAhxS_o.jpg?size=1080x1920&quality=95&sign=2f9d68c24c498ec257ab69dd3f02ef12&type=album",
      classType: "random",
      name: "Шут",
      attack: 30,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 1.25,
      rewards: {
        exp: 200,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-59.userapi.com/impg/74Oq9EPNrHQfpnUT6Y5s7SfFEQ0nIqtgr8Uo0Q/zMmHEAhxS_o.jpg?size=1080x1920&quality=95&sign=2f9d68c24c498ec257ab69dd3f02ef12&type=album",
      classType: "random",
      name: "Шут",
      attack: 40,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 2,
      rewards: {
        exp: 250,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-59.userapi.com/impg/74Oq9EPNrHQfpnUT6Y5s7SfFEQ0nIqtgr8Uo0Q/zMmHEAhxS_o.jpg?size=1080x1920&quality=95&sign=2f9d68c24c498ec257ab69dd3f02ef12&type=album",
      classType: "random",
      name: "Шут",
      attack: 55,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 3,
      rewards: {
        exp: 350,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
  ],
];

export const soloBossArray = [
  [
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-10.userapi.com/impg/oe35LzVEeHWLj_n9ABV83TWZjIEIXpwzseZo2g/zTw7p3JScn4.jpg?size=1080x1920&quality=95&sign=5e816b5ed05e2e01b5a20d4a1e03c98e&type=album",
      classType: "man",
      name: "Задиры",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 4,
      bossEvasionChance: 4,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-10.userapi.com/impg/oe35LzVEeHWLj_n9ABV83TWZjIEIXpwzseZo2g/zTw7p3JScn4.jpg?size=1080x1920&quality=95&sign=5e816b5ed05e2e01b5a20d4a1e03c98e&type=album",
      classType: "man",
      name: "Задиры",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 5,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-10.userapi.com/impg/oe35LzVEeHWLj_n9ABV83TWZjIEIXpwzseZo2g/zTw7p3JScn4.jpg?size=1080x1920&quality=95&sign=5e816b5ed05e2e01b5a20d4a1e03c98e&type=album",
      classType: "man",
      name: "Задиры",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 7,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
  ],
  [
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-4.userapi.com/impg/6MJDBMg4lQTsHLsSpxYqlGt_LmK5T5o_iH5V7g/ljVeZ6oMYSg.jpg?size=1080x1920&quality=95&sign=955b0b0b7a75dabdd104b2ba0aefdd1f&type=album",
      classType: "ghost",
      name: "Сумрак",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 5,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-4.userapi.com/impg/6MJDBMg4lQTsHLsSpxYqlGt_LmK5T5o_iH5V7g/ljVeZ6oMYSg.jpg?size=1080x1920&quality=95&sign=955b0b0b7a75dabdd104b2ba0aefdd1f&type=album",
      classType: "ghost",
      name: "Сумрак",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 7,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-4.userapi.com/impg/6MJDBMg4lQTsHLsSpxYqlGt_LmK5T5o_iH5V7g/ljVeZ6oMYSg.jpg?size=1080x1920&quality=95&sign=955b0b0b7a75dabdd104b2ba0aefdd1f&type=album",
      classType: "ghost",
      name: "Сумрак",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 11,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
  ],
  [
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-19.userapi.com/impg/vsWfhSai0T05gwGYI-Lb6e03C0PKueLzRM3_vA/wP_npyr-090.jpg?size=1080x1920&quality=95&sign=efb1d822b3bd9c99cc26beb287228e71&type=album",
      classType: "entity",
      name: "Биомасса",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-19.userapi.com/impg/vsWfhSai0T05gwGYI-Lb6e03C0PKueLzRM3_vA/wP_npyr-090.jpg?size=1080x1920&quality=95&sign=efb1d822b3bd9c99cc26beb287228e71&type=album",
      classType: "entity",
      name: "Биомасса",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
    {
      lvlNeed: 10,
      postImage:
        "https://sun9-19.userapi.com/impg/vsWfhSai0T05gwGYI-Lb6e03C0PKueLzRM3_vA/wP_npyr-090.jpg?size=1080x1920&quality=95&sign=efb1d822b3bd9c99cc26beb287228e71&type=album",
      classType: "entity",
      name: "Биомасса",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 11,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
  ],

  [
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-70.userapi.com/impg/cIs1mSijemNgMtC7OLW9qTwNsSOPlTUxJyKeyQ/7RHZTESHnMg.jpg?size=1080x1920&quality=95&sign=9390c9000a95010d2c30866693e3a313&type=album",
      classType: "entity",
      name: "Демон канализации",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 9,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-70.userapi.com/impg/cIs1mSijemNgMtC7OLW9qTwNsSOPlTUxJyKeyQ/7RHZTESHnMg.jpg?size=1080x1920&quality=95&sign=9390c9000a95010d2c30866693e3a313&type=album",
      classType: "entity",
      name: "Демон канализации",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 11,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-70.userapi.com/impg/cIs1mSijemNgMtC7OLW9qTwNsSOPlTUxJyKeyQ/7RHZTESHnMg.jpg?size=1080x1920&quality=95&sign=9390c9000a95010d2c30866693e3a313&type=album",
      classType: "entity",
      name: "Демон канализации",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 15,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
  ],
  [
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-6.userapi.com/impg/He7LCrH2YgYnGXSjJnD_BGnp3WYhghvcA0stzA/VLS8o4SiQtI.jpg?size=1080x1920&quality=95&sign=050ec4a9e4138e6882061a2a30918827&type=album",
      classType: "man",
      name: "Златоуст",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 7,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-6.userapi.com/impg/He7LCrH2YgYnGXSjJnD_BGnp3WYhghvcA0stzA/VLS8o4SiQtI.jpg?size=1080x1920&quality=95&sign=050ec4a9e4138e6882061a2a30918827&type=album",
      classType: "man",
      name: "Златоуст",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 8,
      bossEvasionChance: 8,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-6.userapi.com/impg/He7LCrH2YgYnGXSjJnD_BGnp3WYhghvcA0stzA/VLS8o4SiQtI.jpg?size=1080x1920&quality=95&sign=050ec4a9e4138e6882061a2a30918827&type=album",
      classType: "man",
      name: "Златоуст",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 10,
      bossEvasionChance: 10,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
  ],
  [
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-27.userapi.com/impg/TFvdErjgJNmlJTrf6dN7tJFIlAtQu1p532u7Bw/zlle4EeNqWI.jpg?size=1080x1920&quality=95&sign=2d9383b16dd044d175cf7e7cbcc42191&type=album",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 9,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-27.userapi.com/impg/TFvdErjgJNmlJTrf6dN7tJFIlAtQu1p532u7Bw/zlle4EeNqWI.jpg?size=1080x1920&quality=95&sign=2d9383b16dd044d175cf7e7cbcc42191&type=album",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 11,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
    {
      lvlNeed: 40,
      postImage:
        "https://sun9-27.userapi.com/impg/TFvdErjgJNmlJTrf6dN7tJFIlAtQu1p532u7Bw/zlle4EeNqWI.jpg?size=1080x1920&quality=95&sign=2d9383b16dd044d175cf7e7cbcc42191&type=album",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 15,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
  ],

  [
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-71.userapi.com/impg/K6d9BlVXCdcDQmbB2blbqghmMl8DMDlSv7wybQ/ytIYKB5npds.jpg?size=1080x1920&quality=95&sign=0d8b7da97d736b2404e189868209e3b4&type=album",
      classType: "man",
      name: "Драко",
      attack: 40,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 225,
        silver: 15000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.25,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 10,
      bossEvasionChance: 10,
      string:
        "Драко Мальфой стоит в полумраке Тайной Комнаты с ледяными глазами, словно острыми лезвиями, готовыми разорвать тишину. Холодная аура, исходящая от него, заставляет даже самых смелых волшебников испытывать необъяснимый страх.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-71.userapi.com/impg/K6d9BlVXCdcDQmbB2blbqghmMl8DMDlSv7wybQ/ytIYKB5npds.jpg?size=1080x1920&quality=95&sign=0d8b7da97d736b2404e189868209e3b4&type=album",
      classType: "man",
      name: "Драко",
      attack: 60,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 350,
        silver: 80000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.4,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 12,
      bossEvasionChance: 12,
      string:
        "Драко Мальфой стоит в полумраке Тайной Комнаты с ледяными глазами, словно острыми лезвиями, готовыми разорвать тишину. Холодная аура, исходящая от него, заставляет даже самых смелых волшебников испытывать необъяснимый страх.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-71.userapi.com/impg/K6d9BlVXCdcDQmbB2blbqghmMl8DMDlSv7wybQ/ytIYKB5npds.jpg?size=1080x1920&quality=95&sign=0d8b7da97d736b2404e189868209e3b4&type=album",
      classType: "man",
      name: "Драко",
      attack: 80,
      allHp: 750000,
      currentHp: 750000,
      rewards: {
        exp: 450,
        silver: 420000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.6,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 15,
      bossEvasionChance: 15,
      string:
        "Драко Мальфой стоит в полумраке Тайной Комнаты с ледяными глазами, словно острыми лезвиями, готовыми разорвать тишину. Холодная аура, исходящая от него, заставляет даже самых смелых волшебников испытывать необъяснимый страх.",
    },
  ],
  [
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-30.userapi.com/impg/RMzXTyJiQkeJthFd-KUC6v99c0tlSPBz-HcLmg/kNwuqmx7p0E.jpg?size=1080x1920&quality=95&sign=152e491b515179ab1b834ee1a5c0d19b&type=album",
      classType: "ghost",
      name: "Сумасшедший учёный",
      attack: 40,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 225,
        silver: 15000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.25,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 13,
      string:
        "Призрак безумного ученого в облике рептилии стоит перед котлами с бурлящей жидкостью. Он использует плоть волшебников для создания заклинаний. Ужаснувшиеся студенты осознают, что могут стать жертвами его экспериментов.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-30.userapi.com/impg/RMzXTyJiQkeJthFd-KUC6v99c0tlSPBz-HcLmg/kNwuqmx7p0E.jpg?size=1080x1920&quality=95&sign=152e491b515179ab1b834ee1a5c0d19b&type=album",
      classType: "ghost",
      name: "Сумасшедший учёный",
      attack: 60,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 350,
        silver: 80000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.4,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 17,
      string:
        "Призрак безумного ученого в облике рептилии стоит перед котлами с бурлящей жидкостью. Он использует плоть волшебников для создания заклинаний. Ужаснувшиеся студенты осознают, что могут стать жертвами его экспериментов.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-30.userapi.com/impg/RMzXTyJiQkeJthFd-KUC6v99c0tlSPBz-HcLmg/kNwuqmx7p0E.jpg?size=1080x1920&quality=95&sign=152e491b515179ab1b834ee1a5c0d19b&type=album",
      classType: "ghost",
      name: "Сумасшедший учёный",
      attack: 80,
      allHp: 750000,
      currentHp: 750000,
      rewards: {
        exp: 450,
        silver: 420000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.6,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 23,
      string:
        "Призрак безумного ученого в облике рептилии стоит перед котлами с бурлящей жидкостью. Он использует плоть волшебников для создания заклинаний. Ужаснувшиеся студенты осознают, что могут стать жертвами его экспериментов.",
    },
  ],
  [
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-69.userapi.com/impg/QleTQ_mmEPSK5nMj-ntJhjsGegazKNji84Sd5g/wwqrGVFoPeM.jpg?size=1080x1920&quality=95&sign=b4673c0f16809cee3adeacba569f0646&type=album",
      classType: "entity",
      name: "Трёхглавый пёс",
      attack: 40,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 225,
        silver: 15000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.25,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 13,
      bossEvasionChance: 7,
      string:
        "Собака с тремя головами, с её могучими лапами и сверкающими клыками, охраняет запретный проход, словно живая гора из шерсти и ярости. Каждая из её голов готова в любой момент броситься на незваного гостя, защищая тайны, скрытые под ней.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-69.userapi.com/impg/QleTQ_mmEPSK5nMj-ntJhjsGegazKNji84Sd5g/wwqrGVFoPeM.jpg?size=1080x1920&quality=95&sign=b4673c0f16809cee3adeacba569f0646&type=album",
      classType: "entity",
      name: "Трёхглавый пёс",
      attack: 60,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 350,
        silver: 80000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.4,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 17,
      bossEvasionChance: 7,
      string:
        "Собака с тремя головами, с её могучими лапами и сверкающими клыками, охраняет запретный проход, словно живая гора из шерсти и ярости. Каждая из её голов готова в любой момент броситься на незваного гостя, защищая тайны, скрытые под ней.",
    },
    {
      lvlNeed: 60,
      postImage:
        "https://sun9-69.userapi.com/impg/QleTQ_mmEPSK5nMj-ntJhjsGegazKNji84Sd5g/wwqrGVFoPeM.jpg?size=1080x1920&quality=95&sign=b4673c0f16809cee3adeacba569f0646&type=album",
      classType: "entity",
      name: "Трёхглавый пёс",
      attack: 80,
      allHp: 750000,
      currentHp: 750000,
      rewards: {
        exp: 450,
        silver: 420000,
        lvl: 0,
        treasure: {
          lvl: 2,
          chance: 0.6,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 23,
      bossEvasionChance: 7,
      string:
        "Собака с тремя головами, с её могучими лапами и сверкающими клыками, охраняет запретный проход, словно живая гора из шерсти и ярости. Каждая из её голов готова в любой момент броситься на незваного гостя, защищая тайны, скрытые под ней.",
    },
  ],
];

export const bossesArray = [
  [
    {
      postImage:
        "https://sun9-69.userapi.com/impg/qXYv1rlzuAhH0VxmJy3Rz3XR-8f5HTq6nSC6dA/3hb1mAALJ2w.jpg?size=1080x1920&quality=95&sign=655ac338559baf06ef0a379b5a8e59a7&type=album",
      classType: "entity",
      name: "Пикси",
      attack: 50,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 100,
        silver: 200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 6,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
    {
      postImage:
        "https://sun9-69.userapi.com/impg/qXYv1rlzuAhH0VxmJy3Rz3XR-8f5HTq6nSC6dA/3hb1mAALJ2w.jpg?size=1080x1920&quality=95&sign=655ac338559baf06ef0a379b5a8e59a7&type=album",
      classType: "entity",
      name: "Пикси",
      attack: 100,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 300,
        silver: 600,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 8,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
    {
      postImage:
        "https://sun9-69.userapi.com/impg/qXYv1rlzuAhH0VxmJy3Rz3XR-8f5HTq6nSC6dA/3hb1mAALJ2w.jpg?size=1080x1920&quality=95&sign=655ac338559baf06ef0a379b5a8e59a7&type=album",
      classType: "entity",
      name: "Пикси",
      attack: 200,
      allHp: 15000,
      currentHp: 15000,
      rewards: {
        exp: 800,
        silver: 1600,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 12,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-55.userapi.com/impg/p_9xQrRHdYUx6fI6mRV2GWfLYPZDSICwNBlCkQ/7K1XSfEM6Ls.jpg?size=1080x1920&quality=95&sign=6bf6be021b1c86a381dbac1f3612f908&type=album",
      classType: "ghost",
      name: "Полтергейст",
      attack: 100,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 250,
        silver: 500,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 7,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
    {
      postImage:
        "https://sun9-55.userapi.com/impg/p_9xQrRHdYUx6fI6mRV2GWfLYPZDSICwNBlCkQ/7K1XSfEM6Ls.jpg?size=1080x1920&quality=95&sign=6bf6be021b1c86a381dbac1f3612f908&type=album",
      classType: "ghost",
      name: "Полтергейст",
      attack: 200,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 750,
        silver: 1500,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 10,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
    {
      postImage:
        "https://sun9-55.userapi.com/impg/p_9xQrRHdYUx6fI6mRV2GWfLYPZDSICwNBlCkQ/7K1XSfEM6Ls.jpg?size=1080x1920&quality=95&sign=6bf6be021b1c86a381dbac1f3612f908&type=album",
      classType: "ghost",
      name: "Полтергейст",
      attack: 400,
      allHp: 75000,
      currentHp: 75000,
      rewards: {
        exp: 2000,
        silver: 4000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 13,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-78.userapi.com/impg/GK_ceoYXQx3yOtncdplYqmKkrmO1s8bYY539NQ/3LpTemPuQlU.jpg?size=1080x1920&quality=95&sign=7416850dd02f408bbede71e4acf2e7bc&type=album",
      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 200,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 500,
        silver: 1000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 8,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
    {
      postImage:
        "https://sun9-78.userapi.com/impg/GK_ceoYXQx3yOtncdplYqmKkrmO1s8bYY539NQ/3LpTemPuQlU.jpg?size=1080x1920&quality=95&sign=7416850dd02f408bbede71e4acf2e7bc&type=album",

      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 400,
      allHp: 125000,
      currentHp: 125000,
      rewards: {
        exp: 1500,
        silver: 3000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 12,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
    {
      postImage:
        "https://sun9-78.userapi.com/impg/GK_ceoYXQx3yOtncdplYqmKkrmO1s8bYY539NQ/3LpTemPuQlU.jpg?size=1080x1920&quality=95&sign=7416850dd02f408bbede71e4acf2e7bc&type=album",

      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 800,
      allHp: 375000,
      currentHp: 375000,
      rewards: {
        exp: 4000,
        silver: 8000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 16,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-73.userapi.com/impg/hWbcPMRBCiaJPNJZSyxXulRRcRkgkSm2g02asw/VADwen-jbwU.jpg?size=1080x1920&quality=95&sign=56cd5ffd25c89efac0e67b087fa43d53&type=album",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 500,
      allHp: 100000,
      currentHp: 100000,
      rewards: {
        exp: 1200,
        silver: 2400,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 8,
      bossEvasionChance: 8,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
    {
      postImage:
        "https://sun9-73.userapi.com/impg/hWbcPMRBCiaJPNJZSyxXulRRcRkgkSm2g02asw/VADwen-jbwU.jpg?size=1080x1920&quality=95&sign=56cd5ffd25c89efac0e67b087fa43d53&type=album",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 1000,
      allHp: 500000,
      currentHp: 500000,
      rewards: {
        exp: 3600,
        silver: 7200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 13,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
    {
      postImage:
        "https://sun9-73.userapi.com/impg/hWbcPMRBCiaJPNJZSyxXulRRcRkgkSm2g02asw/VADwen-jbwU.jpg?size=1080x1920&quality=95&sign=56cd5ffd25c89efac0e67b087fa43d53&type=album",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 2000,
      allHp: 1500000,
      currentHp: 1500000,
      rewards: {
        exp: 9600,
        silver: 19200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 18,
      bossEvasionChance: 18,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-24.userapi.com/impg/PwaKhaVnKzlnRPbtHS7FaFNZXGe7fPWgE2WtBQ/yF7YGYWhfTg.jpg?size=1080x1920&quality=95&sign=926cbb038b6217e8600f01478ee8757b&type=album",
      classType: "entity",
      name: "Кошмар",
      attack: 1000,
      allHp: 500000,
      currentHp: 500000,
      rewards: {
        exp: 3000,
        silver: 6000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 11,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage:
        "https://sun9-24.userapi.com/impg/PwaKhaVnKzlnRPbtHS7FaFNZXGe7fPWgE2WtBQ/yF7YGYWhfTg.jpg?size=1080x1920&quality=95&sign=926cbb038b6217e8600f01478ee8757b&type=album",
      classType: "entity",
      name: "Кошмар",
      attack: 2000,
      allHp: 2500000,
      currentHp: 2500000,
      rewards: {
        exp: 9000,
        silver: 18000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 17,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage:
        "https://sun9-24.userapi.com/impg/PwaKhaVnKzlnRPbtHS7FaFNZXGe7fPWgE2WtBQ/yF7YGYWhfTg.jpg?size=1080x1920&quality=95&sign=926cbb038b6217e8600f01478ee8757b&type=album",
      classType: "entity",
      name: "Кошмар",
      attack: 4000,
      allHp: 7500000,
      currentHp: 7500000,
      rewards: {
        exp: 24000,
        silver: 48000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 23,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-59.userapi.com/impg/yq1v-yMpWwGpKwqHS_V9AZdEpoJVVtiENQqfiQ/1vkR_6xAijU.jpg?size=1080x1920&quality=95&sign=0c8c666d6ac516cb206f9b75b153fa3f&type=album",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 2000,
      allHp: 2000000,
      currentHp: 2000000,
      rewards: {
        exp: 8000,
        silver: 16000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 12,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
    {
      postImage:
        "https://sun9-59.userapi.com/impg/yq1v-yMpWwGpKwqHS_V9AZdEpoJVVtiENQqfiQ/1vkR_6xAijU.jpg?size=1080x1920&quality=95&sign=0c8c666d6ac516cb206f9b75b153fa3f&type=album",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 4000,
      allHp: 10000000,
      currentHp: 10000000,
      rewards: {
        exp: 24000,
        silver: 48000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 19,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },

    {
      postImage:
        "https://sun9-59.userapi.com/impg/yq1v-yMpWwGpKwqHS_V9AZdEpoJVVtiENQqfiQ/1vkR_6xAijU.jpg?size=1080x1920&quality=95&sign=0c8c666d6ac516cb206f9b75b153fa3f&type=album",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 8000,
      allHp: 30000000,
      currentHp: 30000000,
      rewards: {
        exp: 64000,
        silver: 128000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 26,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
  ],
  [
    {
      postImage:
        "https://sun9-34.userapi.com/impg/-k9GAgWsIv9omrk5jEmB7661TRz111aJpmEPlg/qbmPPnvyzH0.jpg?size=1080x1920&quality=95&sign=ffe65c325ebf6d464090c5c1c10bed3b&type=album",
      classType: "entity",
      name: "Обскур",
      attack: 4000,
      allHp: 10000000,
      currentHp: 10000000,
      rewards: {
        exp: 18000,
        silver: 36000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage:
        "https://sun9-34.userapi.com/impg/-k9GAgWsIv9omrk5jEmB7661TRz111aJpmEPlg/qbmPPnvyzH0.jpg?size=1080x1920&quality=95&sign=ffe65c325ebf6d464090c5c1c10bed3b&type=album",
      classType: "entity",
      name: "Обскур",
      attack: 8000,
      allHp: 50000000,
      currentHp: 50000000,
      rewards: {
        exp: 54000,
        silver: 108000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 21,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage:
        "https://sun9-34.userapi.com/impg/-k9GAgWsIv9omrk5jEmB7661TRz111aJpmEPlg/qbmPPnvyzH0.jpg?size=1080x1920&quality=95&sign=ffe65c325ebf6d464090c5c1c10bed3b&type=album",
      classType: "entity",
      name: "Обскур",
      attack: 16000,
      allHp: 150000000,
      currentHp: 150000000,
      rewards: {
        exp: 144000,
        silver: 288000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 29,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-18.userapi.com/impg/tdTAAyoLZ0H-iWrY6XtAtsNP5Cak9VTwZP7-Gg/rqxE-tiyoPs.jpg?size=1080x1920&quality=95&sign=719a693312271a1ee755560cee1c2c7a&type=album",
      classType: "man",
      name: "Лже-маг",
      attack: 8000,
      allHp: 50000000,
      currentHp: 50000000,
      rewards: {
        exp: 50000,
        silver: 100000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 13,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
    {
      postImage:
        "https://sun9-18.userapi.com/impg/tdTAAyoLZ0H-iWrY6XtAtsNP5Cak9VTwZP7-Gg/rqxE-tiyoPs.jpg?size=1080x1920&quality=95&sign=719a693312271a1ee755560cee1c2c7a&type=album",
      classType: "man",
      name: "Лже-маг",
      attack: 16000,
      allHp: 250000000,
      currentHp: 250000000,
      rewards: {
        exp: 150000,
        silver: 300000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 22,
      bossEvasionChance: 22,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
    {
      postImage:
        "https://sun9-18.userapi.com/impg/tdTAAyoLZ0H-iWrY6XtAtsNP5Cak9VTwZP7-Gg/rqxE-tiyoPs.jpg?size=1080x1920&quality=95&sign=719a693312271a1ee755560cee1c2c7a&type=album",
      classType: "man",
      name: "Лже-маг",
      attack: 32000,
      allHp: 750000000,
      currentHp: 750000000,
      rewards: {
        exp: 400000,
        silver: 800000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 31,
      bossEvasionChance: 31,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
  ],
];

export const bosses2Array = [
  [
    {
      postImage:
        "https://sun9-73.userapi.com/impg/IXvko7uC3hYBZCpCGT4-cjG2uZ-m3apAlUp23w/QV1z7AxCxkY.jpg?size=1080x1920&quality=95&sign=d664f0f5c6f624c1164d280b51218f32&type=album",
      classType: "man",
      name: "Корреспондент",
      attack: 10000,
      allHp: 100000000,
      currentHp: 100000000,
      rewards: {
        exp: 100000,
        silver: 200000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 5,
      bossEvasionChance: 5,
      string:
        "Её острое перо проткнуло немало раздутых репутаций... Некогда хитрый и привлекательный корреспондент связалась с представителями Тёмных сил. Волшебники обнаружили статьи обезумевшей журналистки с описаниями её собственных преступлений!",
    },
    {
      postImage:
        "https://sun9-73.userapi.com/impg/IXvko7uC3hYBZCpCGT4-cjG2uZ-m3apAlUp23w/QV1z7AxCxkY.jpg?size=1080x1920&quality=95&sign=d664f0f5c6f624c1164d280b51218f32&type=album",
      classType: "man",
      name: "Корреспондент",
      attack: 20000,
      allHp: 500000000,
      currentHp: 500000000,
      rewards: {
        exp: 300000,
        silver: 600000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 6,
      bossEvasionChance: 6,
      string:
        "Её острое перо проткнуло немало раздутых репутаций... Некогда хитрый и привлекательный корреспондент связалась с представителями Тёмных сил. Волшебники обнаружили статьи обезумевшей журналистки с описаниями её собственных преступлений!",
    },
    {
      postImage:
        "https://sun9-73.userapi.com/impg/IXvko7uC3hYBZCpCGT4-cjG2uZ-m3apAlUp23w/QV1z7AxCxkY.jpg?size=1080x1920&quality=95&sign=d664f0f5c6f624c1164d280b51218f32&type=album",
      classType: "man",
      name: "Корреспондент",
      attack: 40000,
      allHp: 1500000000,
      currentHp: 1500000000,
      rewards: {
        exp: 800000,
        silver: 1600000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 8,
      bossEvasionChance: 8,
      string:
        "Её острое перо проткнуло немало раздутых репутаций... Некогда хитрый и привлекательный корреспондент связалась с представителями Тёмных сил. Волшебники обнаружили статьи обезумевшей журналистки с описаниями её собственных преступлений!",
    },
  ],
  [
    {
      postImage:
        "https://sun9-37.userapi.com/impg/n4KtkkXtPOvXTRtfMGu1Dg5NfebM_opTVrAsFA/N685eFmIS3o.jpg?size=1080x1920&quality=95&sign=38c5b71ea5d2fe85d3e29bbc07891a9c&type=album",
      classType: "ghost",
      name: "Проклятый эльф",
      attack: 20000,
      allHp: 500000000,
      currentHp: 500000000,
      rewards: {
        exp: 250000,
        silver: 500000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 7,
      string:
        "Благородный эльф-лучник, обреченный стать ужасной нежетью после давно забытой битвы. Страж болота ловко скрывается среди природы и утаскивает в топи заплутавших путников. Волшебнику стоит остерегаться его хитрых ловушек и острых стрел.",
    },
    {
      postImage:
        "https://sun9-37.userapi.com/impg/n4KtkkXtPOvXTRtfMGu1Dg5NfebM_opTVrAsFA/N685eFmIS3o.jpg?size=1080x1920&quality=95&sign=38c5b71ea5d2fe85d3e29bbc07891a9c&type=album",
      classType: "ghost",
      name: "Проклятый эльф",
      attack: 40000,
      allHp: 2500000000,
      currentHp: 2500000000,
      rewards: {
        exp: 750000,
        silver: 1500000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 10,
      string:
        "Благородный эльф-лучник, обреченный стать ужасной нежетью после давно забытой битвы. Страж болота ловко скрывается среди природы и утаскивает в топи заплутавших путников. Волшебнику стоит остерегаться его хитрых ловушек и острых стрел.",
    },
    {
      postImage:
        "https://sun9-37.userapi.com/impg/n4KtkkXtPOvXTRtfMGu1Dg5NfebM_opTVrAsFA/N685eFmIS3o.jpg?size=1080x1920&quality=95&sign=38c5b71ea5d2fe85d3e29bbc07891a9c&type=album",
      classType: "ghost",
      name: "Проклятый эльф",
      attack: 80000,
      allHp: 7500000000,
      currentHp: 7500000000,
      rewards: {
        exp: 2000000,
        silver: 4000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 13,
      string:
        "Благородный эльф-лучник, обреченный стать ужасной нежетью после давно забытой битвы. Страж болота ловко скрывается среди природы и утаскивает в топи заплутавших путников. Волшебнику стоит остерегаться его хитрых ловушек и острых стрел.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-48.userapi.com/impg/ll41KSk5gOWSC_BXc0joJqqLxN8rYK2B9MlYTw/BAsOd7qzl3g.jpg?size=1080x1920&quality=95&sign=63a65ba5f4141016fa20f73e9b73ad7c&type=album",
      classType: "entity",
      name: "Зуфу",
      attack: 40000,
      allHp: 2500000000,
      currentHp: 2500000000,
      rewards: {
        exp: 500000,
        silver: 1000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 8,
      bossEvasionChance: 6,
      string:
        "Крупное существо похожее на кошку, размером со слона, у которого хвост длинее тела. Все ранее известные представители вида Зуфу были миролюбивые, но эта особь проявляет серьёзную агрессию и несет угрозу за счёт воспламеняющейся гривы и физической мощи.",
    },
    {
      postImage:
        "https://sun9-48.userapi.com/impg/ll41KSk5gOWSC_BXc0joJqqLxN8rYK2B9MlYTw/BAsOd7qzl3g.jpg?size=1080x1920&quality=95&sign=63a65ba5f4141016fa20f73e9b73ad7c&type=album",
      classType: "entity",
      name: "Зуфу",
      attack: 80000,
      allHp: 12500000000,
      currentHp: 12500000000,
      rewards: {
        exp: 1500000,
        silver: 3000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 6,
      string:
        "Крупное существо похожее на кошку, размером со слона, у которого хвост длинее тела. Все ранее известные представители вида Зуфу были миролюбивые, но эта особь проявляет серьёзную агрессию и несет угрозу за счёт воспламеняющейся гривы и физической мощи.",
    },
    {
      postImage:
        "https://sun9-48.userapi.com/impg/ll41KSk5gOWSC_BXc0joJqqLxN8rYK2B9MlYTw/BAsOd7qzl3g.jpg?size=1080x1920&quality=95&sign=63a65ba5f4141016fa20f73e9b73ad7c&type=album",
      classType: "entity",
      name: "Зуфу",
      attack: 160000,
      allHp: 37500000000,
      currentHp: 37500000000,
      rewards: {
        exp: 4000000,
        silver: 8000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 16,
      bossEvasionChance: 6,
      string:
        "Крупное существо похожее на кошку, размером со слона, у которого хвост длинее тела. Все ранее известные представители вида Зуфу были миролюбивые, но эта особь проявляет серьёзную агрессию и несет угрозу за счёт воспламеняющейся гривы и физической мощи.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-24.userapi.com/impg/sobeotJZ0LIJZwGIjSZaxkJrxzID2nvBkw1GYA/T9pFDbdkjDU.jpg?size=1080x1920&quality=95&sign=2fe2d7ddc2ffe1db432152483892cbc9&type=album",
      classType: "ghost",
      name: "Двухголовый",
      attack: 80000,
      allHp: 10000000000,
      currentHp: 10000000000,
      rewards: {
        exp: 1200000,
        silver: 2400000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 7,
      bossEvasionChance: 9,
      string:
        "Жуткий призрак скелета с двумя головами. Когда-то это были два алхимика, организовавшие тайную лабораторию в пещере. Но неудачный эксперимент слил их воедино и обратил в призрака, который атакует противника химическими бомбами.",
    },
    {
      postImage:
        "https://sun9-24.userapi.com/impg/sobeotJZ0LIJZwGIjSZaxkJrxzID2nvBkw1GYA/T9pFDbdkjDU.jpg?size=1080x1920&quality=95&sign=2fe2d7ddc2ffe1db432152483892cbc9&type=album",
      classType: "ghost",
      name: "Двухголовый",
      attack: 160000,
      allHp: 50000000000,
      currentHp: 50000000000,
      rewards: {
        exp: 3600000,
        silver: 7200000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 7,
      bossEvasionChance: 19,
      string:
        "Жуткий призрак скелета с двумя головами. Когда-то это были два алхимика, организовавшие тайную лабораторию в пещере. Но неудачный эксперимент слил их воедино и обратил в призрака, который атакует противника химическими бомбами.",
    },
    {
      postImage:
        "https://sun9-24.userapi.com/impg/sobeotJZ0LIJZwGIjSZaxkJrxzID2nvBkw1GYA/T9pFDbdkjDU.jpg?size=1080x1920&quality=95&sign=2fe2d7ddc2ffe1db432152483892cbc9&type=album",
      classType: "ghost",
      name: "Двухголовый",
      attack: 320000,
      allHp: 150000000000,
      currentHp: 150000000000,
      rewards: {
        exp: 9600000,
        silver: 19200000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 7,
      bossEvasionChance: 27,
      string:
        "Жуткий призрак скелета с двумя головами. Когда-то это были два алхимика, организовавшие тайную лабораторию в пещере. Но неудачный эксперимент слил их воедино и обратил в призрака, который атакует противника химическими бомбами.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-76.userapi.com/impg/nZzNFIP744FQeT1RqdyJtxcaH7ZK1K3mIfdExg/uqJ7Q-cxbRo.jpg?size=1080x1920&quality=95&sign=927d02bda23eacb00241d1fe4c8583bb&type=album",
      classType: "man",
      name: "Аркейн",
      attack: 160000,
      allHp: 50000000000,
      currentHp: 50000000000,
      rewards: {
        exp: 3000000,
        silver: 6000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 10,
      string:
        "Маг с бионическими руками, выдающийся специалист в области трансфигурации и алхимии. Одержимость магией превратила профессора в безумца, который намерен привести в действие смертоносные изобретения с крыши нашего замка.",
    },
    {
      postImage:
        "https://sun9-76.userapi.com/impg/nZzNFIP744FQeT1RqdyJtxcaH7ZK1K3mIfdExg/uqJ7Q-cxbRo.jpg?size=1080x1920&quality=95&sign=927d02bda23eacb00241d1fe4c8583bb&type=album",
      classType: "man",
      name: "Аркейн",
      attack: 320000,
      allHp: 250000000000,
      currentHp: 250000000000,
      rewards: {
        exp: 9000000,
        silver: 18000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 13,
      string:
        "Маг с бионическими руками, выдающийся специалист в области трансфигурации и алхимии. Одержимость магией превратила профессора в безумца, который намерен привести в действие смертоносные изобретения с крыши нашего замка.",
    },
    {
      postImage:
        "https://sun9-76.userapi.com/impg/nZzNFIP744FQeT1RqdyJtxcaH7ZK1K3mIfdExg/uqJ7Q-cxbRo.jpg?size=1080x1920&quality=95&sign=927d02bda23eacb00241d1fe4c8583bb&type=album",
      classType: "man",
      name: "Аркейн",
      attack: 640000,
      allHp: 750000000000,
      currentHp: 750000000000,
      rewards: {
        exp: 24000000,
        silver: 48000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 16,
      bossEvasionChance: 16,
      string:
        "Маг с бионическими руками, выдающийся специалист в области трансфигурации и алхимии. Одержимость магией превратила профессора в безумца, который намерен привести в действие смертоносные изобретения с крыши нашего замка.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-70.userapi.com/impg/gcCXUWYOVjVjHFuXtCGy1KjUkTqbHguCT-Gc0w/LeCbyPmMYCQ.jpg?size=1080x1920&quality=95&sign=82157d5ccf057aaaeaa5f918fbe1b688&type=album",
      classType: "entity",
      name: "Гриндиллоу",
      attack: 320000,
      allHp: 200000000000,
      currentHp: 200000000000,
      rewards: {
        exp: 8000000,
        silver: 16000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 10,
      string:
        "Водный обитатель Чёрного озера, болотно-зелёного цвета с острыми рожками и длинными костлявыми пальцами. Атакуют группой и утаскивают далеко на дно тех Волшебников, кому не повезло оказаться в их родной стихии в тёмный час.",
    },
    {
      postImage:
        "https://sun9-70.userapi.com/impg/gcCXUWYOVjVjHFuXtCGy1KjUkTqbHguCT-Gc0w/LeCbyPmMYCQ.jpg?size=1080x1920&quality=95&sign=82157d5ccf057aaaeaa5f918fbe1b688&type=album",
      classType: "entity",
      name: "Гриндиллоу",
      attack: 640000,
      allHp: 1000000000000,
      currentHp: 1000000000000,
      rewards: {
        exp: 24000000,
        silver: 48000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 19,
      bossEvasionChance: 10,
      string:
        "Водный обитатель Чёрного озера, болотно-зелёного цвета с острыми рожками и длинными костлявыми пальцами. Атакуют группой и утаскивают далеко на дно тех Волшебников, кому не повезло оказаться в их родной стихии в тёмный час.",
    },

    {
      postImage:
        "https://sun9-70.userapi.com/impg/gcCXUWYOVjVjHFuXtCGy1KjUkTqbHguCT-Gc0w/LeCbyPmMYCQ.jpg?size=1080x1920&quality=95&sign=82157d5ccf057aaaeaa5f918fbe1b688&type=album",
      classType: "entity",
      name: "Гриндиллоу",
      attack: 1280000,
      allHp: 3000000000000,
      currentHp: 3000000000000,
      rewards: {
        exp: 64000000,
        silver: 128000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 26,
      bossEvasionChance: 10,
      string:
        "Водный обитатель Чёрного озера, болотно-зелёного цвета с острыми рожками и длинными костлявыми пальцами. Атакуют группой и утаскивают далеко на дно тех Волшебников, кому не повезло оказаться в их родной стихии в тёмный час.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-35.userapi.com/impg/MwgEjf2fdW6HSOhH9BYFtp05KRgseu4SEmQxNQ/MV8yTUzfhZA.jpg?size=1080x1920&quality=95&sign=d42adc6b8ff6257915b32961d90346cb&type=album",
      classType: "man",
      name: "Хвост",
      attack: 640000,
      allHp: 1000000000000,
      currentHp: 1000000000000,
      rewards: {
        exp: 18000000,
        silver: 36000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 12,
      string:
        "Слабый человек, которого страх смерти заставляет совершать страшные поступки... Превращаясь в крысу, он ловко ускользает из рук недоброжелательных Волшебников. По указаниям самого Тёмного Лорда готов совершать страшные злодеяния.",
    },
    {
      postImage:
        "https://sun9-35.userapi.com/impg/MwgEjf2fdW6HSOhH9BYFtp05KRgseu4SEmQxNQ/MV8yTUzfhZA.jpg?size=1080x1920&quality=95&sign=d42adc6b8ff6257915b32961d90346cb&type=album",
      classType: "man",
      name: "Хвост",
      attack: 1280000,
      allHp: 5000000000000,
      currentHp: 5000000000000,
      rewards: {
        exp: 54000000,
        silver: 108000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 16,
      bossEvasionChance: 16,
      string:
        "Слабый человек, которого страх смерти заставляет совершать страшные поступки... Превращаясь в крысу, он ловко ускользает из рук недоброжелательных Волшебников. По указаниям самого Тёмного Лорда готов совершать страшные злодеяния.",
    },
    {
      postImage:
        "https://sun9-35.userapi.com/impg/MwgEjf2fdW6HSOhH9BYFtp05KRgseu4SEmQxNQ/MV8yTUzfhZA.jpg?size=1080x1920&quality=95&sign=d42adc6b8ff6257915b32961d90346cb&type=album",
      classType: "man",
      name: "Хвост",
      attack: 2560000,
      allHp: 15000000000000,
      currentHp: 15000000000000,
      rewards: {
        exp: 144000000,
        silver: 288000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 20,
      bossEvasionChance: 20,
      string:
        "Слабый человек, которого страх смерти заставляет совершать страшные поступки... Превращаясь в крысу, он ловко ускользает из рук недоброжелательных Волшебников. По указаниям самого Тёмного Лорда готов совершать страшные злодеяния.",
    },
  ],
  [
    {
      postImage:
        "https://sun9-25.userapi.com/impg/tQaTiRaTHWt0XOSgsg5K1ybGDjfICJf9SF7yOg/zOQV3nJfmhE.jpg?size=1080x1920&quality=95&sign=cbc3ef1a468b6d241cf829c209ba715f&type=album",
      classType: "entity",
      name: "Демментор",
      attack: 1280000,
      allHp: 5000000000000,
      currentHp: 5000000000000,
      rewards: {
        exp: 50000000,
        silver: 100000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 14,
      string:
        "Самые отвратительные существа на свете, являющиеся в образе тёмных призраков, охраняющих самую жуткую тюрьму в мире. Они не знают жалости и пощады, лишая всё вокруг счастья, надежды и самой жизни.",
    },
    {
      postImage:
        "https://sun9-25.userapi.com/impg/tQaTiRaTHWt0XOSgsg5K1ybGDjfICJf9SF7yOg/zOQV3nJfmhE.jpg?size=1080x1920&quality=95&sign=cbc3ef1a468b6d241cf829c209ba715f&type=album",
      classType: "entity",
      name: "Демментор",
      attack: 2560000,
      allHp: 25000000000000,
      currentHp: 25000000000000,
      rewards: {
        exp: 150000000,
        silver: 300000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 32,
      string:
        "Самые отвратительные существа на свете, являющиеся в образе тёмных призраков, охраняющих самую жуткую тюрьму в мире. Они не знают жалости и пощады, лишая всё вокруг счастья, надежды и самой жизни.",
    },
    {
      postImage:
        "https://sun9-25.userapi.com/impg/tQaTiRaTHWt0XOSgsg5K1ybGDjfICJf9SF7yOg/zOQV3nJfmhE.jpg?size=1080x1920&quality=95&sign=cbc3ef1a468b6d241cf829c209ba715f&type=album",
      classType: "entity",
      name: "Демментор",
      attack: 5120000,
      allHp: 75000000000000,
      currentHp: 75000000000000,
      rewards: {
        exp: 400000000,
        silver: 800000000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 12,
      bossEvasionChance: 50,
      string:
        "Самые отвратительные существа на свете, являющиеся в образе тёмных призраков, охраняющих самую жуткую тюрьму в мире. Они не знают жалости и пощады, лишая всё вокруг счастья, надежды и самой жизни.",
    },
  ],
];

export const clothes1 = {
  pants1: {
    category: "pants",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 2,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  pants2: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 5,
    },
    category: "pants",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 3,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  pants3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 10,
    },
    category: "pants",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants4: {
    lvlNeed: 5,
    category: "pants",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 15,
    },
    category: "pants",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 4,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants6: {
    lvlDiceNeed: 10,
    category: "pants",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 10,
    },
    category: "pants",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants8: {
    characSum: 1000,
    category: "pants",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants9: {
    lvlNeed: 10,
    category: "pants",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants10: {
    characSum: 2000,
    category: "pants",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  pants11: {
    lvlDiceNeed: 50,
    category: "pants",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  pants12: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 20,
    },
    category: "pants",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants13: {
    lvlDiceNeed: 100,
    category: "pants",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants14: {
    lvlNeed: 15,
    category: "pants",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 10,
    },
    category: "pants",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants16: {
    characSum: 4000,
    category: "pants",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  pants17: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 20,
    },
    category: "pants",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 11,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  pants18: {
    lvlDiceNeed: 250,
    category: "pants",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "pants",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants20: {
    lvlNeed: 25,
    category: "pants",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants21: {
    characSum: 8000,
    category: "pants",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 14,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  pants22: {
    lvlDiceNeed: 500,
    category: "pants",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 15,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  pants23: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 20,
    },
    category: "pants",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  pants24: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 10,
    },
    category: "pants",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pants25: {
    characSum: 12000,
    category: "pants",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pants26: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 5,
    },
    category: "pants",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  pants27: {
    lvlDiceNeed: 800,
    category: "pants",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  pants28: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 2,
    },
    category: "pants",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  pants29: {
    lvlDiceNeed: 1200,
    category: "pants",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 86000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 29,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  pants30: {
    lvlDiceNeed: 2000,
    category: "pants",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  pantsTreasure1: {
    category: "pants",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2114,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure2: {
    category: "pants",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2124,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure3: {
    category: "pants",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2134,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure4: {
    category: "pants",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2144,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure5: {
    category: "pants",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1914,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure6: {
    category: "pants",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1924,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure7: {
    category: "pants",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1934,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure8: {
    category: "pants",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1944,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure9: {
    category: "pants",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1954,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure10: {
    category: "pants",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1964,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure11: {
    category: "pants",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1974,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure12: {
    category: "pants",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1984,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure13: {
    category: "pants",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1014,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  pantsTreasure14: {
    category: "pants",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1024,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  pantsTreasure15: {
    category: "pants",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1034,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure16: {
    category: "pants",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1044,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure17: {
    category: "pants",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1054,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure18: {
    category: "pants",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1064,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure19: {
    category: "pants",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1074,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  pantsTreasure20: {
    category: "pants",
    src: "Treasure/Treasure20.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1084,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  pantsTreasure21: {
    category: "pants",
    src: "Treasure/Treasure21.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0214,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure22: {
    category: "pants",
    src: "Treasure/Treasure22.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0224,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure23: {
    category: "pants",
    src: "Treasure/Treasure23.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0234,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure24: {
    category: "pants",
    src: "Treasure/Treasure24.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0234,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsEvent1: {
    category: "pants",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 40,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  pantsEvent2: {
    category: "pants",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 600,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 40,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  pantsEvent3: {
    category: "pants",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 300,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 40,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  shirt1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "shirt",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 2,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 6,
    },
    category: "shirt",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 3,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt3: {
    arenaWinsNeed: 100,
    category: "shirt",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 4,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt4: {
    arenaDefWinsNeed: 5,
    category: "shirt",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 3,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt5: {
    collectionsNeed: 25,
    category: "shirt",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt6: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 18,
    },
    category: "shirt",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 3,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt7: {
    lvlNeed: 8,
    category: "shirt",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt8: {
    arenaWinsNeed: 300,
    category: "shirt",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt9: {
    collectionsNeed: 80,
    category: "shirt",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt10: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 25,
    },
    category: "shirt",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 8,
    },
    category: "shirt",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt12: {
    lvlNeed: 16,
    category: "shirt",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt13: {
    arenaWinsNeed: 800,
    category: "shirt",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt14: {
    arenaDefWinsNeed: 25,
    category: "shirt",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt15: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 15,
    },
    category: "shirt",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 5,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  shirt16: {
    collectionsNeed: 250,
    category: "shirt",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt17: {
    lvlNeed: 25,
    category: "shirt",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 15000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 6,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt18: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 7,
    },
    category: "shirt",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 6,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt19: {
    lvlNeed: 30,
    category: "shirt",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt20: {
    arenaWinsNeed: 1500,
    category: "shirt",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt21: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 13,
    },
    category: "shirt",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt22: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 20,
    },
    category: "shirt",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  shirt23: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 12,
    },
    category: "shirt",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 25000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  shirt24: {
    arenaDefWinsNeed: 50,
    category: "shirt",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },

    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  shirt25: {
    collectionsNeed: 800,
    category: "shirt",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 9,
      force: 9,
      power: 9,
      protection: 12,
      accuracy: 9,
      evasion: 9,
      speed: 9,
    },
  },
  shirt26: {
    lvlNeed: 35,
    category: "shirt",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt27: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 18,
    },
    category: "shirt",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 32000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt28: {
    arenaWinsNeed: 3000,
    category: "shirt",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt29: {
    arenaDefWinsNeed: 100,
    category: "shirt",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  shirt30: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 24,
    },
    category: "shirt",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  shirt31: {
    lvlNeed: 40,
    category: "shirt",
    src: "31.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 15,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt32: {
    arenaDefWinsNeed: 250,
    category: "shirt",
    src: "32.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 42000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 16,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt33: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 9,
    },
    category: "shirt",
    src: "33.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 44000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 16,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt34: {
    lvlNeed: 50,
    category: "shirt",
    src: "34.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 46000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt35: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 16,
    },
    category: "shirt",
    src: "35.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt36: {
    arenaWinsNeed: 6000,
    category: "shirt",
    src: "36.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt37: {
    collectionsNeed: 2500,
    category: "shirt",
    src: "37.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 18,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt38: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 22,
    },
    category: "shirt",
    src: "38.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  shirt39: {
    arenaDefWinsNeed: 500,
    category: "shirt",
    src: "39.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 20,
      accuracy: 16,
      evasion: 16,
      speed: 16,
    },
  },
  shirt40: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 4,
    },
    category: "shirt",
    src: "40.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 19,
      accuracy: 16,
      evasion: 16,
      speed: 16,
    },
  },
  shirt41: {
    lvlNeed: 60,
    category: "shirt",
    src: "41.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  shirt42: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 8,
    },
    category: "shirt",
    src: "42.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  shirt43: {
    lvlNeed: 65,
    category: "shirt",
    src: "43.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 68000,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 22,
      accuracy: 18,
      evasion: 18,
      speed: 18,
    },
  },
  shirt44: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 12,
    },
    category: "shirt",
    src: "44.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt45: {
    arenaWinsNeed: 10000,
    category: "shirt",
    src: "45.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 72000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt46: {
    arenaDefWinsNeed: 1000,
    category: "shirt",
    src: "46.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 74000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt47: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 1,
    },
    category: "shirt",
    src: "47.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 76000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt48: {
    lvlNeed: 70,
    category: "shirt",
    src: "48.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 78000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt49: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 3,
    },
    category: "shirt",
    src: "49.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  shirt50: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 13,
    },
    category: "shirt",
    src: "50.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 27,
      accuracy: 22,
      evasion: 22,
      speed: 22,
    },
  },
  shirt51: {
    arenaWinsNeed: 20000,
    category: "shirt",
    src: "51.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 88000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 28,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  shirt52: {
    collectionsNeed: 10000,
    category: "shirt",
    src: "52.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 95000,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 30,
      accuracy: 26,
      evasion: 26,
      speed: 26,
    },
  },
  shirt53: {
    arenaDefWinsNeed: 5000,
    category: "shirt",
    src: "53.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  shirtTreasure1: {
    category: "shirt",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2113,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure2: {
    category: "shirt",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2123,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure3: {
    category: "shirt",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2133,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure4: {
    category: "shirt",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2133,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure5: {
    category: "shirt",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1913,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure6: {
    category: "shirt",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1923,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure7: {
    category: "shirt",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1933,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure8: {
    category: "shirt",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1943,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure9: {
    category: "shirt",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1953,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure10: {
    category: "shirt",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1963,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure11: {
    category: "shirt",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1973,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure12: {
    category: "shirt",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1983,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure13: {
    category: "shirt",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1013,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure14: {
    category: "shirt",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1023,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure15: {
    category: "shirt",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1033,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure16: {
    category: "shirt",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1043,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure17: {
    category: "shirt",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1053,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure18: {
    category: "shirt",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0313,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure19: {
    category: "shirt",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0323,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure20: {
    category: "shirt",
    src: "Treasure/Treasure20.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0333,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure21: {
    category: "shirt",
    src: "Treasure/Treasure21.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0343,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure22: {
    category: "shirt",
    src: "Treasure/Treasure22.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0353,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure23: {
    category: "shirt",
    src: "Treasure/Treasure23.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0363,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure24: {
    category: "shirt",
    src: "Treasure/Treasure24.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0113,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure25: {
    category: "shirt",
    src: "Treasure/Treasure25.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0123,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure26: {
    category: "shirt",
    src: "Treasure/Treasure26.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0133,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure27: {
    category: "shirt",
    src: "Treasure/Treasure27.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0143,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtEvent1: {
    category: "shirt",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 40,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  shirtEvent2: {
    category: "shirt",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 1000,
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  shirtEvent3: {
    category: "shirt",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 500,
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  shoes1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "shoes",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 2,
      speed: 2,
    },
  },
  shoes2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 5,
    },
    category: "shoes",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 3,
      speed: 3,
    },
  },
  shoes3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 13,
    },
    category: "shoes",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 3,
      speed: 3,
    },
  },
  shoes4: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 7,
    },
    category: "shoes",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 7000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 4,
      speed: 4,
    },
  },
  shoes5: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 13,
    },
    category: "shoes",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 4,
      speed: 4,
    },
  },
  shoes6: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 9,
    },
    category: "shoes",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 8,
      speed: 8,
    },
  },
  shoes7: {
    lvlNeed: 10,
    category: "shoes",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },
  shoes8: {
    candyGetNeed: 100,
    category: "shoes",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },
  shoes9: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 12,
    },
    category: "shoes",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 7,
      speed: 7,
    },
  },
  shoes10: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 5,
    },
    category: "shoes",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 24000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 8,
      speed: 8,
    },
  },
  shoes11: {
    lvlNeed: 20,
    category: "shoes",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes12: {
    candyGetNeed: 500,
    category: "shoes",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes13: {
    characSum: 10000,
    category: "shoes",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes14: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "shoes",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 14,
      speed: 14,
    },
  },
  shoes15: {
    lvlNeed: 30,
    category: "shoes",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  shoes16: {
    candyGetNeed: 1000,
    category: "shoes",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 16,
    },
  },
  shoes17: {
    characSum: 20000,
    category: "shoes",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 16,
    },
  },
  shoes18: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 7,
    },
    category: "shoes",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 17,
      speed: 17,
    },
  },
  shoes19: {
    lvlNeed: 35,
    category: "shoes",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 17,
      speed: 17,
    },
  },
  shoes20: {
    candyGetNeed: 2500,
    category: "shoes",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 17,
      speed: 17,
    },
  },
  shoes21: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 5,
    },
    category: "shoes",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 16,
      accuracy: 16,
      evasion: 20,
      speed: 20,
    },
  },
  shoes22: {
    lvlNeed: 40,
    category: "shoes",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 20,
    },
  },
  shoes23: {
    candyGetNeed: 5000,
    category: "shoes",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 20,
    },
  },
  shoes24: {
    lvlNeed: 45,
    category: "shoes",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 22,
      speed: 22,
    },
  },
  shoes25: {
    characSum: 50000,
    category: "shoes",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 22,
      speed: 22,
    },
  },
  shoes26: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 4,
    },
    category: "shoes",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 23,
      speed: 23,
    },
  },
  shoes27: {
    lvlNeed: 55,
    category: "shoes",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 23,
      speed: 23,
    },
  },
  shoes28: {
    candyGetNeed: 10000,
    category: "shoes",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 23,
      speed: 23,
    },
  },
  shoes29: {
    lvlNeed: 60,
    category: "shoes",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 95000,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 29,
      speed: 29,
    },
  },
  shoes30: {
    lvlNeed: 65,
    category: "shoes",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },
  shoes31: {
    characSum: 100000,
    category: "shoes",
    src: "31.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 31,
      accuracy: 31,
      evasion: 36,
      speed: 36,
    },
  },

  shoesTreasure1: {
    category: "shoes",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2115,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 7,
    },
  },

  shoesTreasure2: {
    category: "shoes",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2125,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 7,
    },
  },

  shoesTreasure3: {
    category: "shoes",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1955,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure4: {
    category: "shoes",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1945,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure5: {
    category: "shoes",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1935,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure6: {
    category: "shoes",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1925,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure7: {
    category: "shoes",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1915,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure8: {
    category: "shoes",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1015,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure9: {
    category: "shoes",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1025,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure10: {
    category: "shoes",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1035,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure11: {
    category: "shoes",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1045,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure12: {
    category: "shoes",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1055,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure13: {
    category: "shoes",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0515,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },

  shoesTreasure14: {
    category: "shoes",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0315,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure15: {
    category: "shoes",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0325,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure16: {
    category: "shoes",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0335,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure17: {
    category: "shoes",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0115,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesTreasure18: {
    category: "shoes",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0125,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesTreasure19: {
    category: "shoes",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0135,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesEvent1: {
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 40,
      speed: 40,
    },
  },

  shoesEvent2: {
    zMore: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 1400,
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 90,
      speed: 90,
    },
  },

  shoesEvent3: {
    zMore: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 700,
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 90,
      speed: 90,
    },
  },

  head1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "head",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 2,
      evasion: 1,
      speed: 1,
    },
  },
  head2: {
    bossNeed: {
      name: "Полтергейст",
      number: 1,
      count: 8,
    },
    category: "head",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 3,
      evasion: 1,
      speed: 1,
    },
  },
  head3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 8,
    },
    category: "head",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 3,
      evasion: 2,
      speed: 2,
    },
  },
  head4: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 11,
    },
    category: "head",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  head5: {
    game21Need: 50,
    category: "head",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  head6: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 16,
    },
    category: "head",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  head7: {
    classroomsNeed: 100,
    category: "head",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 6,
      speed: 5,
    },
  },
  head8: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 9,
    },
    category: "head",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 6,
      speed: 5,
    },
  },
  head9: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 17,
    },
    category: "head",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 8,
      speed: 6,
    },
  },
  head10: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 100,
    category: "head",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  head11: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "head",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head12: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 250,
    category: "head",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head13: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 500,
    category: "head",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head14: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 10,
    },
    category: "head",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  head15: {
    game21Need: 500,
    category: "head",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 31000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  head16: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 15,
    },
    category: "head",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 35000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 12,
    },
  },
  head17: {
    classroomsNeed: 1000,
    category: "head",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 15,
      speed: 12,
    },
  },
  head18: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 20,
    },
    category: "head",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head19: {
    game21Need: 1000,
    category: "head",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 44000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 13,
      accuracy: 13,
      evasion: 15,
      speed: 13,
    },
  },
  head20: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 7,
    },
    category: "head",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head21: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 11,
    },
    category: "head",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head22: {
    game21Need: 2500,
    category: "head",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 58000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 16,
      accuracy: 16,
      evasion: 19,
      speed: 16,
    },
  },
  head23: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 3,
    },
    category: "head",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 17,
    },
  },
  head24: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 5000,
    category: "head",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 18,
      accuracy: 18,
      evasion: 21,
      speed: 18,
    },
  },
  head25: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 5,
    },
    category: "head",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 25,
      speed: 22,
    },
  },
  head26: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 11,
    },
    category: "head",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 23,
      force: 23,
      power: 23,
      protection: 23,
      accuracy: 23,
      evasion: 26,
      speed: 23,
    },
  },
  head27: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 5000,
    category: "head",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 88000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 24,
      evasion: 28,
      speed: 24,
    },
  },
  head28: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 10000,
    category: "head",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 34,
      speed: 30,
    },
  },
  head29: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 10000,
    category: "head",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 32,
      force: 32,
      power: 32,
      protection: 32,
      accuracy: 32,
      evasion: 37,
      speed: 32,
    },
  },

  headTreasure1: {
    category: "head",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2116,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure2: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2116,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure3: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2126,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure4: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2136,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure5: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1916,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 7,
    },
  },

  headTreasure6: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1916,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 7,
    },
  },

  headTreasure7: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1026,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure8: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1036,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure9: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1046,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure10: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0516,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 20,
    },
  },

  headTreasure11: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0316,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  headTreasure12: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0326,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  headTreasure13: {
    category: "head",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0116,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 45,
      speed: 40,
    },
  },

  headEvent1: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 40,
      speed: 30,
    },
  },

  headEvent2: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 1800,
    characteristic: {
      health: 90,
      force: 90,
      power: 90,
      protection: 90,
      accuracy: 90,
      evasion: 110,
      speed: 90,
    },
  },

  headEvent3: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 900,
    characteristic: {
      health: 90,
      force: 90,
      power: 90,
      protection: 90,
      accuracy: 90,
      evasion: 110,
      speed: 90,
    },
  },

  wand1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "wand",
    src: "1.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    characteristic: {
      health: 1,
      force: 2,
      power: 2,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  wand2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "wand",
    src: "2.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 15,
    },
    characteristic: {
      health: 2,
      force: 3,
      power: 3,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  wand3: {
    collectionsMoveNeed: 5,
    category: "wand",
    src: "3.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 4,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  wand4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 20,
    },
    category: "wand",
    src: "4.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 4,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  wand5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 35,
    },
    category: "wand",
    src: "5.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 25,
    },
    characteristic: {
      health: 4,
      force: 5,
      power: 5,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  wand6: {
    collectionsMoveNeed: 25,
    category: "wand",
    src: "6.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 30,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wand7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 10,
    },
    category: "wand",
    src: "7.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand8: {
    collectionsMoveNeed: 100,
    category: "wand",
    src: "8.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 17,
    },
    category: "wand",
    src: "9.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 55,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 28,
    },
    category: "wand",
    src: "10.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 70,
    },
    characteristic: {
      health: 7,
      force: 9,
      power: 9,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  wand11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 35,
    },
    category: "wand",
    src: "11.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 75,
    },
    characteristic: {
      health: 7,
      force: 9,
      power: 9,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  wand12: {
    collectionsMoveNeed: 250,
    category: "wand",
    src: "12.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 80,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 13,
    },
    category: "wand",
    src: "13.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 85,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 21,
    },
    category: "wand",
    src: "14.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 90,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 33,
    },
    category: "wand",
    src: "15.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 100,
    },
    characteristic: {
      health: 10,
      force: 12,
      power: 12,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  wand16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "wand",
    src: "16.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 120,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wand17: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 16,
    },
    category: "wand",
    src: "17.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 140,
    },
    characteristic: {
      health: 12,
      force: 15,
      power: 15,
      protection: 12,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  wand18: {
    collectionsMoveNeed: 500,
    category: "wand",
    src: "18.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 150,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  wand19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 25,
    },
    category: "wand",
    src: "19.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 160,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  wand20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 8,
    },
    category: "wand",
    src: "20.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 200,
    },
    characteristic: {
      health: 17,
      force: 20,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  wand21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 14,
    },
    category: "wand",
    src: "21.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 220,
    },
    characteristic: {
      health: 18,
      force: 21,
      power: 21,
      protection: 18,
      accuracy: 18,
      evasion: 18,
      speed: 18,
    },
  },
  wand22: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 19,
    },
    category: "wand",
    src: "22.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 240,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand23: {
    collectionsMoveNeed: 1500,
    category: "wand",
    src: "23.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 250,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 6,
    },
    category: "wand",
    src: "24.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand25: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 11,
    },
    category: "wand",
    src: "25.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 320,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  wand26: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 17,
    },
    category: "wand",
    src: "26.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 360,
    },
    characteristic: {
      health: 21,
      force: 25,
      power: 25,
      protection: 21,
      accuracy: 21,
      evasion: 21,
      speed: 21,
    },
  },
  wand27: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 6,
    },
    category: "wand",
    src: "27.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 450,
    },
    characteristic: {
      health: 23,
      force: 28,
      power: 28,
      protection: 23,
      accuracy: 23,
      evasion: 23,
      speed: 23,
    },
  },
  wand28: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 8,
    },
    category: "wand",
    src: "28.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 500,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  wand29: {
    collectionsMoveNeed: 5000,
    category: "wand",
    src: "29.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  wandTreasure1: {
    category: "wand",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2131,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure2: {
    category: "wand",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2121,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure3: {
    category: "wand",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2111,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure4: {
    category: "wand",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1021,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wandTreasure5: {
    category: "wand",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1011,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wandTreasure6: {
    category: "wand",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0521,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  wandTreasure7: {
    category: "wand",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0511,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  wandTreasure8: {
    category: "wand",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0321,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  wandTreasure9: {
    category: "wand",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0311,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  wandTreasure10: {
    category: "wand",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0231,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure11: {
    category: "wand",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0221,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure12: {
    category: "wand",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0211,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure13: {
    category: "wand",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0121,
    },
    characteristic: {
      health: 40,
      force: 50,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  wandTreasure14: {
    category: "wand",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0111,
    },
    characteristic: {
      health: 40,
      force: 50,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  wandTreasure15: {
    category: "wand",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.00511,
    },
    characteristic: {
      health: 50,
      force: 60,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  wandPrize1: {
    category: "wand",
    src: "Prize/Prize1.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 10,
      force: 300,
      power: 300,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  wandPrize2: {
    category: "wand",
    src: "Prize/Prize2.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 5,
      force: 150,
      power: 150,
      protection: 5,
      accuracy: 5,
      evasion: 150,
      speed: 5,
    },
  },
  wandPrize3: {
    category: "wand",
    src: "Prize/Prize3.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 20,
      force: 200,
      power: 200,
      protection: 20,
      accuracy: 20,
      evasion: 200,
      speed: 20,
    },
  },
  wandPrize4: {
    category: "wand",
    src: "Prize/Prize4.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 30,
      force: 300,
      power: 300,
      protection: 30,
      accuracy: 30,
      evasion: 300,
      speed: 30,
    },
  },
  wandPrize5: {
    category: "wand",
    src: "Prize/Prize5.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 23,
      force: 500,
      power: 23,
      protection: 500,
      accuracy: 23,
      evasion: 23,
      speed: 23,
    },
  },

  wandEvent1: {
    category: "wand",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 40,
      power: 40,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  wandEvent2: {
    category: "wand",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 2600,
    characteristic: {
      health: 130,
      force: 150,
      power: 150,
      protection: 130,
      accuracy: 130,
      evasion: 130,
      speed: 130,
    },
  },

  wandEvent3: {
    category: "wand",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 1300,
    characteristic: {
      health: 130,
      force: 150,
      power: 150,
      protection: 130,
      accuracy: 130,
      evasion: 130,
      speed: 130,
    },
  },

  hand1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "hand",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 2,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  hand2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "hand",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 3,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  hand3: {
    collectionsGetNeed: 5,
    category: "hand",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 4,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  hand4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 22,
    },
    category: "hand",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 4,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  hand5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 32,
    },
    category: "hand",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 5,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  hand6: {
    collectionsGetNeed: 25,
    category: "hand",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  hand7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 9,
    },
    category: "hand",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand8: {
    collectionsGetNeed: 100,
    category: "hand",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 15,
    },
    category: "hand",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 24,
    },
    category: "hand",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  hand11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 32,
    },
    category: "hand",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 24000,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  hand12: {
    collectionsGetNeed: 250,
    category: "hand",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 14,
    },
    category: "hand",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 24,
    },
    category: "hand",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 32000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 36,
    },
    category: "hand",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 12,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  hand16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "hand",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 13,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  hand17: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 16,
    },
    category: "hand",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 42000,
    },
    characteristic: {
      health: 15,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  hand18: {
    collectionsGetNeed: 500,
    category: "hand",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 17,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  hand19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 30,
    },
    category: "hand",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 54000,
    },
    characteristic: {
      health: 17,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  hand20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 12,
    },
    category: "hand",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 62000,
    },
    characteristic: {
      health: 20,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  hand21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 21,
    },
    category: "hand",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 68000,
    },
    characteristic: {
      health: 22,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  hand22: {
    collectionsGetNeed: 1500,
    category: "hand",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 72000,
    },
    characteristic: {
      health: 22,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  hand23: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 13,
    },
    category: "hand",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 76000,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  hand24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 25,
    },
    category: "hand",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 25,
      force: 21,
      power: 21,
      protection: 21,
      accuracy: 21,
      evasion: 21,
      speed: 21,
    },
  },
  hand25: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 13,
    },
    category: "hand",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  hand26: {
    collectionsGetNeed: 5000,
    category: "hand",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  handTreasure1: {
    category: "hand",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2137,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure2: {
    category: "hand",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2127,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure3: {
    category: "hand",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2117,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure4: {
    category: "hand",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1917,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  handTreasure5: {
    category: "hand",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1517,
    },
    characteristic: {
      health: 12,
      force: 9,
      power: 9,
      protection: 9,
      accuracy: 9,
      evasion: 9,
      speed: 9,
    },
  },

  handTreasure6: {
    category: "hand",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1027,
    },
    characteristic: {
      health: 14,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  handTreasure7: {
    category: "hand",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1017,
    },
    characteristic: {
      health: 14,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  handTreasure8: {
    category: "hand",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0537,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure9: {
    category: "hand",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0527,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure10: {
    category: "hand",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0517,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure11: {
    category: "hand",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0147,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure12: {
    category: "hand",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0137,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure13: {
    category: "hand",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0127,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure14: {
    category: "hand",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0117,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handEvent1: {
    category: "hand",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 40,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  handEvent2: {
    swap: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 2200,
    characteristic: {
      health: 130,
      force: 110,
      power: 110,
      protection: 110,
      accuracy: 110,
      evasion: 110,
      speed: 110,
    },
  },

  handEvent3: {
    swap: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 1100,
    characteristic: {
      health: 130,
      force: 110,
      power: 110,
      protection: 110,
      accuracy: 110,
      evasion: 110,
      speed: 110,
    },
  },

  broom1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "broom",
    src: "1.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 2,
      accuracy: 1,
      evasion: 2,
      speed: 1,
    },
  },
  broom2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "broom",
    src: "2.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 15,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 3,
      speed: 2,
    },
  },
  broom3: {
    candyMoveNeed: 10,
    category: "broom",
    src: "3.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  broom4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 20,
    },
    category: "broom",
    src: "4.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  broom5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 35,
    },
    category: "broom",
    src: "5.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 25,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  broom6: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 12,
    },
    category: "broom",
    src: "6.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 7,
      accuracy: 6,
      evasion: 7,
      speed: 6,
    },
  },
  broom7: {
    candyMoveNeed: 50,
    category: "broom",
    src: "7.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom8: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 18,
    },
    category: "broom",
    src: "8.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 55,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 29,
    },
    category: "broom",
    src: "9.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 70,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 36,
    },
    category: "broom",
    src: "10.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 75,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom11: {
    candyMoveNeed: 200,
    category: "broom",
    src: "11.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 80,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom12: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 14,
    },
    category: "broom",
    src: "12.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 85,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 22,
    },
    category: "broom",
    src: "13.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 90,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 12,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  broom14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 34,
    },
    category: "broom",
    src: "14.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 100,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 12,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  broom15: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "broom",
    src: "15.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 120,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 13,
      accuracy: 11,
      evasion: 13,
      speed: 11,
    },
  },
  broom16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 17,
    },
    category: "broom",
    src: "16.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 140,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 15,
      accuracy: 13,
      evasion: 15,
      speed: 13,
    },
  },
  broom17: {
    candyMoveNeed: 600,
    category: "broom",
    src: "17.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 150,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 16,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  broom18: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 26,
    },
    category: "broom",
    src: "18.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 160,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 16,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  broom19: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 9,
    },
    category: "broom",
    src: "19.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 200,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 20,
      speed: 17,
    },
  },
  broom20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 16,
    },
    category: "broom",
    src: "20.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 220,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 20,
    },
    category: "broom",
    src: "21.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 240,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom22: {
    candyMoveNeed: 1500,
    category: "broom",
    src: "22.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 250,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom23: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 7,
    },
    category: "broom",
    src: "23.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 22,
      accuracy: 19,
      evasion: 22,
      speed: 19,
    },
  },
  broom24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 12,
    },
    category: "broom",
    src: "24.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 320,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 22,
      accuracy: 19,
      evasion: 22,
      speed: 19,
    },
  },

  broom25: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 18,
    },
    category: "broom",
    src: "25.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 360,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 23,
      accuracy: 20,
      evasion: 23,
      speed: 20,
    },
  },
  broom26: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 7,
    },
    category: "broom",
    src: "26.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 450,
    },
    characteristic: {
      health: 21,
      force: 21,
      power: 21,
      protection: 27,
      accuracy: 21,
      evasion: 27,
      speed: 21,
    },
  },
  broom27: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 9,
    },
    category: "broom",
    src: "27.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 500,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 28,
      accuracy: 22,
      evasion: 28,
      speed: 22,
    },
  },

  broom28: {
    lvlNeed: 60,
    category: "broom",
    src: "28.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 550,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },

  broom29: {
    candyMoveNeed: 5000,
    category: "broom",
    src: "29.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  broomTreasure1: {
    category: "broom",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2132,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure2: {
    category: "broom",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2122,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure3: {
    category: "broom",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2112,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure4: {
    category: "broom",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1032,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure5: {
    category: "broom",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1022,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure6: {
    category: "broom",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1012,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure7: {
    category: "broom",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0342,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure8: {
    category: "broom",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0332,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure9: {
    category: "broom",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0322,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure10: {
    category: "broom",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0312,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure11: {
    category: "broom",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0112,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  broomTreasure12: {
    category: "broom",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0112,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  broomTreasure13: {
    category: "broom",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.00512,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },

  broomPrize1: {
    category: "broom",
    src: "Prize/Prize1.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 500,
      evasion: 500,
      speed: 30,
    },
  },

  broomPrize2: {
    category: "broom",
    src: "Prize/Prize2.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 550,
      accuracy: 40,
      evasion: 550,
      speed: 40,
    },
  },
  broomEvent1: {
    category: "broom",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Летний ивент",
    diamond: 10,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 40,
      accuracy: 30,
      evasion: 40,
      speed: 30,
    },
  },
  broomEvent2: {
    category: "broom",
    src: "Event/Event2.png",
    lvl: 0,
    event: "Зимний ивент",
    diamond: 3000,
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },
  broomEvent3: {
    category: "broom",
    src: "Event/Event3.png",
    lvl: 0,
    event: "Ивент 23 февраля",
    diamond: 1500,
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },
};

export const clothes2 = {
  "shirt1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt4-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt5-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "shirt",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "shirt6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt7-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt8-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt9-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt11-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt12-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt13-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt14-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt15-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt16-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt17-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt18-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "shirt",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 49,
      protection: 44,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "shirt19-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt20-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt21-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt22-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt23-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt24-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt25-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt26-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt27-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt28-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt29-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt30-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt31-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt32-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt33-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt34-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },

  "shirt35-2": {
    game21Comb: {
      name: "Два Туза",
      count: 20,
      property: "ace",
    },
    category: "shirt",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt36-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "36.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt37-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "37.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt38-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "38.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "shirt39-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "39.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "shirt40-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "40.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 32,
      force: 32,
      power: 37,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "shirtEvent1-2": {
    event: "Шут",
    category: "shirt",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "shirtEvent2-2": {
    event: "Турнир кости/21",
    category: "shirt",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 115,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "pants1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants4-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "pants5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants7-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants8-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants9-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "pants10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants11-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants12-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants13-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants14-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants15-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants16-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants17-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 46,
      force: 46,
      power: 46,
      protection: 53,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "pants18-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants19-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants20-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants21-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants22-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 59,
      force: 59,
      power: 59,
      protection: 67,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "pants23-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants24-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants25-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants26-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants27-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants28-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants29-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants30-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants31-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants32-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants33-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants34-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },

  "pants35-2": {
    game21Comb: {
      name: "678",
      count: 20,
      property: "678",
    },
    category: "pants",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants36-2": {
    diceGameTournament: true,
    category: "pants",
    src: "36.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants37-2": {
    diceGameTournament: true,
    category: "pants",
    src: "37.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants38-2": {
    diceGameTournament: true,
    category: "pants",
    src: "38.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 37,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "pants39-2": {
    diceGameTournament: true,
    category: "pants",
    src: "39.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 37,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "pants40-2": {
    diceGameTournament: true,
    category: "pants",
    src: "40.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 39,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "pantsEvent1-2": {
    event: "Шут",
    category: "pants",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 90,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "pantsEvent2-2": {
    event: "Турнир кости/21",
    category: "pants",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 115,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "hand1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },

  "hand29-2": {
    game21Comb: {
      name: "777",
      count: 20,
      property: "777",
    },
    zMore: {
      man: true,
      woman: true,
    },
    category: "hand",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 36,
      force: 31,
      power: 31,
      protection: 31,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "hand30-2": {
    game21Tournament: true,
    category: "hand",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 38,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "hand31-2": {
    game21Tournament: true,
    category: "hand",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand32-2": {
    game21Tournament: true,
    category: "hand",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand33-2": {
    game21Tournament: true,
    category: "hand",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand34-2": {
    game21Tournament: true,
    category: "hand",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand35-2": {
    game21Tournament: true,
    category: "hand",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 43,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 38,
      evasion: 38,
      speed: 38,
    },
  },

  "handEvent1-2": {
    event: "Шут",
    category: "hand",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 90,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "handEvent2-2": {
    event: "Турнир кости/21",
    category: "hand",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 115,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "shoes1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    category: "shoes",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes29-2": {
    game21Comb: {
      name: "5 картинок",
      count: 20,
      property: "img",
    },
    category: "shoes",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    characteristic: {
      health: 34,
      force: 34,
      power: 34,
      protection: 34,
      accuracy: 34,
      evasion: 40,
      speed: 40,
    },
  },

  "shoes30-2": {
    game21Tournament: true,
    category: "shoes",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  "shoes31-2": {
    game21Tournament: true,
    category: "shoes",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes32-2": {
    game21Tournament: true,
    category: "shoes",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes33-2": {
    game21Tournament: true,
    category: "shoes",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes34-2": {
    game21Tournament: true,
    category: "shoes",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes35-2": {
    game21Tournament: true,
    category: "shoes",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    characteristic: {
      health: 34,
      force: 34,
      power: 34,
      protection: 34,
      accuracy: 34,
      evasion: 40,
      speed: 40,
    },
  },

  "shoesEvent1-2": {
    event: "Шут",
    category: "shoes",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 80,
      evasion: 90,
      speed: 90,
    },
  },

  "shoesEvent2-2": {
    swap: {
      man: false,
      woman: true,
    },
    event: "Турнир кости/21",
    category: "shoes",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 115,
      speed: 115,
    },
  },

  "head1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 14,
      evasion: 12,
      speed: 12,
    },
  },
  "head2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 17,
      evasion: 14,
      speed: 14,
    },
  },
  "head3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 17,
      evasion: 14,
      speed: 14,
    },
  },
  "head4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 26,
      evasion: 22,
      speed: 22,
    },
  },
  "head5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 29,
      evasion: 24,
      speed: 24,
    },
  },
  "head6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 29,
      evasion: 24,
      speed: 24,
    },
  },
  "head7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 55,
      evasion: 48,
      speed: 48,
    },
  },
  "head12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 170000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 57,
      evasion: 50,
      speed: 50,
    },
  },
  "head13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 170000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 57,
      evasion: 50,
      speed: 50,
    },
  },
  "head14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 70,
      evasion: 62,
      speed: 62,
    },
  },
  "head15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 214000,
    },
    characteristic: {
      health: 64,
      force: 64,
      power: 64,
      protection: 64,
      accuracy: 72,
      evasion: 64,
      speed: 64,
    },
  },
  "head16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 214000,
    },
    characteristic: {
      health: 64,
      force: 64,
      power: 64,
      protection: 64,
      accuracy: 72,
      evasion: 64,
      speed: 64,
    },
  },
  "head17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head29-2": {
    diceGameComb: {
      name: "4 четвёрки",
      count: 20,
      property: "4444",
    },
    category: "head",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },

  "head30-2": {
    game21Tournament: true,
    category: "head",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 36,
      evasion: 30,
      speed: 30,
    },
  },

  "head31-2": {
    game21Tournament: true,
    category: "head",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head32-2": {
    game21Tournament: true,
    category: "head",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head33-2": {
    game21Tournament: true,
    category: "head",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head34-2": {
    game21Tournament: true,
    category: "head",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head35-2": {
    game21Tournament: true,
    category: "head",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },

  "headEvent1-2": {
    swap: {
      man: true,
      woman: true,
    },
    event: "Шут",
    category: "head",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 90,
      evasion: 80,
      speed: 80,
    },
  },

  "headEvent2-2": {
    swap: {
      man: false,
      woman: true,
    },
    event: "Турнир кости/21",
    category: "head",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 115,
      evasion: 100,
      speed: 100,
    },
  },

  "wand1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "1.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  "wand2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "2.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  "wand3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "3.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 18,
      power: 18,
      protection: 15,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  "wand4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "4.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 480,
    },
    characteristic: {
      health: 24,
      force: 29,
      power: 29,
      protection: 24,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  "wand5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "5.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 480,
    },
    characteristic: {
      health: 24,
      force: 29,
      power: 29,
      protection: 24,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  "wand6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "6.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 31,
      power: 31,
      protection: 26,
      accuracy: 26,
      evasion: 26,
      speed: 26,
    },
  },
  "wand7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "7.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 46,
      power: 46,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "wand8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "8.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 46,
      power: 46,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "wand9-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "9.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand10-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "10.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "11.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand12-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "12.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1280,
    },
    characteristic: {
      health: 64,
      force: 72,
      power: 72,
      protection: 64,
      accuracy: 64,
      evasion: 64,
      speed: 64,
    },
  },
  "wand13-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "13.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1280,
    },
    characteristic: {
      health: 64,
      force: 72,
      power: 72,
      protection: 64,
      accuracy: 64,
      evasion: 64,
      speed: 64,
    },
  },
  "wand14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "14.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 74,
      power: 74,
      protection: 66,
      accuracy: 66,
      evasion: 66,
      speed: 66,
    },
  },
  "wand15-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "15.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 88,
      power: 88,
      protection: 78,
      accuracy: 78,
      evasion: 78,
      speed: 78,
    },
  },
  "wand16-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "16.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 88,
      power: 88,
      protection: 78,
      accuracy: 78,
      evasion: 78,
      speed: 78,
    },
  },
  "wand17-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "17.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 101,
      power: 101,
      protection: 91,
      accuracy: 91,
      evasion: 91,
      speed: 91,
    },
  },
  "wand18-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "18.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 101,
      power: 101,
      protection: 91,
      accuracy: 91,
      evasion: 91,
      speed: 91,
    },
  },
  "wand19-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "19.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 115,
      power: 115,
      protection: 105,
      accuracy: 105,
      evasion: 105,
      speed: 105,
    },
  },
  "wand20-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "20.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 115,
      power: 115,
      protection: 105,
      accuracy: 105,
      evasion: 105,
      speed: 105,
    },
  },

  "wand21-2": {
    diceGameComb: {
      name: "4 пятёрки",
      count: 20,
      property: "5555",
    },
    category: "wand",
    src: "21.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 36,
      power: 36,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "wand22-2": {
    diceGameTournament: true,
    category: "wand",
    src: "22.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 36,
      power: 36,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "wand23-2": {
    diceGameTournament: true,
    category: "wand",
    src: "23.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand24-2": {
    diceGameTournament: true,
    category: "wand",
    src: "24.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand25-2": {
    diceGameTournament: true,
    category: "wand",
    src: "25.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand26-2": {
    diceGameTournament: true,
    category: "wand",
    src: "26.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 41,
      power: 41,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "wandEvent1-2": {
    event: "Шут",
    category: "wand",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 90,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "wandEvent2-2": {
    event: "Турнир кости/21",
    category: "wand",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 115,
      power: 115,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "broom1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "1.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "2.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "3.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "4.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "5.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "6.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "7.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 46,
      accuracy: 40,
      evasion: 46,
      speed: 40,
    },
  },
  "broom8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "8.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 46,
      accuracy: 40,
      evasion: 46,
      speed: 40,
    },
  },
  "broom9-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "9.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom10-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "10.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "11.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom12-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "12.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom13-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "13.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "14.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom15-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "15.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 78,
      power: 78,
      protection: 88,
      accuracy: 78,
      evasion: 88,
      speed: 78,
    },
  },
  "broom16-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.032,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "16.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 78,
      power: 78,
      protection: 88,
      accuracy: 78,
      evasion: 88,
      speed: 78,
    },
  },
  "broom17-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "17.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 91,
      power: 91,
      protection: 101,
      accuracy: 91,
      evasion: 101,
      speed: 91,
    },
  },
  "broom18-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "18.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 91,
      power: 91,
      protection: 101,
      accuracy: 91,
      evasion: 101,
      speed: 91,
    },
  },
  "broom19-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "19.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 105,
      power: 105,
      protection: 115,
      accuracy: 105,
      evasion: 115,
      speed: 105,
    },
  },
  "broom20-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "20.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 105,
      power: 105,
      protection: 115,
      accuracy: 105,
      evasion: 115,
      speed: 105,
    },
  },
  "broom21-2": {
    diceGameComb: {
      name: "4 шестёрки",
      count: 20,
      property: "6666",
    },
    category: "broom",
    src: "21.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 42,
      accuracy: 35,
      evasion: 42,
      speed: 35,
    },
  },

  "broom22-2": {
    diceGameTournament: true,
    category: "broom",
    src: "22.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  "broom23-2": {
    diceGameTournament: true,
    category: "broom",
    src: "23.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom24-2": {
    diceGameTournament: true,
    category: "broom",
    src: "24.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom25-2": {
    diceGameTournament: true,
    category: "broom",
    src: "25.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom26-2": {
    diceGameTournament: true,
    category: "broom",
    src: "26.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 42,
      accuracy: 35,
      evasion: 42,
      speed: 35,
    },
  },

  "broomEvent1-2": {
    event: "Шут",
    category: "broom",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 90,
      accuracy: 80,
      evasion: 90,
      speed: 80,
    },
  },

  "broomEvent2-2": {
    event: "Турнир кости/21",
    category: "broom",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 115,
      accuracy: 100,
      evasion: 115,
      speed: 100,
    },
  },
};

export const clothes3 = {
  "shirt1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "1.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 120,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "shirt2-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "2.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 120,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "shirt3-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "3.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 240,
      protection: 200,
      accuracy: 200,
      evasion: 200,
      speed: 200,
    },
  },

  "shirt4-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "4.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 240,
      protection: 200,
      accuracy: 200,
      evasion: 200,
      speed: 200,
    },
  },

  "shirt5-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "5.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 400,
      force: 400,
      power: 480,
      protection: 400,
      accuracy: 400,
      evasion: 400,
      speed: 400,
    },
  },

  "shirt6-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "6.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 400,
      force: 400,
      power: 480,
      protection: 400,
      accuracy: 400,
      evasion: 400,
      speed: 400,
    },
  },

  "shirt7-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "7.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 700,
      force: 700,
      power: 840,
      protection: 700,
      accuracy: 700,
      evasion: 700,
      speed: 700,
    },
  },

  "shirt8-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "8.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 700,
      force: 700,
      power: 840,
      protection: 700,
      accuracy: 700,
      evasion: 700,
      speed: 700,
    },
  },

  "shirt9-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "9.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 1100,
      force: 1100,
      power: 1320,
      protection: 1100,
      accuracy: 1100,
      evasion: 1100,
      speed: 1100,
    },
  },

  "shirt10-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "10.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 1100,
      force: 1100,
      power: 1320,
      protection: 1100,
      accuracy: 1100,
      evasion: 1100,
      speed: 1100,
    },
  },

  "shirt11-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "11.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 1600,
      force: 1600,
      power: 1920,
      protection: 1600,
      accuracy: 1600,
      evasion: 1600,
      speed: 1600,
    },
  },

  "shirt12-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "12.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 1600,
      force: 1600,
      power: 1920,
      protection: 1600,
      accuracy: 1600,
      evasion: 1600,
      speed: 1600,
    },
  },

  "shirt13-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "13.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 2200,
      force: 2200,
      power: 2640,
      protection: 2200,
      accuracy: 2200,
      evasion: 2200,
      speed: 2200,
    },
  },

  "shirt14-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "14.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 2200,
      force: 2200,
      power: 2640,
      protection: 2200,
      accuracy: 2200,
      evasion: 2200,
      speed: 2200,
    },
  },

  "shirt15-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "15.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 2900,
      force: 2900,
      power: 3480,
      protection: 2900,
      accuracy: 2900,
      evasion: 2900,
      speed: 2900,
    },
  },

  "shirt16-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "shirt",
    src: "16.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 2900,
      force: 2900,
      power: 3480,
      protection: 2900,
      accuracy: 2900,
      evasion: 2900,
      speed: 2900,
    },
  },

  "pants1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "1.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  "pants2-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "2.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  "pants3-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "3.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 120,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "pants4-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "4.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 120,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "pants5-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "5.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 200,
      protection: 240,
      accuracy: 200,
      evasion: 200,
      speed: 200,
    },
  },

  "pants6-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "6.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 200,
      protection: 240,
      accuracy: 200,
      evasion: 200,
      speed: 200,
    },
  },

  "pants7-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "7.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 350,
      force: 350,
      power: 350,
      protection: 420,
      accuracy: 350,
      evasion: 350,
      speed: 350,
    },
  },

  "pants8-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "8.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 350,
      force: 350,
      power: 350,
      protection: 420,
      accuracy: 350,
      evasion: 350,
      speed: 350,
    },
  },

  "pants9-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "9.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 550,
      force: 550,
      power: 550,
      protection: 660,
      accuracy: 550,
      evasion: 550,
      speed: 550,
    },
  },

  "pants10-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "10.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 550,
      force: 550,
      power: 550,
      protection: 660,
      accuracy: 550,
      evasion: 550,
      speed: 550,
    },
  },

  "pants11-3": {
    zIndex: {
      man: 2,
      woman: 2,
    },
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "11.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 800,
      force: 800,
      power: 800,
      protection: 920,
      accuracy: 800,
      evasion: 800,
      speed: 800,
    },
  },

  "pants12-3": {
    zIndex: {
      man: 2,
      woman: 2,
    },
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "12.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 800,
      force: 800,
      power: 800,
      protection: 920,
      accuracy: 800,
      evasion: 800,
      speed: 800,
    },
  },

  "pants13-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "13.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 1100,
      force: 1100,
      power: 1100,
      protection: 1320,
      accuracy: 1100,
      evasion: 1100,
      speed: 1100,
    },
  },

  "pants14-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "14.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 1100,
      force: 1100,
      power: 1100,
      protection: 1320,
      accuracy: 1100,
      evasion: 1100,
      speed: 1100,
    },
  },

  "pants15-3": {
    zIndex: {
      man: 2,
      woman: 2,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "15.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 1450,
      force: 1450,
      power: 1450,
      protection: 1740,
      accuracy: 1450,
      evasion: 1450,
      speed: 1450,
    },
  },

  "pants16-3": {
    zIndex: {
      man: 2,
      woman: 2,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "pants",
    src: "16.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 1450,
      force: 1450,
      power: 1450,
      protection: 1740,
      accuracy: 1450,
      evasion: 1450,
      speed: 1450,
    },
  },

  "hand1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "1.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3600000,
    },
    characteristic: {
      health: 600,
      force: 500,
      power: 500,
      protection: 500,
      accuracy: 500,
      evasion: 500,
      speed: 500,
    },
  },

  "hand2-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "2.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3600000,
    },
    characteristic: {
      health: 600,
      force: 500,
      power: 500,
      protection: 500,
      accuracy: 500,
      evasion: 500,
      speed: 500,
    },
  },

  "hand3-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "3.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 9000000,
    },
    characteristic: {
      health: 1200,
      force: 1000,
      power: 1000,
      protection: 1000,
      accuracy: 1000,
      evasion: 1000,
      speed: 1000,
    },
  },

  "hand4-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "4.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 9000000,
    },
    characteristic: {
      health: 1200,
      force: 1000,
      power: 1000,
      protection: 1000,
      accuracy: 1000,
      evasion: 1000,
      speed: 1000,
    },
  },

  "hand5-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "5.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000000,
    },

    characteristic: {
      health: 2400,
      force: 2000,
      power: 2000,
      protection: 2000,
      accuracy: 2000,
      evasion: 2000,
      speed: 2000,
    },
  },

  "hand6-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "6.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000000,
    },
    characteristic: {
      health: 2400,
      force: 2000,
      power: 2000,
      protection: 2000,
      accuracy: 2000,
      evasion: 2000,
      speed: 2000,
    },
  },

  "hand7-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "7.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 43000000,
    },

    characteristic: {
      health: 4200,
      force: 2500,
      power: 2500,
      protection: 2500,
      accuracy: 2500,
      evasion: 2500,
      speed: 2500,
    },
  },

  "hand8-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "8.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 43000000,
    },
    characteristic: {
      health: 4200,
      force: 2500,
      power: 2500,
      protection: 2500,
      accuracy: 2500,
      evasion: 2500,
      speed: 2500,
    },
  },

  "hand9-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "9.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 108000000,
    },

    characteristic: {
      health: 6600,
      force: 5500,
      power: 5500,
      protection: 5500,
      accuracy: 5500,
      evasion: 5500,
      speed: 5500,
    },
  },

  "hand10-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "10.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 108000000,
    },
    characteristic: {
      health: 6600,
      force: 5500,
      power: 5500,
      protection: 5500,
      accuracy: 5500,
      evasion: 5500,
      speed: 5500,
    },
  },

  "hand11-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "11.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 288000000,
    },

    characteristic: {
      health: 9600,
      force: 8000,
      power: 8000,
      protection: 8000,
      accuracy: 8000,
      evasion: 8000,
      speed: 8000,
    },
  },

  "hand12-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "12.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 288000000,
    },

    characteristic: {
      health: 9600,
      force: 8000,
      power: 8000,
      protection: 8000,
      accuracy: 8000,
      evasion: 8000,
      speed: 8000,
    },
  },

  "hand13-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "13.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 648000000,
    },

    characteristic: {
      health: 13200,
      force: 11000,
      power: 11000,
      protection: 11000,
      accuracy: 11000,
      evasion: 11000,
      speed: 11000,
    },
  },

  "hand14-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "14.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 648000000,
    },
    characteristic: {
      health: 13200,
      force: 11000,
      power: 11000,
      protection: 11000,
      accuracy: 11000,
      evasion: 11000,
      speed: 11000,
    },
  },

  "hand15-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "15.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1800000000,
    },

    characteristic: {
      health: 17400,
      force: 14500,
      power: 14500,
      protection: 14500,
      accuracy: 14500,
      evasion: 14500,
      speed: 14500,
    },
  },

  "hand16-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "hand",
    src: "16.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1800000000,
    },
    characteristic: {
      health: 17400,
      force: 14500,
      power: 14500,
      protection: 14500,
      accuracy: 14500,
      evasion: 14500,
      speed: 14500,
    },
  },

  "shoes1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "1.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3200000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 200,
      protection: 200,
      accuracy: 200,
      evasion: 240,
      speed: 240,
    },
  },

  "shoes2-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "2.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3200000,
    },
    characteristic: {
      health: 200,
      force: 200,
      power: 200,
      protection: 200,
      accuracy: 200,
      evasion: 240,
      speed: 240,
    },
  },

  "shoes3-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "3.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000000,
    },
    characteristic: {
      health: 400,
      force: 400,
      power: 400,
      protection: 400,
      accuracy: 400,
      evasion: 480,
      speed: 480,
    },
  },

  "shoes4-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "4.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000000,
    },
    characteristic: {
      health: 400,
      force: 400,
      power: 400,
      protection: 400,
      accuracy: 400,
      evasion: 480,
      speed: 480,
    },
  },

  "shoes5-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "5.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000000,
    },
    characteristic: {
      health: 800,
      force: 800,
      power: 800,
      protection: 800,
      accuracy: 800,
      evasion: 960,
      speed: 960,
    },
  },

  "shoes6-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "6.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000000,
    },
    characteristic: {
      health: 800,
      force: 800,
      power: 800,
      protection: 800,
      accuracy: 800,
      evasion: 960,
      speed: 960,
    },
  },

  "shoes7-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "7.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000000,
    },
    characteristic: {
      health: 1400,
      force: 1400,
      power: 1400,
      protection: 1400,
      accuracy: 1400,
      evasion: 1680,
      speed: 1680,
    },
  },

  "shoes8-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "8.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000000,
    },
    characteristic: {
      health: 1400,
      force: 1400,
      power: 1400,
      protection: 1400,
      accuracy: 1400,
      evasion: 1680,
      speed: 1680,
    },
  },

  "shoes9-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "9.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 2200,
      force: 2200,
      power: 2200,
      protection: 2200,
      accuracy: 2200,
      evasion: 2640,
      speed: 2640,
    },
  },

  "shoes10-3": {
    zMore: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "10.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 2200,
      force: 2200,
      power: 2200,
      protection: 2200,
      accuracy: 2200,
      evasion: 2640,
      speed: 2640,
    },
  },

  "shoes11-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "11.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 256000000,
    },
    characteristic: {
      health: 3200,
      force: 3200,
      power: 3200,
      protection: 3200,
      accuracy: 3200,
      evasion: 3840,
      speed: 3840,
    },
  },

  "shoes12-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "12.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 256000000,
    },
    characteristic: {
      health: 3200,
      force: 3200,
      power: 3200,
      protection: 3200,
      accuracy: 3200,
      evasion: 3840,
      speed: 3840,
    },
  },

  "shoes13-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "13.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 576000000,
    },
    characteristic: {
      health: 4400,
      force: 4400,
      power: 4400,
      protection: 4400,
      accuracy: 4400,
      evasion: 5280,
      speed: 5280,
    },
  },

  "shoes14-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "14.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 576000000,
    },
    characteristic: {
      health: 4400,
      force: 4400,
      power: 4400,
      protection: 4400,
      accuracy: 4400,
      evasion: 5280,
      speed: 5280,
    },
  },

  "shoes15-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "15.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1600000000,
    },
    characteristic: {
      health: 5600,
      force: 5600,
      power: 5600,
      protection: 5600,
      accuracy: 5600,
      evasion: 6720,
      speed: 6720,
    },
  },

  "shoes16-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "shoes",
    src: "16.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1600000000,
    },
    characteristic: {
      health: 5600,
      force: 5600,
      power: 5600,
      protection: 5600,
      accuracy: 5600,
      evasion: 6720,
      speed: 6720,
    },
  },

  "head1-3": {
    swap: {
      man: false,
      woman: true,
    },
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "1.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 250,
      force: 250,
      power: 250,
      protection: 250,
      accuracy: 300,
      evasion: 250,
      speed: 250,
    },
  },

  "head2-3": {
    swap: {
      man: false,
      woman: true,
    },
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "2.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 1200000,
    },
    characteristic: {
      health: 250,
      force: 250,
      power: 250,
      protection: 250,
      accuracy: 300,
      evasion: 250,
      speed: 250,
    },
  },

  "head3-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "3.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 500,
      force: 500,
      power: 500,
      protection: 500,
      accuracy: 600,
      evasion: 500,
      speed: 500,
    },
  },

  "head4-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "4.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000000,
    },
    characteristic: {
      health: 500,
      force: 500,
      power: 500,
      protection: 500,
      accuracy: 600,
      evasion: 500,
      speed: 500,
    },
  },

  "head5-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "5.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 1000,
      force: 1000,
      power: 1000,
      protection: 1000,
      accuracy: 1200,
      evasion: 1000,
      speed: 1000,
    },
  },

  "head6-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "6.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000000,
    },
    characteristic: {
      health: 1000,
      force: 1000,
      power: 1000,
      protection: 1000,
      accuracy: 1200,
      evasion: 1000,
      speed: 1000,
    },
  },

  "head7-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "7.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 1750,
      force: 1750,
      power: 1750,
      protection: 1750,
      accuracy: 2100,
      evasion: 1750,
      speed: 1750,
    },
  },

  "head8-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "8.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000000,
    },
    characteristic: {
      health: 1750,
      force: 1750,
      power: 1750,
      protection: 1750,
      accuracy: 2100,
      evasion: 1750,
      speed: 1750,
    },
  },

  "head9-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "9.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 2750,
      force: 2750,
      power: 2750,
      protection: 2750,
      accuracy: 3300,
      evasion: 2750,
      speed: 2750,
    },
  },

  "head10-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "10.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000000,
    },
    characteristic: {
      health: 2750,
      force: 2750,
      power: 2750,
      protection: 2750,
      accuracy: 3300,
      evasion: 2750,
      speed: 2750,
    },
  },

  "head11-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "11.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 4000,
      force: 4000,
      power: 4000,
      protection: 4000,
      accuracy: 4800,
      evasion: 4000,
      speed: 4000,
    },
  },

  "head12-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "12.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 96000000,
    },
    characteristic: {
      health: 4000,
      force: 4000,
      power: 4000,
      protection: 4000,
      accuracy: 4800,
      evasion: 4000,
      speed: 4000,
    },
  },

  "head13-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "13.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 5500,
      force: 5500,
      power: 5500,
      protection: 5500,
      accuracy: 6600,
      evasion: 5500,
      speed: 5500,
    },
  },

  "head14-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "14.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 216000000,
    },
    characteristic: {
      health: 5500,
      force: 5500,
      power: 5500,
      protection: 5500,
      accuracy: 6600,
      evasion: 5500,
      speed: 5500,
    },
  },

  "head15-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "15.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 7250,
      force: 7250,
      power: 7250,
      protection: 7250,
      accuracy: 8700,
      evasion: 7250,
      speed: 7250,
    },
  },

  "head16-3": {
    swap: {
      man: true,
      woman: true,
    },
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "head",
    src: "16.png",
    lvl: 2,
    price: {
      property: "silver",
      name: "серебра",
      count: 600000000,
    },
    characteristic: {
      health: 7250,
      force: 7250,
      power: 7250,
      protection: 7250,
      accuracy: 8700,
      evasion: 7250,
      speed: 7250,
    },
  },

  "wand1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "1.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 20000,
    },
    characteristic: {
      health: 1000,
      force: 1200,
      power: 1200,
      protection: 1000,
      accuracy: 1000,
      evasion: 1000,
      speed: 1000,
    },
  },

  "wand2-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "2.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 40000,
    },
    characteristic: {
      health: 2000,
      force: 2400,
      power: 2400,
      protection: 2000,
      accuracy: 2000,
      evasion: 2000,
      speed: 2000,
    },
  },

  "wand3-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "3.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 80000,
    },

    characteristic: {
      health: 4000,
      force: 4800,
      power: 4800,
      protection: 4000,
      accuracy: 4000,
      evasion: 4000,
      speed: 4000,
    },
  },

  "wand4-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "4.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 140000,
    },
    characteristic: {
      health: 7000,
      force: 8400,
      power: 8400,
      protection: 7000,
      accuracy: 7000,
      evasion: 7000,
      speed: 7000,
    },
  },

  "wand5-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "5.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 220000,
    },
    characteristic: {
      health: 11000,
      force: 13200,
      power: 13200,
      protection: 11000,
      accuracy: 11000,
      evasion: 11000,
      speed: 11000,
    },
  },

  "wand6-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "6.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 320000,
    },

    characteristic: {
      health: 16000,
      force: 19200,
      power: 19200,
      protection: 16000,
      accuracy: 16000,
      evasion: 16000,
      speed: 16000,
    },
  },

  "wand7-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "7.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 440000,
    },
    characteristic: {
      health: 22000,
      force: 26400,
      power: 26400,
      protection: 22000,
      accuracy: 22000,
      evasion: 22000,
      speed: 22000,
    },
  },

  "wand8-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "8.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 560000,
    },
    characteristic: {
      health: 29000,
      force: 34800,
      power: 34800,
      protection: 29000,
      accuracy: 29000,
      evasion: 29000,
      speed: 29000,
    },
  },

  "broom1-3": {
    boss2Random: {
      name: "Корреспондента",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "1.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 20000,
    },
    characteristic: {
      health: 1000,
      force: 1200,
      power: 1200,
      protection: 1000,
      accuracy: 1000,
      evasion: 1000,
      speed: 1000,
    },
  },

  "broom2-3": {
    boss2Random: {
      name: "Проклятого эльфа",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "2.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 40000,
    },
    characteristic: {
      health: 2000,
      force: 2400,
      power: 2400,
      protection: 2000,
      accuracy: 2000,
      evasion: 2000,
      speed: 2000,
    },
  },

  "broom3-3": {
    boss2Random: {
      name: "Зуфу",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "3.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 80000,
    },

    characteristic: {
      health: 4000,
      force: 4800,
      power: 4800,
      protection: 4000,
      accuracy: 4000,
      evasion: 4000,
      speed: 4000,
    },
  },

  "broom4-3": {
    boss2Random: {
      name: "Двухголового",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "4.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 140000,
    },
    characteristic: {
      health: 7000,
      force: 8400,
      power: 8400,
      protection: 7000,
      accuracy: 7000,
      evasion: 7000,
      speed: 7000,
    },
  },

  "broom5-3": {
    boss2Random: {
      name: "Аркейна",
      number: 4,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "5.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 220000,
    },
    characteristic: {
      health: 11000,
      force: 13200,
      power: 13200,
      protection: 11000,
      accuracy: 11000,
      evasion: 11000,
      speed: 11000,
    },
  },

  "broom6-3": {
    boss2Random: {
      name: "Гриндиллоу",
      number: 5,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "6.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 320000,
    },

    characteristic: {
      health: 16000,
      force: 19200,
      power: 19200,
      protection: 16000,
      accuracy: 16000,
      evasion: 16000,
      speed: 16000,
    },
  },

  "broom7-3": {
    boss2Random: {
      name: "Хвоста",
      number: 6,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "7.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 440000,
    },
    characteristic: {
      health: 22000,
      force: 26400,
      power: 26400,
      protection: 22000,
      accuracy: 22000,
      evasion: 22000,
      speed: 22000,
    },
  },

  "broom8-3": {
    boss2Random: {
      name: "Демментора",
      number: 7,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "8.png",
    lvl: 2,
    price: {
      property: "gold",
      name: "золота",
      count: 560000,
    },
    characteristic: {
      health: 29000,
      force: 34800,
      power: 34800,
      protection: 29000,
      accuracy: 29000,
      evasion: 29000,
      speed: 29000,
    },
  },
};

export const clothes2Treasures = {
  "shirtTreasure1-2": {
    category: "shirt",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0511,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure2-2": {
    category: "shirt",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0521,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure3-2": {
    category: "shirt",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0531,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure4-2": {
    category: "shirt",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0541,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure5-2": {
    category: "shirt",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0411,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure6-2": {
    category: "shirt",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0421,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure7-2": {
    category: "shirt",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0431,
    },

    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure8-2": {
    category: "shirt",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0441,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure9-2": {
    category: "shirt",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0451,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure10-2": {
    category: "shirt",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0461,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure11-2": {
    category: "shirt",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0211,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure12-2": {
    category: "shirt",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0221,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure13-2": {
    category: "shirt",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0231,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure14-2": {
    category: "shirt",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0251,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure15-2": {
    category: "shirt",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0261,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure16-2": {
    category: "shirt",
    src: "Treasure/Treasure16.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0271,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure17-2": {
    category: "shirt",
    src: "Treasure/Treasure17.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0281,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure18-2": {
    category: "shirt",
    src: "Treasure/Treasure18.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0291,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "shirtTreasure19-2": {
    category: "shirt",
    src: "Treasure/Treasure19.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0111,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 90,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "shirtTreasure20-2": {
    category: "shirt",
    src: "Treasure/Treasure20.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00511,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 100,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "pantsTreasure1-2": {
    category: "pants",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0512,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "pantsTreasure2-2": {
    category: "pants",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0522,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "pantsTreasure3-2": {
    category: "pants",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0412,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure4-2": {
    category: "pants",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0422,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure5-2": {
    category: "pants",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0312,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure6-2": {
    category: "pants",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0322,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure7-2": {
    category: "pants",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0332,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure8-2": {
    category: "pants",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0342,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure9-2": {
    category: "pants",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0352,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure10-2": {
    category: "pants",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0352,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure11-2": {
    category: "pants",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0212,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure12-2": {
    category: "pants",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0222,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure13-2": {
    category: "pants",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0232,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure14-2": {
    category: "pants",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0242,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure15-2": {
    category: "pants",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0252,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure16-2": {
    category: "pants",
    src: "Treasure/Treasure16.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0262,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure17-2": {
    category: "pants",
    src: "Treasure/Treasure17.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0272,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure18-2": {
    category: "pants",
    src: "Treasure/Treasure18.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0282,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure19-2": {
    category: "pants",
    src: "Treasure/Treasure19.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0112,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 90,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pantsTreasure20-2": {
    category: "pants",
    src: "Treasure/Treasure20.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0122,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 90,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "handTreasure1-2": {
    category: "hand",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0513,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "handTreasure2-2": {
    category: "hand",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0523,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "handTreasure3-2": {
    category: "hand",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0413,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "handTreasure4-2": {
    category: "hand",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0423,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "handTreasure5-2": {
    category: "hand",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0313,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure6-2": {
    category: "hand",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0323,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure7-2": {
    category: "hand",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0333,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure8-2": {
    category: "hand",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0213,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure9-2": {
    category: "hand",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0223,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure10-2": {
    category: "hand",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0233,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure11-2": {
    category: "hand",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0243,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure12-2": {
    category: "hand",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0113,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 90,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "handTreasure13-2": {
    category: "hand",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0123,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 90,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "handTreasure14-2": {
    category: "hand",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0133,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 90,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "handTreasure15-2": {
    category: "hand",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0143,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 90,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "headTreasure1-2": {
    category: "head",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0514,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure2-2": {
    category: "head",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0524,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure3-2": {
    category: "head",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0534,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure4-2": {
    category: "head",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0414,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure5-2": {
    category: "head",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0424,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure6-2": {
    category: "head",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0434,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure7-2": {
    category: "head",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0434,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure8-2": {
    category: "head",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0314,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 50,
      evasion: 40,
      speed: 40,
    },
  },
  "headTreasure9-2": {
    category: "head",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0324,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 50,
      evasion: 40,
      speed: 40,
    },
  },
  "headTreasure10-2": {
    category: "head",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0214,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 60,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure11-2": {
    category: "head",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0114,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 80,
      evasion: 60,
      speed: 60,
    },
  },
  "headTreasure12-2": {
    category: "head",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0124,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 80,
      evasion: 60,
      speed: 60,
    },
  },
  "headTreasure13-2": {
    category: "head",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0134,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 80,
      evasion: 60,
      speed: 60,
    },
  },
  "headTreasure14-2": {
    category: "head",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0144,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 80,
      evasion: 60,
      speed: 60,
    },
  },
  "headTreasure15-2": {
    category: "head",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00514,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 90,
      evasion: 70,
      speed: 70,
    },
  },
  "shoesTreasure1-2": {
    category: "shoes",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0515,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 30,
      speed: 30,
    },
  },
  "shoesTreasure2-2": {
    category: "shoes",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0525,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 30,
      speed: 30,
    },
  },
  "shoesTreasure3-2": {
    category: "shoes",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0535,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 30,
      speed: 30,
    },
  },
  "shoesTreasure4-2": {
    category: "shoes",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0545,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 30,
      speed: 30,
    },
  },
  "shoesTreasure5-2": {
    category: "shoes",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0415,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure6-2": {
    category: "shoes",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0315,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure7-2": {
    category: "shoes",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0325,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure8-2": {
    category: "shoes",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0215,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 70,
    },
  },
  "shoesTreasure9-2": {
    category: "shoes",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0225,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 70,
    },
  },
  "shoesTreasure10-2": {
    category: "shoes",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0235,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 70,
    },
  },
  "shoesTreasure11-2": {
    category: "shoes",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0245,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 70,
    },
  },
  "shoesTreasure12-2": {
    category: "shoes",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0255,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 70,
    },
  },
  "shoesTreasure13-2": {
    category: "shoes",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0115,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 80,
    },
  },
  "shoesTreasure14-2": {
    category: "shoes",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0125,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 70,
      accuracy: 70,
      evasion: 70,
      speed: 80,
    },
  },
  "shoesTreasure15-2": {
    category: "shoes",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0135,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure1-2": {
    category: "wand",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0516,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure2-2": {
    category: "wand",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0526,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure3-2": {
    category: "wand",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0536,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure4-2": {
    category: "wand",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0546,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure5-2": {
    category: "wand",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0416,
    },
    characteristic: {
      health: 30,
      force: 40,
      power: 40,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "wandTreasure6-2": {
    category: "wand",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0426,
    },
    characteristic: {
      health: 30,
      force: 40,
      power: 40,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "wandTreasure7-2": {
    category: "wand",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0436,
    },
    characteristic: {
      health: 30,
      force: 40,
      power: 40,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "wandTreasure8-2": {
    category: "wand",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0316,
    },
    characteristic: {
      health: 50,
      force: 60,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wandTreasure9-2": {
    category: "wand",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0326,
    },
    characteristic: {
      health: 50,
      force: 60,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wandTreasure10-2": {
    category: "wand",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0116,
    },
    characteristic: {
      health: 60,
      force: 80,
      power: 80,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "wandTreasure11-2": {
    category: "wand",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00516,
    },
    characteristic: {
      health: 80,
      force: 100,
      power: 100,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "wandTreasure12-2": {
    category: "wand",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00526,
    },
    characteristic: {
      health: 80,
      force: 100,
      power: 100,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "wandTreasure13-2": {
    category: "wand",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00536,
    },
    characteristic: {
      health: 80,
      force: 100,
      power: 100,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "wandTreasure14-2": {
    category: "wand",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00546,
    },
    characteristic: {
      health: 80,
      force: 100,
      power: 100,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "broomTreasure1-2": {
    category: "broom",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0517,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure2-2": {
    category: "broom",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0527,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure3-2": {
    category: "broom",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0537,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure4-2": {
    category: "broom",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0417,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  "broomTreasure5-2": {
    category: "broom",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0427,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  "broomTreasure6-2": {
    category: "broom",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0437,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  "broomTreasure7-2": {
    category: "broom",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0317,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },
  "broomTreasure8-2": {
    category: "broom",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0327,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },
  "broomTreasure9-2": {
    category: "broom",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0337,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },
  "broomTreasure10-2": {
    category: "broom",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0347,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },
  "broomTreasure11-2": {
    category: "broom",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0217,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 80,
      accuracy: 60,
      evasion: 80,
      speed: 60,
    },
  },
  "broomTreasure12-2": {
    category: "broom",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0227,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 80,
      accuracy: 60,
      evasion: 80,
      speed: 60,
    },
  },
  "broomTreasure13-2": {
    category: "broom",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0117,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 90,
      accuracy: 70,
      evasion: 90,
      speed: 70,
    },
  },
  "broomTreasure14-2": {
    category: "broom",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00517,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 100,
      accuracy: 80,
      evasion: 100,
      speed: 80,
    },
  },
};

export const clothes3Treasures = {
  "shirtTreasure1-3": {
    category: "shirt",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00811,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure2-3": {
    category: "shirt",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00821,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure3-3": {
    category: "shirt",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00831,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure4-3": {
    category: "shirt",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00841,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure5-3": {
    category: "shirt",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00851,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure6-3": {
    category: "shirt",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00861,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure7-3": {
    category: "shirt",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00871,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure8-3": {
    category: "shirt",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00881,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "shirtTreasure9-3": {
    category: "shirt",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00891,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure1-3": {
    category: "pants",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00812,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure2-3": {
    category: "pants",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00822,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure3-3": {
    category: "pants",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00832,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure4-3": {
    category: "pants",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00842,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure5-3": {
    category: "pants",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00852,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure6-3": {
    category: "pants",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00862,
    },

    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure7-3": {
    category: "pants",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00872,
    },
    zIndex: {
      man: 3,
      woman: 3,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure8-3": {
    category: "pants",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00882,
    },
    zIndex: {
      man: 3,
      woman: 3,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "pantsTreasure9-3": {
    category: "pants",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00892,
    },
    zIndex: {
      man: 3,
      woman: 3,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure1-3": {
    category: "hand",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00713,
    },

    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure2-3": {
    category: "hand",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00723,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure3-3": {
    category: "hand",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00733,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure4-3": {
    category: "hand",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00743,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure5-3": {
    category: "hand",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00753,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure6-3": {
    category: "hand",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00763,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure7-3": {
    category: "hand",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00773,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure8-3": {
    category: "hand",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00783,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "handTreasure9-3": {
    category: "hand",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00793,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 180,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure1-3": {
    category: "head",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00714,
    },
    swap: {
      man: true,
      woman: true,
    },

    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure2-3": {
    category: "head",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00724,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure3-3": {
    category: "head",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00734,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure4-3": {
    category: "head",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00744,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure5-3": {
    category: "head",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00754,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure6-3": {
    category: "head",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00764,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure7-3": {
    category: "head",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00774,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure8-3": {
    category: "head",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00784,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "headTreasure9-3": {
    category: "head",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00794,
    },

    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 180,
      evasion: 150,
      speed: 150,
    },
  },

  "shoesTreasure1-3": {
    category: "shoes",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00615,
    },
    zMore: {
      man: true,
      woman: true,
    },

    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure2-3": {
    category: "shoes",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00625,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure3-3": {
    category: "shoes",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00635,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure4-3": {
    category: "shoes",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00645,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure5-3": {
    category: "shoes",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00655,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure6-3": {
    category: "shoes",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00665,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure7-3": {
    category: "shoes",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00675,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure8-3": {
    category: "shoes",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00685,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "shoesTreasure9-3": {
    category: "shoes",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00695,
    },
    zMore: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 150,
      accuracy: 150,
      evasion: 180,
      speed: 180,
    },
  },

  "wandTreasure1-3": {
    category: "wand",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00516,
    },

    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure2-3": {
    category: "wand",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00526,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure3-3": {
    category: "wand",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00536,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure4-3": {
    category: "wand",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00546,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure5-3": {
    category: "wand",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00556,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure6-3": {
    category: "wand",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00566,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure7-3": {
    category: "wand",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00576,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure8-3": {
    category: "wand",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00586,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "wandTreasure9-3": {
    category: "wand",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00596,
    },
    characteristic: {
      health: 150,
      force: 180,
      power: 180,
      protection: 150,
      accuracy: 150,
      evasion: 150,
      speed: 150,
    },
  },

  "broomTreasure1-3": {
    category: "broom",
    src: "Treasure/Treasure1.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00517,
    },

    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure2-3": {
    category: "broom",
    src: "Treasure/Treasure2.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00527,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure3-3": {
    category: "broom",
    src: "Treasure/Treasure3.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00537,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure4-3": {
    category: "broom",
    src: "Treasure/Treasure4.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00547,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure5-3": {
    category: "broom",
    src: "Treasure/Treasure5.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00557,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure6-3": {
    category: "broom",
    src: "Treasure/Treasure6.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00567,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure7-3": {
    category: "broom",
    src: "Treasure/Treasure7.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00577,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure8-3": {
    category: "broom",
    src: "Treasure/Treasure8.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00587,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },

  "broomTreasure9-3": {
    category: "broom",
    src: "Treasure/Treasure9.png",
    lvl: 2,
    treasure: {
      lvl: 2,
      chance: 0.00597,
    },
    characteristic: {
      health: 150,
      force: 150,
      power: 150,
      protection: 180,
      accuracy: 150,
      evasion: 180,
      speed: 150,
    },
  },
};

export const allClothes = {
  ...clothes1,
  ...clothes2,
  ...clothes2Treasures,
  ...clothes3,
  ...clothes3Treasures,
};

export const candies = [
  "армотенции",
  "безоар",
  "омелы",
  "белладонны",
  "бубонтюбер",
  "аконита",
  "горелой спички",
  "вяленого помидора",
  "окрошки",
  "красной икры",
  "полцелуя Дементора",
  "Азкабана",
  "мокрой собаки",
  "пыли",
  "глинтвейна",
  "сюрстрёмминга",
  "первокурсника",
  "берёзового сока",
  "соли",
  "крови единорога",
  "яйца грифона",
  "губки для мытья посуды",
  "слива в раковине",
  "отрыжки огра",
  "утренней росы",
  "райского наслаждения",
  "ароматного шашлыка",
  "первой любви",
  "шлака",
  "жареных пельменей",
  "цыпленка табака",
  "кошачей шерсти",
  "мази звездочка",
  "чернил",
  "забродившей вишни",
  "сосисок",
  "маршмеллоу",
  "ушной серы",
  "рвоты",
  "черники",
  "банана",
  "арбуза",
  "вишни",
  "корицы",
  "грязи",
  "мыла",
  "яблока",
  "дождевого червя",
  "сладкой ваты",
  "травы",
  "лимона",
  "тутти-фрутти",
  "тухлого яйца",
  "черного перца",
  "соплей",
  "козявок",
  "навоза",
  "ногтей",
  "жевательной резинки",
  "вяленого мяса",
  "тухлой рыбы",
  "чипсов",
  "вонючих носков",
  "сливочного пива",
  "пота",
  "старухи",
  "туалетной бумаги",
  "подмышки Хагрида",
  "клопа",
  "луковой обжарки",
  "ядреного хрена",
  "кофе",
  "мухомора",
  "струи скунса",
  "подгузников",
  "тухлого сыра",
  "зубной пасты",
  "собачьего корма",
  "шишек",
  "брокколи",
  "мокроты",
  "несбывшихся надежд",
  "летучей мыши",
  "опарышей",
  "сиропа от кашля",
  "спрея от насморка",
  "табака",
  "поганок",
  "мышьяка",
  "серной кислоты",
  "тыквенного сока",
  "семечек с морской солью",
  "кетчунеза",
  "антибиотиков",
  "вазилина",
  "слизней",
  "слез феникса",
  "оборотного зелья",
  "приворотного зелья",
  "мази для метел",
  "слабительного",
  "сыворотки правды",
  "мандрагоры",
  "шоколадной лягушки",
  "перхоти",
  "поцелуя",
  "птичьего помета",
  "беладонны",
  "жаброслей",
  "шпрот",
  "подошвы",
  "корвалола",
  "плесени",
  "спирта",
  "мраморной говядины",
  "черной икры",
  "скорлупы",
  "мяты",
  "кока-колы",
  "помойных отходов",
  "крысиных хвостов",
  "улитки",
  "лягушачьих лапок",
  "лужи",
  "неразделенной любви",
  "выгребной ямы",
];

export const rules = [
  "Правила чата приложения Школа Магии.",
  "Незнание правил не освобождает Вас от ответственности.",

  "Раздел 1 - Общение",
  "1.1. Запрещено оскорблять проект;",
  "1.2. Запрещено оскорблять администрацию и участников чата, пресекаются любые негативные высказывания;",
  "1.3. Запрещено разводить конфликты на национальной, расовой, религиозной или любой другой почве;",
  "1.4 Запрещено оскорблять и принижать сформированные ордены;",
  "1.5. Запрещено отправлять более 3-х одинаковых сообщений ( расценивается как флуд );",
  "1.6. Запрещена клевета в сторону администрации/игроков;",
  "1.7. Запрещены любые негативные высказывания в сторону обновлений/конкурсов/новостей и т.д. (любые осуждения механики игры и новых обновлений в негативном ключе будут пресекаться (допускается только конструктивная критика));",
  "1.8. Запрещены постоянные выходы из чата;",
  "1.9. Запрещено размещать любые виды порнографии и нацизма;",
  "1.10. Запрещено упоминать родственников в любой негативной интерпретации;",
  "1.11. Запрещено обсуждать и осуждать действия администрации проекта;",
  "1.12. Запрещено обсуждать или упоминать любые другие игровые проекты;",
  "1.13. Запрещена Любая нецензурная и ненормативная лексика.",

  "Раздел 2 - Реклама.",
  "2.1. Запрещено привлекать игроков на другие проекты путем различной рекламы;",
  "2.2. Запрещено рекламировать конкурсы/группы, которые не относятся к Школе Магии;",
  "2.3. Строго запрещается размещение Любых ссылок в чате (особенно тех, которые несут потенциальное приглашение в другие чаты)",

  "Раздел 3 – Общие правила:",
  "3.1. Все решения о вынесения наказания определяются администрацией сообщества и не подлежал обжалованию;",
  "3.2. Администратор вправе избрать любую форму наказания, по своему усмотрению;",
  "3.3. Любые попытки обжаловать решения администрации о вынесении наказания со стороны других игроков будут также пресекаться баном",
  "3.4. Администрация сообщества вправе не объяснять игроку, за что тот был наказан, вся нужная информация для игроков чётко прописана в правилах.",

  "За нарушение вышеперечисленных правил Вы можете получить наказание следующего вида:",
  "1. Предупреждение в чате;",
  "2. Мут в чате;",
  "3. Кик с чата;",
  "4. Вечный бан в чате.",
  "5. Бан в игре «Школа Магии»",
  "Мера пресечения выбирается исходя из того, какой пункт правил Вы нарушили.",
];

// const test = {};

// Object.entries(clothes2Treasures).map((elem) => {
//   const max = Math.max.apply(null, Object.values(elem[1].characteristic));
//   const min = Math.min.apply(null, Object.values(elem[1].characteristic));
//
//   if (elem[1].category === "shirt") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: max,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "pants") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: max,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "hand") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: max,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "head") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: max,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "shoes") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: min,
//         evasion: max,
//         speed: max,
//       },
//     };
//   }
//
//   if (elem[1].category === "wand") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: max,
//         power: max,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "broom") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: max,
//         accuracy: min,
//         evasion: max,
//         speed: min,
//       },
//     };
//   }
// });
//
// console.log(test);
