import styled from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";
import imgMarker3 from "../../img/Marker3Dark.png";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f7b471;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 816px;
  height: 575px;
  gap: 10px;

  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Header = styled.div`
  margin-top: ${(props) => props.top && `${props.top}px`};
  font-size: 24px;
  font-weight: 600;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
  text-align: start;
`;

export const OrdensWrapper = styled.div`
  width: 100%;
  height: 435px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const Applications = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 12px;
  overflow-y: scroll;
  min-height: 435px;
  max-height: 435px;
  padding: 0 5px 0 2px;
`;

export const ErrorString = styled.div`
  margin-top: 12px;
`;

export const RedactWrapper = styled(Applications)`
  gap: 10px;
`;

export const Link = styled.a`
  color: #f7b471;
`;

export const RegularString = styled.span`
  opacity: 0.7;
`;

export const BoldString = styled.div`
  font-size: 15px;
`;

export const Application = styled.div`
  width: 100%;
  line-height: 14px;
  gap: 6px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-template-columns: 0.35fr 0.45fr 2fr 0.45fr 0.45fr;
  background-image: url("${(props) => (props.isMe ? imgMarker2 : imgMarker5)}");
  background-size: contain;
  min-height: 50px;
  max-height: 50px;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Rate = styled(Application)`
  grid-template-columns: 0.35fr 0.45fr 2fr 0.45fr 0.8fr;
`;

export const Header2 = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Place = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const OrdenInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  padding: 10px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
  color: #ffcf82;
  min-width: 100%;
  max-width: 100%;
  min-height: 435px;
  max-height: 435px;
`;

export const TopInfoOrden = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const GerbWrapper = styled.div`
  min-width: 86px;
  height: 86px;
  display: flex;
  position: relative;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const TopInfoOrdenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const TopInfoOrdenRight = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: hidden;
  & > div {
    display: flex;
    flex-direction: column;
    & > span:nth-child(1) {
      font-size: 13px;
      line-height: 15px;
    }
    & > span:nth-child(2) {
      line-height: 20px;
    }

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const LvlInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  & > span:nth-child(1) {
    font-size: 12px;
    line-height: 15px;
  }
  & > span:nth-child(2) {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;
  text-align: start;
  width: 100%;
  & > span:nth-child(2) {
    font-size: 13px;
    line-height: 15px;
  }

  & > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ApplicationInfo = styled(MemberInfo)`
  & > span:nth-child(2) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ApplicationButton = styled.div`
  display: flex;
  width: 41px;
  height: 33px;
  position: relative;
  cursor: pointer;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > img:nth-child(1) {
    transition: opacity 0.25s linear;
    opacity: 0.5;
  }

  &:hover > img:nth-child(1) {
    opacity: 1;
  }
`;

export const Avatar = styled.div`
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  cursor: ${(props) => (props.isPointer ? "pointer" : "default")};
`;

export const HeadUser = styled.span`
width: fit-content;
    cursor ${(props) => (props.isActive ? "pointer" : "default")};
`;

export const Description = styled.div`
  min-height: 150px;
  max-height: 150px;
  max-width: 100%;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  & > img {
    top: 22px;
    width: 100%;
    position: absolute;
  }
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const BottomOrdenInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const Ordens = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 435px;
  max-height: 435px;
  padding: 0 10px 0 5px;
  min-width: 100%;
  max-width: 100%;
`;

export const Mebers = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 435px;
  max-height: 435px;
  padding: 0 10px 0 5px;
  min-width: 100%;
  max-width: 100%;
  position: relative;
`;

export const Orden = styled.div`
  line-height: 14px;
  gap: 10px;
  padding: 5px;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  background-image: url("${(props) =>
    props.isActive
      ? imgMarker3
      : props.isMy && !props.isActive
      ? imgMarker2
      : imgMarker5}");
  background-size: contain;
  min-height: 50px;
  max-height: 50px;
  cursor: pointer;

  &:hover {
    filter: ${(props) => (props.isMy ? "brightness(1)" : "brightness(1.2)")};
  }
  &:active {
    filter: ${(props) => (props.isMy ? "brightness(1)" : "brightness(0.9)")};
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Member = styled.div`
  line-height: 14px;
  gap: 10px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-template-columns: 0.5fr 0.5fr 3fr;
  background-image: url("${(props) => (props.isMe ? imgMarker2 : imgMarker5)}");
  background-size: contain;
  min-height: 50px;
  max-height: 50px;

  cursor: ${(props) => (props.isMe ? "default" : "pointer")};

  &:hover {
    filter: ${(props) => (props.isMy ? "brightness(1)" : "brightness(1.2)")};
  }
  &:active {
    filter: ${(props) => (props.isMy ? "brightness(1)" : "brightness(0.9)")};
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const OrdenRaid = styled.div`
  grid-template-columns: 0.4fr 3fr 0.8fr 0.8fr 0.8fr;
  line-height: 14px;
  cursor: default;
  gap: 10px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  background-image: url("${(props) => (props.isMe ? imgMarker2 : imgMarker5)}");
  background-size: contain;
  min-height: 50px;
  max-height: 50px;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const OrdenName = styled.div`
  display: flex;
  row-gap: 0;
  column-gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  justify-content: start;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > span {
    overflow: hidden;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Orden2String = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
  column-gap: 2px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > span {
    overflow: hidden;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > span:nth-child(1) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const MenuBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 10px;
`;

export const MenuLeftBottom = styled(MenuBottom)`
  left: 0;
  right: unset;
`;

export const ResourcesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const Resource = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2px;
`;

export const InputOrden = styled.input`
  text-align: center;
  width: 100%;
  border: none !important;
  border-bottom: 1px #573d17 solid !important;
  background-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
  font-family: "Bellota-Regular";
  color: #ffcf82 !important;
  -moz-appearance: textfield !important;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::placeholder {
    color: #ffcf82 !important;
    text-transform: none;
  }
  font-size: 14px;
  line-height: 20px;
`;

export const AreaOrden = styled.textarea`
  z-index: 1;
  resize: none;
  text-align: center;
  width: 100%;
  border: none !important;
  outline: none !important;
  font-family: "Bellota-Regular";
  color: #ffcf82 !important;
  background-color: transparent !important;
  -moz-appearance: textfield !important;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::placeholder {
    color: #ffcf82 !important;
    text-transform: none;
  }
  font-size: 14px;
  line-height: 27px;
  margin-bottom: 10px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: start;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 10px;
`;

export const ButtonsBottom = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 22px;
`;

export const CreateWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  font-size: 15px;
`;

export const Resources = styled(CreateWrapper)`
  gap: 10px;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > img {
    position: absolute;
    top: 46px;
    width: 100%;
    object-fit: cover;
    height: 140px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  &:before {
    content: "[";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: "]";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const InfoPopup = styled.div`
  position: absolute;
  left: 184px;
  top: ${(props) =>
    props.top ? (props.top > 420 ? "420px" : `${props.top}px`) : "0px"};
  transform: translate(50%, 25%);
  display: flex;
  flex-direction: column;
  background-color: #18110a;
  color: #fcc382;
  transition: opacity 0.1s linear, visibility 0.1s linear;
  z-index: ${(props) => (props.visible ? "1" : "-1")};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  font-size: 11px;
  text-align: center;
  min-width: 150px;
  max-width: 150px;
`;

export const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;
