import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: ${(props) => (props.g ? props.g : "2")}px;
  position: ${(props) => !props.button && "relative"};
  cursor: ${(props) => (props.button ? "unset" : "help")};
  align-items: center;
  font-size: ${(props) => props.font && `${props.font}px`};
  line-height: ${(props) =>
    props.lineH
      ? `${props.lineH}px`
      : props.reverseColumn || props.column
      ? "normal"
      : props.w
      ? `${props.w}px`
      : 1};
  flex-direction: ${(props) =>
    props.column ? "column" : props.reverseColumn && "column-reverse"};
  color: ${(props) => props.color && props.color};
  word-spacing: -2px;
  filter: ${(props) => props.filter && props.filter};
  & > span {
    word-spacing: 1px;
  }

  &:after {
    top: 0;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "${(props) =>
      !props.button && props.showCount
        ? `${props.name}: ${props.count}`
        : props.name}";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    line-height: 1.5;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: min-content;
    min-width: 80px;
    font-family: "Bellota-Regular";
    text-align: center;
  }
  &:hover:after {
    visibility: ${(props) => (props.button ? "hidden" : "visible")};
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
`;
