import styled from "styled-components";

import imgSliz from "../../img/arena/Sliz.png";
import imgGriff from "../../img/arena/Griff.png";
import imgPuff from "../../img/arena/Puff.png";
import imgKogt from "../../img/arena/Kogt.png";

import buttonConnect from "../../img/ButtonConnect.png";

export const LogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  overflow-y: ${(props) => (props.isStatistic ? "auto" : "hidden")};
  max-height: 332px;
  position: relative;

  & > div {
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
    text-align: center;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const FightWrapper = styled.div`
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  & > div {
    position: relative;
  }
  & > div > div {
    margin-top: 71px;
    height: 332px;
  }
`;

export const Opponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;

  background-image: url("${(props) =>
    props.faculty === "slytherin"
      ? imgSliz
      : props.faculty === "ravenclaw"
      ? imgKogt
      : props.faculty === "hufflepuff"
      ? imgPuff
      : imgGriff}");
  background-size: contain;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const HealthInfo = styled.div`
  position: absolute;
  bottom: 25px;
  color: #281707;
  font-weight: 600;
  text-shadow: 0 0 #281707;

  & > div:nth-child(1) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    gap: 2px;
  }
`;

export const CharactersInfo = styled.div`
  position: absolute;
  bottom: 95px;
`;

export const ButtonPercent = styled.div`
  margin-top: 2px;
  position: relative;
  width: 100px;
  &::after {
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    z-index: 1;
    width: 12px;
    height: 8px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    background-color: #140c02;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    height: 16px;
    overflow: hidden;
    & > div {
      display: flex;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 12px black;
      clip-path: polygon(
        0% 0%,
        ${(props) => props.value}%0%,
        ${(props) => props.value}% 50%,
        ${(props) => props.value}% 100%,
        0% 100%
      );
      transition: all 0.5s ease-in-out;

      background: ${(props) =>
        props.isMe
          ? `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 75.98%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(
          180deg,
          rgba(255, 234, 234, 0) 19.47%,
          rgba(255, 234, 234, 0.2) 34.88%,
          rgba(255, 234, 234, 0) 60.57%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0) 42.19%
        ),
        #acae4f;`
          : `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 75.98%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(
          180deg,
          rgba(255, 234, 234, 0) 19.47%,
          rgba(255, 234, 234, 0.2) 34.88%,
          rgba(255, 234, 234, 0) 60.57%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0) 42.19%
        ),
        #ea3e27;`};
    }
  }
`;
